import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const DailyReport = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch events from API
    const fetchEvents = async () => {
      try {
        const response = await fetch("/inventry/reporting/dailybasis");
        const eventData = await response.json();
        // console.log("Daily data is:", eventData)

        var arr=['total_amount','total_discount','total_net_price','total_gst_price']
        // Format the events array with saleValue, gstValue, and discount
        var array=[]
        var counter=0
        const formattedEvents = eventData.map((event) => {
            arr.map((key)=>{
               var obj= {
                id:counter,
          start: new Date(event.date),
          end: new Date(event.date),
          title: `${key=="total_amount" ? "Amount: " : key=="total_discount" ? "Discount: " : key=="total_net_price" ? "Net Cost: " : key=="total_gst_price" ? "GST: " : ""} ${numberWithCommas(event[key])}`,


        }
        counter=counter+1
        array.push(obj)
    })
    });
       // console.log(array)

        setEvents(array);
      } catch (error) {
       // console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const numberWithCommas=(x) =>{
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const eventStyleGetter = (event) => {
    let backgroundColor;
    switch (event.title.split(":")[0]) {
      case "Amount":
        backgroundColor = "green";
        break;
      case "Discount":
        backgroundColor = "blue";
        break;
      case "Net Cost":
        backgroundColor = "red";
        break;
      default:
        backgroundColor = "gray";
        break;
    }

    return {
      style: {
        backgroundColor,
      },
    };
  };

  return (
    <>
      <h1>Daily Report</h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleGetter}
      />
    </>
  );
};

export default DailyReport;

import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import aiodock from '../hitech.png'
// Create styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 10,
  },
  logo: {
    width: 180,
    height: 80,
    paddingLeft: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  tableHeader: {
    fontSize: 10,
    marginTop:10,
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    padding: 5,
    backgroundColor: "#EEEEEE",
  },
  tableRow: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  tableRowOdd: {
    backgroundColor: "#DDDDDD",
  },
  tableTotal: {
    fontSize: 12,
    fontWeight: "bold",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EEEEEE",
    marginTop: 10,
  },
});

const separator = (numb) => {
  var str = (numb + "").split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return str.join(".");
};

const InvoicePdf = ({
  invoiceNo,
  client,
  accountTitle,
  paymentMethod,
  paymentTitle,
  paymentId,
  totalAmount,
  chequeNo,
  date,
  advanceTax,
  remarks,
  accounts,
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        {/* Main Section */}
        <View style={styles.section}>
        <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              top: 0,
              alignItems: "center", 
              marginBottom:-30
              
            }}
            fixed
          >
            <View style={styles.logo}>
              <Image src={aiodock} />
            </View>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              top: 0,
              alignItems: "left",
              paddingTop: 1,
              paddingLeft: 10,
              marginBottom: 50,
            }}
            fixed
          >
            
            <View
              style={{
                textAlign: "left",
                width: "100%",
                marginLeft: 5,
                flex: 1,
                marginTop: 10,
                flexDirection: "row",
                // paddingLeft: 30,
                // paddingRight: 30,
                lineHeight: "1.5px",
              }}
            >
                <View
                style={{
                  textAlign: "left",
                  width: "50%",
                  marginTop: 10,
                  flexDirection:"column"
                }}
              >
             <Text
              style={{
                textAlign: "left",
                fontSize: 12,
                marginTop: 2,
                color: "#555555",
              }}
            >
             {"JV No #"}
            </Text>
                <Text
                  style={{
                    marginTop: 1,
                    fontSize: 18,
                    fontFamily: "Helvetica-Bold",
                    paddingTop:"20"
                  }}
                >
                  {invoiceNo}
                </Text>
              </View>
              <View
                style={{
                  textAlign: "right",
                  width: "50%",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    marginTop: 1,
                    fontSize: 12,
                  }}
                >
                  {"JV Date : " + new Date(date).toDateString()}
                </Text>
              </View>
            </View>
          </View>

          {/*______________________________________________________________________________________________________ */}
          <View style={styles.tableHeader}>
            <Text style={{ width: "10%" }}>Sr.No</Text>
            <Text style={{ width: "20%" }}>Account Title</Text>
            <Text style={{ width: "15%" }}>Cheque No</Text>
            <Text style={{ width: "15%" }}>Debit</Text>
            <Text style={{ width: "15%" }}>Credit</Text>
            <Text style={{ width: "25%" }}>Description</Text>
          </View>

          {accounts.map((account, index) => (
            <View
              style={[styles.tableRow, index % 2 === 1 && styles.tableRowOdd]}
              key={index}
            >
              <Text style={{ width: "10%" }}>{index + 1}</Text>
              <Text style={{ width: "20%" }}>{account.ac_title}</Text>
              <Text style={{ width: "15%" }}>{account.cheque}</Text>
              <Text style={{ width: "15%" }}>{separator(account.debit)} </Text>
              <Text style={{ width: "15%" }}>{separator(account.credit)} </Text>
              <Text style={{ width: "25%" }}>{account.description}</Text>
            </View>
          ))}

          {/*______________________________________________________________________________________________________ */}

          <View
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: 10,
              paddingLeft: 30,
              paddingRight: 30,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: "left",
                width: "20%",
                fontSize: 12,
                marginTop: 2,
                color: "#555555",
              }}
            >
              Remarks:{" "}
            </Text>
          </View>

          <View
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: 10,
              paddingLeft: 40,
              paddingRight: 30,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: "left",
                width: "100%",
                fontFamily: "Helvetica-Bold",
                borderWidth: 0.5,
                backgroundColor: "#eeeeee",
                padding: 5,
                fontSize: 12,
              }}
            >
              {remarks}
            </Text>
          </View>

          <View
            fixed
            style={{
              position: "absolute",
              width: "100%",
              bottom: 0,
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  textAlign: "center",
                  marginTop: 1,
                  fontSize: 10,
                }}
              >
                {
                  "Address : Shop No.3 pak plaza, Liaqat Rd, Naya Mohalla, Rawalpindi"
                }
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 8,
                  marginTop: 1,
                  fontSize: 10,
                }}
              >
                {"Software Design by: aiodock.com"}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { Grid, Container, Typography } from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import url from '../../serverURL.js';
export default function Edit({editData, editClose,handleClickError,handleClick}) {
  const [open, setOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState('paper');
  const [form, setForm] = React.useState(editData)
  const [category, setCategories] = React.useState([])

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    editClose()
  };
 
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    fetch(url + 'inventry/category', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => {
        if (res.status === 400) {
            alert('No Category Found');
        } else {
            return res.json()
        }
    }).then((categories) => {
        const box = categories.filter((data) => (
            {
                name: data.name,
                code: data.code,
                category: data.category,
                
            })
        )
        setCategories(box)

    })



  }, [open]);

  const getDate = () => {
    return `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
}
const submit = () => {
    /* eslint-disable */
    if (form.name === undefined || form.category === undefined || form.name=="" || form.name.trim()=="") {
        handleClickError()
       return
    }
    var data = {
        code: form.code,
        name: form.name,
        category: form.category,
        stock: form.stock === undefined ? "" : form.stock,
        barcode: form.stock === undefined ? "" : form.barcode,
        cost: form.cost === undefined ? "" : form.cost,
        description: form.description === undefined ? "" : form.description,
        min_stock: form.min_stock === undefined ? "" : form.min_stock,
        rate: form.rate === undefined ? "" : form.rate,
        userId: localStorage.getItem('id')
    }
    fetch(url + 'inventry/item', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(res => {
        if (res.status == 400) {
            handleClickError()
         
            return
        }
        handleClick("Updated Successfully!")

        handleClose()
    })
}

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edit Item</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
        <Grid container spacing={3}>
        <Grid item md={6}>
        
        <TextField
                        required
                        id="filled-required"
                        label="Code"
                        variant="filled"
                        value={form.code === undefined ? "" : form.code}
                       
                    />
        </Grid>
        <Grid item md={6}>
                    <TextField
                        required
                        id="filled-required"
                        label="Name"
                        variant="filled"
                        value={form.name === undefined ? "" : form.name}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                name: e.target.value
                            })

                        }}
                    />
                    </Grid>
                    <Grid item md={6}>
                    <TextField
                        required
                        id="filled-required"
                        label="Description"
                        variant="filled"
                        value={form.description}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                description: e.target.value
                            })

                        }}
                    />
                     </Grid>
                     <Grid item md={6}>
                    <TextField
                        required
                        id="filled-required"
                        label="Barcode"
                        type='text'
                        variant="filled"
                        value={form.barcode}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                barcode: e.target.value
                            })

                        }}
                    />
  </Grid>
                     <Grid item md={6}>
                    <FormControl sx={{ m: 1, minWidth: 245 }}>
                        <InputLabel id="demo-simple-select-filled-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={form.category}
                            onChange={(e) => {
                                setForm({
                                    ...form,
                                    category: e.target.value
                                })

                            }}

                        >
                            <MenuItem value={undefined}>Select</MenuItem>
                            {
                                category.map((row) => {
                                    return <MenuItem value={row.name}>{row.name}</MenuItem>
                                })
                            }

                        </Select>
                    </FormControl>
                    </Grid>
                     <Grid item md={6}>
                    <TextField
                        required
                        id="filled-required"
                        label="Sale Price"
                        variant="filled"
                        type='number'
                        value={form.rate}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                rate: e.target.value
                            })

                        }}
                    />
                      </Grid>
                     <Grid item md={6}>
                    <TextField
                        required
                        id="filled-required"
                        label="Minimum Stock Quantity"
                        variant="filled"
                        value={form.min_stock}
                        type='number'
                        onChange={(e) => {
                            setForm({
                                ...form,
                                min_stock: e.target.value
                            })

                        }}
                    />
                      </Grid>
                     <Grid item md={6}>
                    <TextField
                        required
                        id="filled-required"
                        label="Purchase Price"
                        variant="filled"
                        type='number'
                        value={form.cost}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                cost: e.target.value
                            })

                        }}
                    />
                    </Grid>
                    </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submit}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import aiodock from "../hitech.png";
// Create styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 10,
  },
  logo: {
    width: 180,
    height: 80,
    textAlign: "center",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  tableHeader: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    padding: 5,
    backgroundColor: "#EEEEEE",
  },
  tableRow: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  tableRowOdd: {
    backgroundColor: "#DDDDDD",
  },
  tableTotal: {
    fontSize: 12,
    fontWeight: "bold",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EEEEEE",
    marginTop: 10,
  },
});

const separator = (numb) => {
  var str = (numb + "").split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return str.join(".");
};

const InvoicePdf = ({
  invoiceNo,
  client,
  accountTitle,
  paymentMethod,
  paymentTitle,
  paymentId,
  totalAmount,
  chequeNo,
  date,
  advanceTax,
  remarks,
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        {/* Main Section */}
        <View style={styles.section}>
          {/* <View style={styles.logo}>
          <Text style={{ fontSize: 16, textAlign: "center" }}>
            Company Name
          </Text>
        </View> */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              top: 0,
              alignItems: "center", 
              marginBottom:-30
              
            }}
            fixed
          >
            <View style={styles.logo}>
              <Image src={aiodock} />
            </View>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            
              alignItems: "left",
           
              paddingLeft: 10,
              marginBottom: 50,
            }}
            fixed
          >
            {/* <View
              style={styles.logo}>
            
               <Image
              src={aiodock}
            />
            </View> */}
            <View
              style={{
                textAlign: "left",
                width: "100%",
                marginLeft: 5,
                flex: 1,
                marginTop: 10,
                flexDirection: "row",
                // paddingLeft: 30,
                // paddingRight: 30,
                lineHeight: "1.5px",
              }}
            >
              <View
                style={{
                  textAlign: "left",
                  width: "50%",
                  marginTop: 10,
                  flexDirection:"column"
                }}
              >
             <Text
              style={{
                textAlign: "left",
                fontSize: 12,
                marginTop: 2,
                color: "#555555",
              }}
            >
             {"Receipt No #"}
            </Text>
                <Text
                  style={{
                    marginTop: 1,
                    fontSize: 18,
                    fontFamily: "Helvetica-Bold",
                    paddingTop:"20"
                  }}
                >
                  {invoiceNo}
                </Text>
              </View>
              <View
                style={{
                  textAlign: "right",
                  width: "50%",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    marginTop: 1,
                    fontSize: 12,
                  }}
                >
                  {"Receipt Date : " + new Date(date).toDateString()}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: 10,
              paddingLeft: 30,
              paddingRight: 30,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: "left",
                width: "20%",
                fontSize: 12,
                marginTop: 2,
                color: "#555555",
              }}
            >
              Account Title:{" "}
            </Text>
          </View>
          <View
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: 10,
              paddingLeft: 50,
              paddingRight: 30,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: "left",
                width: "80%",
                fontFamily: "Helvetica-Bold",
              }}
            >
              {" "}
              {accountTitle}{" "}
            </Text>
          </View>

          <View
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: 10,
              paddingLeft: 30,
              paddingRight: 30,
              display: "flex",
              flexDirection: "row",
              color: "#555555",
            }}
          >
            <Text
              style={{
                textAlign: "left",
                width: "40%",
                fontSize: 12,
                marginTop: 2,
              }}
            >
              Account Type:{" "}
            </Text>
          </View>
          <View
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: 10,
              paddingLeft: 50,
              paddingRight: 30,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: "left",
                width: "80%",
                fontFamily: "Helvetica-Bold",
              }}
            >
              {" "}
              {paymentMethod}{" "}
            </Text>
          </View>
          {/*______________________________________________________________________________________________________ */}

          {paymentMethod === "CASH" ? (
            <View>
              <View
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginTop: 10,
                  paddingLeft: 30,
                  paddingRight: 30,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    width: "20%",
                    fontSize: 12,
                    marginTop: 2,
                    color: "#555555",
                  }}
                >
                  Cash Account:{" "}
                </Text>
              </View>
              <View
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginTop: 10,
                  paddingLeft: 50,
                  paddingRight: 30,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    width: "80%",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {paymentTitle}
                </Text>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginTop: 10,
                  paddingLeft: 30,
                  paddingRight: 30,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    width: "20%",
                    fontSize: 12,
                    marginTop: 2,
                    color: "#555555",
                  }}
                >
                  Bank Account:{" "}
                </Text>
              </View>
              <View
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginTop: 10,
                  paddingLeft: 50,
                  paddingRight: 30,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    width: "80%",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {paymentTitle}
                </Text>
              </View>
            </View>
          )}
          {paymentMethod == "BANK" && (
            <View>
              <View
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginTop: 10,
                  paddingLeft: 30,
                  paddingRight: 30,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    width: "20%",
                    fontSize: 12,
                    marginTop: 2,
                    color: "#555555",
                  }}
                >
                  Cheque No:{" "}
                </Text>
              </View>
              <View
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginTop: 10,
                  paddingLeft: 50,
                  paddingRight: 30,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    width: "80%",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {chequeNo}
                </Text>
              </View>
            </View>
          )}

          {/*______________________________________________________________________________________________________ */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View style={{ width: "60%" }}></View>

            <View
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: 12,
                width: "40%",

                marginTop: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",

                  marginTop: 1,
                  fontStyle: "bold",
                }}
                key={"221"}
              >
                <Text
                  style={{
                    width: "40%",
                    textAlign: "center",
                    borderColor: "black",
                    borderWidth: 0.5,
                    height: "100%",
                    color: "black",
                    padding: 5,
                  }}
                >
                  {"Advance Tax"}
                </Text>
                <Text
                  style={{
                    width: "60%",
                    height: "100%",
                    textAlign: "right",
                    borderColor: "black",
                    borderWidth: 0.25,
                    fontFamily: "Helvetica-Bold",
                    backgroundColor: "#eeeeee",
                    padding:5
                  }}
                >
                  {"Rs " + separator(parseInt(advanceTax)) + "/-"}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 1,
                  fontStyle: "bold",
                }}
                key={"221"}
              >
                <Text
                  style={{
                    width: "40%",
                    textAlign: "center",
                    borderColor: "black",
                    borderWidth: 0.5,
                    height: "100%",
                    padding: 5,
                  }}
                >
                  {"Amount"}
                </Text>
                <Text
                  style={{
                    width: "60%",
                    height: "100%",
                    textAlign: "right",
                    borderColor: "black",
                    fontFamily: "Helvetica-Bold",
                    borderWidth: 0.25,
                    backgroundColor: "#eeeeee",
                    padding: 5,
                  }}
                >
                  {"Rs " + separator(parseInt(totalAmount)) + "/-"}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: 10,
              paddingLeft: 30,
              paddingRight: 30,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: "left",
                width: "20%",
                fontSize: 12,
                marginTop: 2,
                color: "#555555",
              }}
            >
              Remarks:{" "}
            </Text>
          </View>

          {remarks!=""
          ?
          <View
          style={{
            textAlign: "left",
            width: "100%",
            marginTop: 10,
            paddingLeft: 40,
            paddingRight: 30,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              textAlign: "left",
              width: "100%",
              borderWidth: 0.5,
              backgroundColor: "#eeeeee",
              padding: 5,
              fontSize: 10,
            }}
          >
            {remarks}
          </Text>
        </View>
        :
        ""

          }

          <View
            fixed
            style={{
              position: "absolute",
              width: "100%",
              bottom: 0,
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  textAlign: "center",
                  marginTop: 1,
                  fontSize: 10,
                }}
              >
                {
                  "Address : Shop No.3 pak plaza, Liaqat Rd, Naya Mohalla, Rawalpindi"
                }
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 8,
                  marginTop: 1,
                  fontSize: 10,
                }}
              >
                {"Software Design by: aiodock.com"}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;

import React, { Component } from "react";
import {
    Form, Col,
    Row
} from 'react-bootstrap';
import 'whatwg-fetch';
import { MDBDataTable } from "mdbreact";
import { Card, CardHeader, CardBody } from "reactstrap";
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import Box from '@mui/material/Box';
import ReactLoading from "react-loading";

import { forwardRef } from 'react';
// ServerURL
import URL from '../../serverURL.js';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

import MaterialTable from 'material-table'
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid, Container, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




class trailBalance extends Component {
    constructor() {
        super();
        this.state = {
            columns: [

                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Account Title",
                    field: "title",
                    sort: "asc"
                },
                {
                    label: "Debit (OB)",
                    field: "opening_debit",
                    sort: "asc"
                },
                {
                    label: "Credit (OB)",
                    field: "opening_credit",
                    sort: "asc"
                },
                {
                    label: "Debit (During)",
                    field: "process_debit",
                    sort: "asc"
                },
                {
                    label: "Credit (During)",
                    field: "process_credit",
                    sort: "asc"
                },
                {
                    label: "Debit (CB)",
                    field: "closing_debit",
                    sort: "asc"
                },
                {
                    label: "Credit (CB)",
                    field: "closing_credit",
                    sort: "asc"
                }


            ],
            rows: [
            ],
            call: () => {
                var columns = this.state.columns;
                var rows = this.state.rows;
                return { columns, rows };
            },
            id: 0,
            cash_accounts: [],
            bank_accounts: [],
            date_from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            date_to: new Date(),
            final_flag: '',
            total_opening_debit: 0,
            total_opening_credit: 0,
            total_process_debit: 0,
            total_process_credit: 0,
            total_closing_debit: 0,
            total_closing_credit: 0,
            cash: true,
            bank: false,
            type: 'customer',
            ac_title_data: null,
            all_accounts: {},
            original_record: [],
            body: '',
            title: '',
            opening_balance: 0,
            opening_flag: '',
            add: false,
            date: new Date(),
            save: false,
            avGbyInvoice: {}
        };

        this.onSubmit = this.onSubmit.bind(this);
    }
    componentWillMount() {


    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    onSubmit(e) {
        e.preventDefault();

        this.setState({ total_opening_credit: 0 })
        this.setState({ total_opening_debit: 0 })
        this.setState({ total_process_credit: 0 })
        this.setState({ total_process_debit: 0 })
        
        var from = new Date(this.state.date_from)
        from.setHours(1)
        var to = new Date(this.state.date_to)
        to.setHours(24)

        var data = {
            from: from,
            to:to
        }
        this.setState({ save: true })
        var url = "/inventry/reporting/tb_inventory"
        data.clienttype = ""

        var OpeningDebit_COS = 0
        var OpeningCredit_COS = 0
        var ProcessDebit = 0
        var ProcessCredit = 0
        var ClosingDebit = 0
        var ClosingCredit = 0

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((transactions) => {
                //  console.log(transactions)
                var Opening = transactions.opening
                var During = transactions.during


                fetch(url + 'inventry/reporting/trial', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((transactions) => {
                
                    var the_rows = []
                    var total_opening_credit = 0
                    var total_opening_debit = 0
                    var total_process_debit = 0
                    var total_process_credit = 0
                    var total_closing_debit = 0
                    var total_closing_credit = 0

                    

                    transactions.push({
                        detail: { id: "C-1", name: "Cost of Sales", type: "COS" },
                        opening: { credit: 0 , debit: Opening.credit },
                        process: { credit: 0 , debit : During.credit },
                    })


                    var OpeningDebit_Profit_Loss = 0
                    var OpeningCredit_Profit_Loss = 0
                    var ProcessDebit_Profit_Loss = 0
                    var ProcessCredit_Profit_Loss = 0

                    // transactions.map((data) => {
                    //     if (data.detail.name == "Credit Sales" || data.detail.name == "Cash Sales") {
                    //         OpeningCredit_Profit_Loss = data.opening.credit + OpeningCredit_Profit_Loss
                    //         OpeningDebit_Profit_Loss = data.opening.debit + OpeningDebit_Profit_Loss
                    //         ProcessCredit_Profit_Loss = data.process.credit + ProcessCredit_Profit_Loss
                    //         ProcessDebit_Profit_Loss = data.process.debit + ProcessDebit_Profit_Loss
                    //     }


                    // })


                    transactions.filter((data) => {
                        var credit = parseFloat(data.process.credit)
                        var debit = parseFloat(data.process.debit)
                        if(data.detail.name.includes("FEL")){
                            console.log(debit)
                        }
                        var closing_debit = 0
                        var closing_credit = 0
                        var close =(((data.opening.debit + debit) - Math.abs(data.opening.credit))- Math.abs(credit))
                        if (close > 0) {
                            closing_debit =  close
                        } else {
                            closing_credit = Math.abs(close)
                        }
                        if ((data.detail.id + "") == "A-1-1-4") {
                           
                          

                            // Check Opening 
                            var opening= Opening.debit-Opening.credit
                            var openingDebit=0
                            var openingCredit=0
                            
                            if(opening>0){
                                openingDebit=opening
                            }else{
                                openingCredit=Math.abs(opening)
                            }
                          
                            // Calculate During
                            console.log(During)
                            console.log("/////////// During")
                            var during= During.debit-During.credit
                            var duringDebit=0
                            var duringCredit=0
                            if(during>0){
                                duringDebit=during
                            }else{
                                duringCredit=Math.abs(during)
                            }
                            console.log(duringCredit)
                            console.log("/////////// Credit")
                            console.log(duringDebit)
                            console.log("/////////// Debit")

                            var close =(((Math.abs(openingDebit) + duringDebit) - openingCredit)- duringCredit)
                            if (close > 0) {
                                closing_debit =  close
                            } else {
                                closing_credit = Math.abs(close)
                            }

                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                opening_credit: this.numberWithCommas((openingCredit).toFixed(2)),
                                opening_debit: this.numberWithCommas((openingDebit).toFixed(2)),
                                closing_debit: this.numberWithCommas(closing_debit.toFixed(2)),
                                closing_credit: this.numberWithCommas(closing_credit.toFixed(2)),
                                process_credit: this.numberWithCommas(duringCredit.toFixed(2)),
                                process_debit: this.numberWithCommas(duringDebit.toFixed(2)),
                            }
                            total_opening_credit = total_opening_credit + parseFloat(openingCredit)
                            total_opening_debit = total_opening_debit + parseFloat(openingDebit)
                            total_process_debit = total_process_debit + parseFloat(duringDebit)
                            total_process_credit = total_process_credit + parseFloat(duringCredit)
                            total_closing_credit = total_closing_credit + closing_credit
                            total_closing_debit = total_closing_debit + closing_debit
                            the_rows.push(put_data)

                            return

                        }

                        var put_data = {
                            id: data.detail.id,
                            title: data.detail.name,
                            type: data.detail.type,
                            opening_credit: this.numberWithCommas(data.opening.credit.toFixed(2)),
                            opening_debit: this.numberWithCommas(data.opening.debit.toFixed(2)),
                            closing_debit: this.numberWithCommas(closing_debit.toFixed(2)),
                            closing_credit: this.numberWithCommas(closing_credit.toFixed(2)),
                            process_debit: this.numberWithCommas(((data.process.debit - data.process.credit) > 0 ? (data.process.debit - data.process.credit) : 0).toFixed(2)),
                            process_credit: this.numberWithCommas(((data.process.debit - data.process.credit) > 0 ? 0 : Math.abs((data.process.debit - data.process.credit))).toFixed(2)),
                        }
                        total_opening_credit = total_opening_credit + parseFloat(data.opening.credit)
                        total_opening_debit = total_opening_debit + parseFloat(data.opening.debit)
                        total_process_debit = total_process_debit + parseFloat((data.process.debit - data.process.credit) > 0 ? (data.process.debit - data.process.credit) : 0)
                        total_process_credit = total_process_credit + parseFloat((data.process.debit - data.process.credit) > 0 ? 0 : Math.abs((data.process.debit - data.process.credit)))
                        total_closing_credit = total_closing_credit + closing_credit
                        total_closing_debit = total_closing_debit + closing_debit
                        the_rows.push(put_data)

                    }
                    )

                    this.setState({ rows: the_rows })
                    this.setState({ total_opening_credit: this.numberWithCommas(total_opening_credit.toFixed(2)) })

                    this.setState({ total_opening_debit: this.numberWithCommas(total_opening_debit.toFixed(2)) })

                    this.setState({ total_process_credit: this.numberWithCommas(total_process_credit.toFixed(2)) })
                    this.setState({ total_process_debit: this.numberWithCommas(total_process_debit.toFixed(2)) })
                    this.setState({ total_closing_credit: this.numberWithCommas(total_closing_credit.toFixed(2)) })
                    this.setState({ total_closing_debit: this.numberWithCommas(total_closing_debit.toFixed(2)) })
                    this.setState({ save: false })

                })




            }).catch(() => {
                this.setState({ save: false })
            })

        
    }
    Clear_Text = () => {
        try {
            const instance = this._typeahead_1.getInstance();
            instance.clear();
        } catch (e) {

            console.log(e)

        }
    }

    getData() {
        var columns = this.state.columns;
        var rows = this.state.rows;
        var filter = this.original;
        return { columns, rows };

    }
    handleChangeFrom = date => {
        this.setState({
            date_from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            date_to: date
        });
    };
    render() {
        return (
            <Grid container>

                <Grid md={12}>
                    <Card>
                        <CardHeader>
                        </CardHeader>
                        <CardBody className="all-icons">
                            <form onSubmit={this.onSubmit} className="Notifications">
                                <h2><strong>Trial Balance</strong></h2>




                                <Grid container controlId="exampleForm.ControlSelect2" >
                                    <Grid sm={2}>
                                        <b>From :</b>
                                        <br />
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker inputFormat="dd/MM/yyyy"

                                                value={this.state.date_from}
                                                onChange={(date_from) => {
                                                    this.setState({ date_from: date_from })
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid sm={2}>
                                        <b>To :</b>
                                        <br />
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker inputFormat="dd/MM/yyyy"

                                                value={this.state.date_to}
                                                onChange={(date_to) => {
                                                    this.setState({ date_to: date_to })
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid sm={1}></Grid>
                                    <Grid sm={2}>
                                        < br/>
                                    <Button variant="contained" size="md" block type="submit" disabled={this.state.save}>Search</Button>
                                   
                                    </Grid>
                                    <Grid sm={5}></Grid>
                                </Grid>
                           
                            </form>
                            <Grid container controlId="formHorizontalEmail">
                                <Grid md={12}>
                                    {
                                        this.state.save
                                            ?
                                            <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                                            :

                                            <MaterialTable
                                                title=""
                                                icons={tableIcons}
                                                columns={[
                                                    { title: 'ID', field: 'id' },
                                                    { title: 'Type', field: 'type' },
                                                    { title: 'Account Title', field: 'title' },

                                                    ////////////////////////////
                                                    {
                                                        title: 'Debit (Opening)', field: 'opening_debit',
                                                        cellStyle: {
                                                            color: 'red',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'red',
                                                        }
                                                    },

                                                    {
                                                        title: 'Credit (Opening)', field: 'opening_credit',
                                                        cellStyle: {

                                                            color: 'blue',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'blue',
                                                        }
                                                    },
                                                    /////////////////////////////
                                                    {
                                                        title: 'Debit (During)', field: 'process_debit',
                                                        cellStyle: {
                                                            color: 'red',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'red',
                                                        }
                                                    },

                                                    {
                                                        title: 'Credit (During)', field: 'process_credit',
                                                        cellStyle: {
                                                            color: 'blue',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'blue',
                                                        }
                                                    },
                                                    //////////////////////////////
                                                    {
                                                        title: 'Debit (Closing)', field: 'closing_debit',
                                                        cellStyle: {
                                                            color: 'red',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'red',
                                                        }
                                                    },

                                                    {
                                                        title: 'Credit (Closing)', field: 'closing_credit',
                                                        cellStyle: {
                                                            color: 'blue',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'blue',
                                                        }
                                                    }

                                                    /////////////////////////////////


                                                ]}
                                                data={this.state.rows}
                                                options={{
                                                    headerStyle: {
                                                        backgroundColor: '#01579b',
                                                        color: '#FFF',
                                                        position: 'sticky',
                                                        top: 0
                                                    },
                                                    maxBodyHeight: '850px' ,
                                                    search: true,
                                                    grouping: false,
                                                    exportButton: true,
                                                    selection: false,
                                                    showTitle: true,
                                                    paging: false

                                                }}

                                            />

                                    }
                                </Grid>
                            </Grid>

                            <Grid container controlId="exampleForm.ControlSelect2" >
                             

                                <Grid sm={2}>
                                    <b class="debit">Opening Debit</b>
                                    <TextField

                                        id="outlined-basic"
                                        variant="outlined" type="text" value={this.state.total_opening_debit} class="debit form-control" required placeholder="Debit..." />
                                </Grid>
                                <Grid sm={2}>
                                    <b class="credit" >Opeing Credit</b>
                                    <TextField

                                        id="outlined-basic"
                                        variant="outlined" type="text" value={this.state.total_opening_credit} class="credit form-control" required placeholder="Credit..." />
                                </Grid>
                                <Grid sm={2}>
                                    <b class="debit">During Period Debit</b>
                                    <TextField

                                        id="outlined-basic"
                                        variant="outlined" type="text" value={this.state.total_process_debit} class="debit form-control" required placeholder="Debit..." />
                                </Grid>
                                <Grid sm={2}>
                                    <b class="credit" >During Period Credit</b>
                                    <TextField

                                        id="outlined-basic"
                                        variant="outlined" type="text" value={this.state.total_process_credit} class="credit form-control" required placeholder="Credit..." />
                                </Grid>
                                <Grid sm={2}>
                                    <b class="debit">Closing Debit</b>
                                    <TextField

                                        id="outlined-basic"
                                        variant="outlined" type="text" value={this.state.total_closing_debit} class="debit form-control" required placeholder="Debit..." />
                                </Grid>
                                <Grid sm={2}>
                                    <b class="credit" >Closing Credit</b>
                                    <TextField

                                        id="outlined-basic"
                                        variant="outlined" type="text" value={this.state.total_closing_credit} class="credit form-control" required placeholder="Credit..." />
                                </Grid>


                            </Grid>
                            <Modal
                                open={this.state.add}
                                onClose={() => this.setState({ add: false })}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        {this.state.title}
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        {this.state.body}
                                    </Typography>
                                </Box>
                            </Modal>

                        </CardBody>
                    </Card>
                </Grid>

            </Grid>
        );
    };
}
export default trailBalance;
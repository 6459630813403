import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import PreviewIcon from '@mui/icons-material/Preview';

// Alert Thing
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
// ServerURL
import url from 'src/serverURL.js';


import { Grid, Container, Typography } from '@mui/material';

import MaterialTable from 'material-table'
import DeleteCategory from './deleteCategory'





const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    PreviewIcon: forwardRef((props, ref) => <PreviewIcon {...props} ref={ref} />),
};

export default function AddItem() {

    const [rows, setRows] = useState([])

    const [name, setName] = useState("")

    const [loading, setLoading] = React.useState(true)

    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);

    const [deleteCat,setDeleteCat]=React.useState(false)
    const [deleteCatData,setDeleteCatData]=React.useState("")

    const [alertText, setAlertText] = React.useState("Category Added Successfully")

    const handleClick = (data) => {
        if (data != undefined && data != null) {
            setAlertText(data)
        }
        setOpen(true);

        Reload();
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleClickError = () => {
        setError(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    const getDate = () => {
        return `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
    }
    const submit = () => {
        /* eslint-disable */
        if (name === undefined || name == "" || name.trim() == "") {
            handleClickError()
            return
        }
        var category = {
            name: name,
            userId: localStorage.getItem('id')

        }
        fetch(url + 'inventry/category', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(category)
        }).then(res => {
            if (res.status == 400) {
                handleClickError()

                return
            }
            handleClick()

        })
    }
    useEffect(() => {

        fetch(url + 'inventry/category', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((categories) => {
            const box = categories.filter((data) => (
                {
                    name: data.name,
                    code: data.code,
                    category: data.category,

                })
            )
            setRows(box)
            setLoading(false)

        })

    }, [])

    const Reload = () => {
        setLoading(true)
        fetch(url + 'inventry/category', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((categories) => {
            const box = categories.filter((data) => (
                {
                    name: data.name,
                    code: data.code,
                    category: data.category
                })
            )
            setLoading(false)
            setRows(box)

        })


    }
    const deleteCategory = (data) => {
        setLoading(true)
        
        console.log("Called")
        console.log(data)
        
        if(data==undefined && data==""){
            return
        }
        var data = {
            code: data.code
        }
        fetch(url + 'inventry/category', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            },
            body:JSON.stringify(data)
        }).then(res => {
            if (res.status === 400) {
               handleClickError()
            } else {
                handleClick("Category Deleted")
                Reload()
            }
            setDeleteCat(false)
        })
    }
    return (
        <>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >

<>
{
    deleteCat ?
    <DeleteCategory deleteCatData={deleteCatData} deleteCategory={deleteCategory} />
    :
    null
}
</>
                <div>
                    <TextField
                        required
                        label="Date"
                        variant="filled"
                        disabled
                        value={getDate()}
                    />
                    <TextField
                        required
                        id="filled-required"
                        label="Name"
                        variant="filled"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)

                        }}
                    />


                    <br />
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            {alertText}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                            Invalid Details
                        </Alert>
                    </Snackbar>
                    <div style={{ padding: 10 }}>
                        <Button variant="contained" to="#" onClick={submit} >
                            Add Category
                        </Button>
                    </div>


                </div>
                {
                    loading ?
                        <CircularProgress />
                        :
                        <MaterialTable
                            icons={tableIcons}
                            title="Items"
                            columns={[
                                { title: 'Code', field: 'code' },
                                { title: 'Name', field: 'name' }

                            ]}
                            data={rows}
                            actions={[

                                {
                                    icon: () => <DeleteOutline />,
                                    tooltip: 'Delete',
                                    onClick: (event, rowData) => {
                                        setDeleteCatData(rowData)
                                        setDeleteCat(true)
                                    }
                                }
                            ]}
                        />
                }

            </Box>

        </>

    );
}

import React, { Component, forwardRef } from "react";

import "whatwg-fetch";

import MaterialTable from "material-table";
import { MDBDataTable } from "mdbreact";
import { Card, CardHeader, CardBody } from "reactstrap";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ReactLoading from "react-loading";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// ServerURL
import url from "src/serverURL.js";

import { Grid, Container, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// Material Table Icons Imports
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function RowContainer(props) {
  const { row, title } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {title}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{""}</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Item Title</TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Opening
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Purchase
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Sold
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Purchase Return
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Sales Return
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Balance
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Unit Cost
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Worth
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(row).map((key) => {
                    var balance =
                      row[key].opening +
                      row[key].purchase +
                      row[key].sales_return -
                      (row[key].sale + row[key].purchase_return);
                    return (
                      <TableRow key={key}>
                        <TableCell>{""}</TableCell>
                        <TableCell component="th" scope="row">
                          {row[key].code}
                        </TableCell>
                        <TableCell>{row[key].name}</TableCell>
                        <TableCell align="right">{row[key].opening}</TableCell>
                        <TableCell align="right">{row[key].purchase}</TableCell>
                        <TableCell align="right">{row[key].sale}</TableCell>
                        <TableCell align="right">
                          {row[key].purchase_return}
                        </TableCell>
                        <TableCell align="right">
                          {row[key].sales_return}
                        </TableCell>
                        <TableCell align="right">{balance}</TableCell>
                        <TableCell align="right">
                          {(row[key].rate == null ? 0 : row[key].rate).toFixed(
                            2
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {(
                            (row[key].rate == null ? 0 : row[key].rate) *
                            balance
                          ).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

class stockManagement extends Component {
  constructor() {
    super();
    this.state = {
      categories: {},
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      cash_accounts: [],
      bank_accounts: [],
      date_from: new Date("1/1/2021"),
      date_to: new Date(),
      final_flag: "",
      total_balance: 0,
      cash: true,
      bank: false,
      load: false,
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      original_record: [],
      body: "",
      title: "",
      opening_balance: 0,
      opening_flag: "",
      add: false,
      date: new Date(),
      submit: false,

      totalWorth: 0,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    fetch("/inventry/item/categories")
      .then((res) => res.json())
      .then((categories) => {
        this.setState({ categories });
      });
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, 2000);
  }
  onSubmit(e) {
    e.preventDefault();

    this.setState({ submit: true });
    var data = {
      from: this.state.date_from,
      to: this.state.date_to,
    };
    fetch("/inventry/reporting/stockManagement", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((transactions) => {
        console.log("Transactions are:", transactions);
        var worth = 0;

        let result = Object.keys(transactions["Stock"]).map((key) => {
          var balance =
            transactions["Stock"][key].opening +
            transactions["Stock"][key].purchase +
            transactions["Stock"][key].sales_return -
            (transactions["Stock"][key].sale +
              transactions["Stock"][key].purchase_return);
          worth = worth + parseFloat(transactions["Stock"][key].rate) * balance;
          return {
            ...transactions["Stock"][key],
            balance: balance,
            rate: parseFloat(transactions["Stock"][key].rate).toFixed(2),
            worth: (
              parseFloat(transactions["Stock"][key].rate) * balance
            ).toFixed(2),
          };
        });
        this.setState({ totalWorth: worth });
        this.setState({ rows: result });
        this.setState({ submit: false });
      });
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };

  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  handleChangeFrom = (date) => {
    this.setState({
      date_from: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      date_to: date,
    });
  };
  render() {
    const { rows } = this.state;
    const dataWithCategories = rows.map((row) => ({
      ...row,
      category: this.state.categories[row.name],
    }));
    return (
      <Grid container>
        {this.state.load ? (
          <Grid md={12}>
            <Card>
              <CardHeader></CardHeader>
              <CardBody className="all-icons">
                <form onSubmit={this.onSubmit} className="Notifications">
                  <Grid container controlId="exampleForm.ControlSelect2">
                    <Grid sm={4}>
                      <h2>
                        <strong>Stock</strong>
                      </h2>
                    </Grid>
                    <Grid sm={2}>
                      <b>From :</b>
                      <br />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          value={this.state.date_from}
                          onChange={(date_from) => {
                            this.setState({ date_from: date_from });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid sm={1}></Grid>
                    <Grid sm={2}>
                      <b>To :</b>
                      <br />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          value={this.state.date_to}
                          onChange={(date_to) => {
                            this.setState({ date_to: date_to });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid sm={1}></Grid>
                    <Grid sm={2}>
                      <br />
                      <Button
                        variant="contained"
                        size="md"
                        block
                        type="submit"
                        disabled={this.state.submit}
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid sm={2}></Grid>
                  </Grid>
                </form>
                {this.state.submit ? (
                  <ReactLoading
                    type={"spokes"}
                    color="rgb(32,55,100)"
                    width={100}
                    height={100}
                  />
                ) : (
                  <Grid container controlId="formHorizontalEmail">
                    <Grid md={12}>
                      <TableContainer component={Paper}>
                        <MaterialTable
                          title="Stock Details"
                          columns={[
                            { title: "Category", field: "category" },
                            { title: "Code", field: "code" },
                            // { title: "Category", field: "category" },
                            { title: "Item Title", field: "name" },
                            {
                              title: "Opening",
                              field: "opening",
                              type: "numeric",
                            },
                            {
                              title: "Purchase",
                              field: "purchase",
                              type: "numeric",
                            },
                            {
                              title: "Purchase Return",
                              field: "purchase_return",
                              type: "numeric",
                            },
                            { title: "Sale", field: "sale", type: "numeric" },
                            {
                              title: "Sales Return",
                              field: "sales_return",
                              type: "numeric",
                            },
                            {
                              title: "Balance",
                              field: "balance",
                              type: "numeric",
                            },
                            {
                              title: "Unit Cost",
                              field: "rate",
                              type: "numeric",
                            },
                            {
                              title: "Worth",
                              field: "worth",
                              type: "numeric",
                            },
                            // {
                            //   title: "Balance",
                            //   field: "balance",
                            //   type: "numeric",
                            // },
                            // { title: "Worth", field: "worth", type: "numeric" },
                          ]}
                          data={dataWithCategories}
                          options={{
                            search: true,
                            pageSizeOptions: [20, 40, 100],
                            pageSize: 20,
                            maxBodyHeight: "500px",
                          }}
                          icons={tableIcons}
                        />
                      </TableContainer>
                    </Grid>
                  </Grid>
                )}
                <Grid container controlId="exampleForm.ControlSelect2">
                  <Grid sm={10}></Grid>

                  <Grid sm={2}>
                    <b class="Total Worth">Total Worth</b>
                    <TextField
                      fullWidth
                      id="filled-basic"
                      variant="filled"
                      type="text"
                      value={this.numberWithCommas(
                        this.state.totalWorth.toFixed(2)
                      )}
                      style={{
                        fontWeight: "bold",
                      }}
                      required
                      placeholder="Total Worth..."
                    />
                  </Grid>
                </Grid>

                <Modal
                  open={this.state.add}
                  onClose={() => this.setState({ add: false })}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {this.state.title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {this.state.body}
                    </Typography>
                  </Box>
                </Modal>
              </CardBody>
            </Card>
          </Grid>
        ) : (
          <div
            style={{
              margin: "auto",
              marginTop: "10em",
              height: "100em",
            }}
          >
            <ReactLoading
              type={"spokes"}
              color="rgb(32,55,100)"
              width={100}
              height={100}
            />
          </div>
        )}
      </Grid>
    );
  }
}
export default stockManagement;
// Material Table Icons Defined
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

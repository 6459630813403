import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { Grid, Container, Typography } from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import EditSaleReturnComponent from './editSaleReturnComponent';
// ServerURL
import url from '../../serverURL.js';

export default function Edit({ editData, editClose}) {
    const [open, setOpen] = React.useState(true);
    const [scroll, setScroll] = React.useState('paper');
    const [form, setForm] = React.useState(editData)
  

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
        editClose()
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

      


    }, [open]);

    const getDate = () => {
        return `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
    }
    const submit = () => {
        // /* eslint-disable */
        // if (form.name === undefined || form.type === undefined || form.name == "" || form.name.trim() == "") {
        //     handleClickError()
        //     return
        // }
        // var data = {
        //     id:form.id,
        //     name:form.name,
        //     mobile:form.mobile,
        //     type:form.type,
        //     email:form.email,
        //     ntn:form.ntn,
        //     stn:form.stn,
        //     address:form.address,
        //     debit:form.debit,
        //     credit:form.credit,
        // }
        // fetch(url + 'inventry/client', {
        //     method: 'PUT',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(data)
        // }).then(res => {
        //     if (res.status == 400) {
        //         handleClickError()

        //         return
        //     }
        //     handleClick("Updated Successfully!")

        //     handleClose()
        // })
    }

    return (
        <div>
            <Dialog
                open={open}
                fullScreen={true}
                fullWidth={true}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Edit Sale Return - {editData.invoiceno}</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                <EditSaleReturnComponent data={editData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

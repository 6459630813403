import React, { Component } from "react";
import { Card, CardHeader, CardBody, Input } from "reactstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import { Form, Col, Row } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import ReactLoading from "react-loading";
import { forwardRef } from "react";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MaterialTable from "material-table";
import { Grid, Container, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import url from "../../serverURL.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class JV extends React.Component {
  constructor() {
    super();
    this.state = {
      columns: [
        {
          label: "Account Title",
          field: "account_title",
          sort: "asc",
        },
        {
          label: "Cheque No",
          field: "cheque",
          sort: "asc",
        },
        {
          label: "Debit",
          field: "debit",
          sort: "asc",
        },
        {
          label: "Credit",
          field: "credit",
          sort: "asc",
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
        },
        {
          label: "Remove",
          field: "remove",
          sort: "asc",
        },
      ],
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      options: [],
      description: "",
      name: "",
      cheque: 0,
      mobile: 0,
      advance_tax: 0,
      email: "",
      cash_accounts: [],
      bank_accounts: [],
      cash: true,
      bank: false,
      ntn: 0,
      debit: 0,
      credit: 0,
      JvNo: "",
      stn: 0,
      address: "",
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      amount: 0,
      debit: 0,
      credit: 0,
      remarks: "",
      original_record: [],
      category: "",
      categories: [],
      add: false,
      title: "",
      total_credit: 0,
      total_debit: 0,
      body: "",
      date: new Date(),
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.numberWithCommas = this.numberWithCommas.bind(this);
    this.Reload = this.Reload.bind(this);
  }
  Reload() {}
  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  componentWillMount() {
    fetch(url + "inventry/jv/JVNo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((counter) => {
        this.setState({ JvNo: counter.JvNo });
      });
    fetch(url + "inventry/jv/accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((client) => {
        var cli = [];
        var accounts = {};
        client.map((acc) => {
          if (acc.type == "customer" || acc.type == "vendor") {
            cli.push(acc.name);
            var obj = {
              title: acc.name,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.name] = obj;
          } else {
            if (
              ((acc.id + "").includes("O-1") &&
                (acc.id + "").split("-").length == 3) ||
              acc.type == "Expenses" ||
              acc.type == "Long Term Liabilities" ||
              acc.type == "Current Liabilities" ||
              acc.type == "Revenue" ||
              acc.type == "Assets" ||
              acc.title == "Cash Sales" ||
              acc.title == "Credit Sales" ||
              acc.title == "Cost of Sales" ||
              acc.title == "Inventory"
            ) {
              return;
            }
            cli.push(acc.title);
            var obj = {
              title: acc.title,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.title] = obj;
          }
        });
        console.log(accounts);
        this.setState({ all_accounts: accounts });
        this.setState({ options: cli });
      });
  }
  componentWillReceiveProps() {
    this.Reload();
  }
  onSubmit(e) {
    e.preventDefault();

    if (this.state.ac_title_data == null) {
      this.setState({ title: "A/C Title" });
      this.setState({ body: "Kindly Select A/C for Transaction!!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.ac_title_data.type == "BANK" && this.state.cheque == 0) {
      this.setState({ title: "Cheque" });
      this.setState({ body: "Enter Cheque Number!!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.credit <= 0 && this.state.debit <= 0) {
      this.setState({ title: "Alert" });
      this.setState({ body: "Kindly Enter Credit or Debit !!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.credit < 0 || this.state.debit < 0) {
      this.setState({ title: "Alert" });
      this.setState({ body: "Invalid Credit or Debit !!" });
      this.setState({ add: true });
      return;
    }

    var data = {
      details: {
        credit: this.state.credit,
        debit: this.state.debit,
        ac_title: this.state.ac_title_data.title,
        ac_id: this.state.ac_title_data.id,
        ac_type: this.state.ac_title_data.type,
        cheque: this.state.cheque,
        description: this.state.description,
      },
      account_title: this.state.ac_title_data.title,
      cheque: this.state.cheque,
      credit: this.numberWithCommas(this.state.credit),
      debit: this.numberWithCommas(this.state.debit),
      description: this.state.description,
      remove: (
        <button
          className="btn btn-danger"
          onClick={() => {
            var index = this.state.rows.indexOf(data);
            var temp = this.state.rows;
            if (data.details.credit > 0) {
              var total_credit =
                parseInt(this.state.total_credit) -
                parseInt(data.details.credit);
              this.setState({ total_credit: total_credit });
            } else {
              var total_debit =
                parseInt(this.state.total_debit) - parseInt(data.details.debit);
              this.setState({ total_debit: total_debit });
            }

            temp.splice(index, 1);
            this.setState({ rows: temp });
          }}
        >
          Remove
        </button>
      ),
    };
    var temp = this.state.rows;
    temp.push(data);
    console.log(temp);
    var total_credit =
      parseInt(this.state.total_credit) + parseInt(this.state.credit);
    var total_debit =
      parseInt(this.state.total_debit) + parseInt(this.state.debit);
    this.setState({ total_credit: total_credit });
    this.setState({ total_debit: total_debit });
    this.setState({ rows: temp });
    this.setState({ description: "" });
    this.setState({ credit: 0 });
    this.setState({ debit: 0 });
    this.setState({ cheque: 0 });
    // this.Clear_Text()
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };
  SaveJv = () => {
    if (this.state.total_credit != this.state.total_debit) {
      this.setState({ title: "JV" });
      this.setState({ body: "Credit & Debit must be equal !!" });
      this.setState({ add: true });
      return;
    }
    var transactions = [];
    var temp = this.state.rows;
    temp.map((item) => {
      transactions.push(item.details);
    });
    var data = {
      id: this.state.JvNo,
      transactions: transactions,
      date: this.state.date,
      credit: this.state.total_credit,
      debit: this.state.total_debit,
      remarks: this.state.remarks,
      userId: localStorage.getItem("id"),
    };

    var JvNoID = this.state.JvNo;

    fetch(url + "inventry/jv", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 400) {
        this.setState({ title: "JV" });
        this.setState({ body: "JV Record Failed!!" });
        this.setState({ add: true });
      } else {
        this.setState({ total_credit: 0 });
        this.setState({ total_debit: 0 });
        this.setState({ remarks: 0 });
        this.setState({ rows: [] });
        this.setState({ title: "JV " + JvNoID });
        this.setState({ body: "JV Record Added!!" });
        this.setState({ add: true });

        fetch(url + "inventry/jv/JVNo", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status == 400) {
              alert("No Category Found");
            } else {
              return res.json();
            }
          })
          .then((counter) => {
            this.setState({ JvNo: counter.JvNo });
          });
      }
    });
  };
  Accounts = () => {
    return (
      <div>
        <Autocomplete
          caseSensitive={false}
          id="ac_title"
          ignoreDiacritics={true}
          ref={(ref) => (this._typeahead_1 = ref)}
          onChange={(event, text) => {
            var text = text + "";
            var id = this.state.all_accounts[text];
            console.log(id);
            this.setState({ ac_title_data: id });
          }}
          options={this.state.options}
          placeholder="A/C Title..."
          renderInput={(params) => <TextField {...params} label="Search" />}
        />
      </div>
    );
  };
  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  render() {
    return (
      <>
        <Container fluid>
          <Grid container>
            <Grid md={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardBody className="all-icons">
                  <form onSubmit={this.onSubmit} className="Notifications">
                    <Grid container>
                      <Grid sm={6} md={6}>
                        <b>JV No.</b>
                        <div>
                          <TextField
                            required
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            value={this.state.JvNo}
                            disabled
                          />
                        </div>
                      </Grid>
                      <Grid sm={2} md={2}></Grid>
                      <Grid sm={2} md={2}>
                        <b>Date</b>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              inputFormat="dd/MM/yyyy"
                              value={this.state.date}
                              onChange={(date) => {
                                this.setState({ date: date });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                      <Grid sm={2} md={2}></Grid>
                    </Grid>

                    <Grid container controlId="exampleForm.ControlSelect2">
                      <Grid sm={6}>
                        <b>A/C Title:</b>
                        {this.Accounts()}
                      </Grid>
                      <Grid sm={2}></Grid>
                      <Grid sm={2}>
                        <b class="debit">
                          {" "}
                          Debit -{" "}
                          <span style={{ color: "green" }}>
                            {this.numberWithCommas(this.state.debit)}
                          </span>
                        </b>
                        <TextField
                          required
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                          value={this.state.debit}
                          class="debit form-control"
                          onChange={(e) => {
                            var debit = e.target.value;
                            if (this.state.credit > 0 && debit > 0) {
                              this.setState({ credit: 0 });
                            }
                            this.setState({ debit: e.target.value });
                          }}
                          placeholder="Debit..."
                        />
                      </Grid>
                      <Grid sm={2}>
                      <Button
                          variant="contained"
                          size="md"
                          block
                          color="success"
                          type="submit"
                          style={{marginTop:33, marginLeft:20,color:'white' }}
                        >
                          Add Debit
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container controlId="exampleForm.ControlSelect2">
                      <Grid sm={6}>
                        <b>Description</b>
                        <br />

                        <TextField
                          required
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          type="text"
                          value={this.state.description}
                          onChange={(e) => {
                            this.setState({ description: e.target.value });
                          }}
                          placeholder="Description..."
                        />
                      </Grid>
                      <Grid sm={2}></Grid>

                      <Grid sm={2}>
                        <b class="credit">
                          Credit -{" "}
                          <span style={{ color: "red" }}>
                            {this.numberWithCommas(this.state.credit)}
                          </span>
                        </b>
                        <TextField
                          fullWidth
                          required
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                          value={this.state.credit}
                          class="credit form-control"
                          onChange={(e) => {
                            var credit = e.target.value;
                            if (this.state.debit > 0 && credit > 0) {
                              this.setState({ debit: 0 });
                            }
                            this.setState({ credit: e.target.value });
                          }}
                          placeholder="Credit..."
                        />
                      </Grid>
                      
                      <Grid sm={2}>
                        <Button
                          variant="contained"
                          size="md"
                          block
                          type="submit"
                          color="error"
                          style={{marginTop:33, marginLeft:20}}
                        >
                          Add Credit
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container controlId="exampleForm.ControlSelect2">
                      <Grid sm={6}>
                        <b>Cheque No #</b>
                        <TextField
                          fullWidth
                          required
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                          value={this.state.cheque}
                          onChange={(e) => {
                            this.setState({ cheque: e.target.value });
                          }}
                          placeholder="Cheque..."
                        />
                      </Grid>
                      <Grid sm={2}></Grid>

                      <Grid sm={4}>
                        {/* <Button
                          variant="contained"
                          size="md"
                          block
                          type="submit"
                          style={{marginTop:10}}
                        >
                          Add Line
                        </Button> */}
                      </Grid>
                    
                    </Grid>
                  </form>
                  <Grid container controlId="formHorizontalEmail">
                    <Grid md={12}>
                      <MaterialTable
                        title=""
                        icons={tableIcons}
                        columns={[
                          {
                            title: "Account Title",
                            field: "account_title",
                          },
                          {
                            title: "Cheque No",
                            field: "cheque",
                          },
                          {
                            title: "Debit",
                            field: "debit",
                            cellStyle: (rowData) => ({
                              backgroundColor:"#54D62C",
                              color:'white'
                                  
                            }),
                          },
                          {
                            title: "Credit",
                            field: "credit",
                            cellStyle: (rowData) => ({
                              backgroundColor: "#FF4842",
                              color:'white'
                            }),
                          },
                          {
                            title: "Description",
                            field: "description",
                          },
                          {
                            title: "Remove",
                            field: "remove",
                            render: (rowData) => (
                              <DeleteOutline
                                color="secondary"
                                onClick={() => {
                                  const dataDelete = [...this.state.rows];
                                  const index = rowData.tableData.id;
                                  dataDelete.splice(index, 1);
                                  this.setState({ rows: [...dataDelete] });
                                  if (parseFloat(rowData.details.credit) > 0) {
                                    var total_credit =
                                      parseFloat(this.state.total_credit) -
                                      parseFloat(rowData.details.credit);
                                    this.setState({
                                      total_credit: total_credit,
                                    });
                                  } else {
                                    var total_debit =
                                      parseFloat(this.state.total_debit) -
                                      parseFloat(rowData.details.debit);
                                    this.setState({ total_debit: total_debit });
                                  }
                                }}
                              />
                            ),
                          },
                        ]}
                        data={this.state.rows}
                        options={{
                          headerStyle: {
                            backgroundColor: "#01579b",
                            color: "#FFF",
                            position: "sticky",
                            top: 0,
                          },
                          maxBodyHeight: "850px",
                          search: false,
                          grouping: true,
                          selection: true,
                          showTitle: false,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container controlId="exampleForm.ControlSelect2">
                    <Grid sm={3}>
                      <b>Remarks</b>
                      <Form.Control
                        style={{ width: "100%" }}
                        as="textarea"
                        rows="3"
                        value={this.state.remarks}
                        onChange={(e) => {
                          this.setState({ remarks: e.target.value });
                        }}
                      />
                    </Grid>
                    <Grid sm={1}></Grid>

                    <Grid sm={2}>
                      <b class="debit" style={{color:'green'}}>Debit</b>
                      <TextField
                      style={{marginRight:10}}
                        required
                        id="outlined-basic"
                        variant="outlined"
                        value={this.numberWithCommas(this.state.total_debit)}
                        class="debit form-control"
                        placeholder="Debit..."
                      />
                    </Grid>
                    <Grid sm={2}>
                      <b class="credit" style={{color:'red'}}>Credit</b>
                      <TextField
                      style={{marginLeft:10}}
                        required
                        id="outlined-basic"
                        variant="outlined"
                        value={this.numberWithCommas(this.state.total_credit)}
                        class="credit form-control"
                        placeholder="Credit..."
                      />
                    </Grid>
                    <Grid sm={2}></Grid>
                    <Grid sm={2}>
                      <br />
                      <Button
                        variant="contained"
                        to="#"
                        size="md"
                        style={{ fontSize: "1.5em" }}
                        block
                        onClick={this.SaveJv}
                      >
                        Save Record
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid controlId="exampleForm.ControlTextarea1"></Grid>

                  <Modal
                    open={this.state.add}
                    onClose={() => this.setState({ add: false })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        {this.state.title}
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {this.state.body}
                      </Typography>
                    </Box>
                  </Modal>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
export default JV;

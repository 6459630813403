import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import "whatwg-fetch";
import { MDBDataTable } from "mdbreact";
import { Card, CardHeader, CardBody } from "reactstrap";

import Autocomplete from "@mui/material/Autocomplete";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Modal from "@mui/material/Modal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import TextField from "@mui/material/TextField";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Grid, Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// ServerURL
import url from "src/serverURL.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
class Payment extends Component {
  constructor() {
    super();
    this.state = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Title",
          field: "name",
          sort: "asc",
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
        },
      ],
      rows: [],
      id: 0,
      options: [],
      name: "",
      all_accounts: {},
      mobile: 0,
      cheque_date: new Date(),
      advance_tax: 0,
      email: "",
      _typeahead: null,
      cash_accounts: [],
      cash_data: null,
      bank_data: null,
      ac_title_data: null,
      cheque_no: "",
      bank_accounts: [],
      all_payment: [],
      cash: true,
      bank: false,
      ntn: 0,
      stn: 0,
      address: "",
      type: "customer",
      amount: 0,
      debit: 0,
      credit: 0,
      paymentNo: "111",
      remarks: "",
      original_record: [],
      category: "",
      categories: [],
      add: false,
      title: "",
      body: "",
      date: new Date(),
      defaultSelected: "",
      default_bank_payment_title: "",
      default_cash_payment_title: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.Reload = this.Reload.bind(this);
  }
  Reload() {}
  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };
  handleChange_Cheque = (date) => {
    this.setState({
      cheque_date: date,
    });
  };
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  componentWillMount() {
    fetch(url + "inventry/accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((accounts) => {
        var cash = [];
        var bank = [];
        var pay = {};
        accounts.map((acc) => {
          if (acc.type == "CASH") {
            cash.push(acc.title);
          }
          if (acc.type == "BANK") {
            bank.push(acc.title);
          }
          pay[acc.title] = {
            id: acc.id,
            title: acc.title,
            type: acc.type,
          };
        });
        this.setState({ all_payment: pay });
        this.setState({ cash_accounts: cash });
        this.setState({ bank_accounts: bank });
        console.log("Accounts");
        console.log(bank);
        console.log("Accounts");
      });

    fetch(url + "inventry/jv/accounts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status == 400) {
          alert("No Category Found");
        } else {
          return res.json();
        }
      })
      .then((client) => {
        var cli = [];
        var accounts = {};
        client.map((acc) => {
          if ( acc.type == "vendor") {
            cli.push(acc.name);
            var obj = {
              title: acc.name,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.name] = obj;
          } 
          else if(acc.type == "customer"){
            return
          }
          else {
            if (
              ((acc.id + "").includes("O-1") &&
                (acc.id + "").split("-").length == 3) ||
              acc.type == "Expenses" ||
              acc.type == "Long Term Liabilities" ||
              acc.type == "Current Liabilities" ||
              acc.type == "Revenue" ||
              acc.type == "Assets" ||
              acc.title == "Cash Sales" ||
              acc.title == "Credit Sales" ||
              acc.title == "Cost of Sales" ||
              acc.title == "Inventory"
            ) {
              return;
            }
            cli.push(acc.title);
            var obj = {
              title: acc.title,
              type: acc.type,
              id: acc.id,
            };
            accounts[acc.title] = obj;
          }
        });

        this.setState({ all_accounts: accounts });
        this.setState({ options: cli });
      });

    var previous = this.props.data;
    this.setState({ paymentNo: previous.id });
    this.setState({ amount: previous.amount });
    this.setState({ date: new Date(previous.date) });
    var arr = previous.ac_title;
    this.setState({ default_ac_title: arr });
    this.setState({
      ac_title_data: {
        title: previous.ac_title,
        id: previous.ac_id,
        type: previous.ac_type,
      },
    });
    var payment_method = previous.payment_method;
    if (payment_method == "CASH") {
      this.setState({ cash: true });
      this.setState({ bank: false });
      var array = previous.payment_title;
      console.log("-------------");
      console.log(array);
      console.log("-------------");
      this.setState({ default_cash_payment_title: array });
      var obj = {
        id: previous.payment_id,
        title: previous.payment_title,
        type: previous.payment_method,
      };
      this.setState({ cash_data: obj });
      this.setState({ default_bank_payment_title: "" });
    } else {
      this.setState({ bank: true });
      this.setState({ cash: false });
      var array = previous.payment_title;
      console.log("-------------");
      console.log(array);
      console.log("-------------");
      this.setState({ default_bank_payment_title: array });
      this.setState({ cheque_date: new Date(previous.cheque_date) });
      this.setState({ cheque_no: previous.cheque_no });
      this.setState({ default_cash_payment_title: "" });
      var obj = {
        id: previous.payment_id,
        title: previous.payment_title,
        type: previous.payment_method,
      };
      this.setState({ bank_data: obj });
    }

    this.setState({ advance_tax: previous.advance_tax });
    this.setState({ remarks: previous.remarks });
  }
  componentWillReceiveProps() {
    this.Reload();
  }
  onSubmit(e) {
    e.preventDefault();

    if (
      this.state.amount <= 0 ||
      this.state.amount == undefined ||
      this.state.amount == null ||
      this.state.amount == ""
    ) {
      this.setState({ title: "Amount" });
      this.setState({ body: "Amount is Invalid!" });
      this.setState({ add: true });
      return;
    }
    if (
      this.state.ac_title_data == undefined ||
      this.state.ac_title_data == null
    ) {
      this.setState({ title: "A/C Title" });
      this.setState({ body: "A/C Title is Invalid!" });
      this.setState({ add: true });
      return;
    }
    if (
      this.state.cash &&
      (this.state.cash_data == undefined || this.state.cash_data == null)
    ) {
      this.setState({ title: "Cash" });
      this.setState({ body: "Cash Account is Invalid!" });
      this.setState({ add: true });
      return;
    }
    if (
      this.state.bank &&
      (this.state.bank_data == undefined || this.state.bank_data == null)
    ) {
      this.setState({ title: "Bank" });
      this.setState({ body: "Bank Account is Invalid!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.bank) {
      if (this.state.cheque_no == "") {
        this.setState({ title: "Cheque" });
        this.setState({ body: "Cheque No is Invalid!" });
        this.setState({ add: true });
        return;
      }
    }
    if (this.state.date == null) {
      this.setState({ title: "Date" });
      this.setState({ body: "Date is Invalid!" });
      this.setState({ add: true });
      return;
    }
    if (this.state.cheque_date == null) {
      this.setState({ title: "Cheque Date" });
      this.setState({ body: "Cheque Date is Invalid!" });
      this.setState({ add: true });
      return;
    }
    var method = "";
    var title = "";
    var id = "";
    if (this.state.cash) {
      method = "CASH";
      title = this.state.cash_data.title;
      id = this.state.cash_data.id;
    } else {
      method = "BANK";
      title = this.state.bank_data.title;
      id = this.state.bank_data.id;
    }
    var paymentNoId = this.state.paymentNo;

    var data = {
      id: this.state.paymentNo,
      ac_title: this.state.ac_title_data.title,
      ac_id: this.state.ac_title_data.id,
      ac_type: this.state.ac_title_data.type,
      date: this.state.date,
      amount: this.state.amount,
      payment_method: method,
      payment_title: title,
      payment_id: id,
      cheque_no: this.state.cheque_no,
      cheque_date: this.state.cheque_date,
      advance_tax: this.state.advance_tax,
      remarks: this.state.remarks,
      userId: localStorage.getItem("id"),
    };

    fetch(url + "inventry/payment", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status == 400) {
        this.setState({ title: "Alert" });
        this.setState({ body: "Payment Already Exists!!" });
        this.setState({ add: true });
      } else {
        this.setState({ title: "Payment " + paymentNoId });
        this.setState({ body: "Payment Updated!!" });
        this.setState({ add: true });
      }
    });
  }
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
      instance.focus();
    } catch (e) {
      try {
        const instance = this._typeahead_2.getInstance();
        instance.clear();
        instance.focus();
      } catch (e) {
        console.log(e);
      }
    }
  };

  Accounts = () => {
    return (
      <div>
        <Autocomplete
          caseSensitive={false}
          id="filtering-example"
          clearButton={true}
          autoFocus={true}
          ignoreDiacritics={true}
          defaultValue={this.state.default_ac_title}
          onChange={(event, text) => {
            var text = text + "";
            var id = this.state.all_accounts[text];
            //console.log("Data")
            this.setState({ ac_title_data: id });
          }}
          options={this.state.options}
          placeholder="A/C Title..."
          renderInput={(params) => (
            <TextField {...params} label="A/C Title..." />
          )}
        />
      </div>
    );
  };

  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Grid item lg={2}></Grid>
            <Grid item md={8}>
              <Card>
                <CardHeader></CardHeader>
                <CardBody className="all-icons">
                  <form onSubmit={this.onSubmit} className="Notifications">
                    <Grid container controlId="formHorizontalEmail">
                      <Grid item sm={6}>
                        <b style={{ fontSize: "1.5em" }}>Payment No</b>
                        <div>
                          <TextField
                            required
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            value={this.state.paymentNo}
                            disabled
                            fullWidth
                          />
                        </div>
                      </Grid>
                      <Grid item sm={2}></Grid>
                      <Grid item sm={4}>
                        <b style={{ fontSize: "1.5em" }}>Date</b>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              inputFormat="dd/MM/yyyy"
                              value={this.state.date}
                              onChange={(date) => {
                                this.setState({ date: date });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container controlId="formHorizontalEmail">
                      <Grid item sm={6}>
                        <b style={{ fontSize: "1.5em", color: "red" }}>
                          A/C Title:
                        </b>
                        {this.Accounts()}
                      </Grid>
                      <Grid item sm={2}></Grid>
                      <Grid item sm={2}>
                        <b style={{ fontSize: "1.5em" }}>
                          Amount{" "}
                          <b style={{ color: "red" }}>
                            {" "}
                            {this.numberWithCommas(this.state.amount)}
                          </b>
                        </b>
                        <br />
                        <TextField
                          required
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                          style={{width:'18em'}}
                          value={this.state.amount}
                          onChange={(e) => {
                            this.setState({ amount: e.target.value });
                          }}
                          placeholder="Amount..."
                        />
                      </Grid>
                      <Grid item sm={2}></Grid>
                    </Grid>

                    <Grid container controlId="formHorizontalEmail">
                      <Grid item sm={12}>
                        <b style={{ fontSize: "1.5em" }}>Account Selection</b>
                        <Grid container id="formGridCheckbox">
                          <Grid item sm={4}>
                            <Form.Check
                              custom
                              inline
                              checked={this.state.cash}
                              onChange={() => {
                                this.setState({ cash: !this.state.cash });

                                this.setState({ bank: !this.state.bank });

                                this.setState({
                                  default_bank_payment_title: "",
                                });
                              }}
                              type="checkbox"
                              label={
                                <b style={{ fontSize: "1.5em" }}> CASH </b>
                              }
                              name="cash"
                              id="cash"
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <Form.Check
                              custom
                              inline
                              checked={this.state.bank}
                              onChange={() => {
                                this.setState({ cash: !this.state.cash });

                                this.setState({ bank: !this.state.bank });
                                this.setState({
                                  default_cash_payment_title: "",
                                });
                              }}
                              type="checkbox"
                              label={
                                <b style={{ fontSize: "1.5em", }}> BANK </b>
                              }
                              name="bank"
                              id="bank"
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <b style={{ fontSize: "1.5em" }}>Advance Tax</b>
                            <br />

                            <TextField
                              required
                              id="outlined-basic"
                              variant="outlined"
                              type="number"
                              style={{width:'18em'}}
                              value={this.state.advance_tax}
                              onChange={(e) => {
                                this.setState({ advance_tax: e.target.value });
                              }}
                              placeholder="Advance Tax..."
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container controlId="formHorizontalEmail">
                      <Grid item sm={6}>
                        {this.state.cash ? (
                          <b style={{ fontSize: "1.5em", color:'green' }}>Cash Account</b>
                        ) : (
                          <b style={{ fontSize: "1.5em", color:'green' }}>Bank Account</b>
                        )}
                        {this.state.cash ? (
                          <div>
                            <Autocomplete
                              caseSensitive={false}
                              key="cash"
                              clearButton={true}
                              autoFocus={true}
                              ignoreDiacritics={true}
                              defaultValue={
                                this.state.default_cash_payment_title
                              }
                              onChange={(event, text) => {
                                var text = text + "";

                                //console.log(name)
                                var id = this.state.all_payment[text];
                                //console.log("Payment Cash")
                                //console.log(id)
                                this.setState({ cash_data: id });
                              }}
                              options={this.state.cash_accounts}
                              placeholder="Cash Accounts..."
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  key={"cash"}
                                  label="Cash Accounts..."
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <div>
                            <Autocomplete
                              caseSensitive={false}
                              key="bank"
                              clearButton={true}
                              autoFocus={true}
                              ignoreDiacritics={true}
                              defaultValue={
                                this.state.default_bank_payment_title
                              }
                              onChange={(event, text) => {
                                if (this.state.bank) {
                                  var text = text + "";
                                  //console.log(name)
                                  var id = this.state.all_payment[text];
                                  //console.log("Payment Bank")
                                  this.setState({ bank_data: id });
                                }
                              }}
                              options={this.state.bank_accounts}
                              placeholder="Bank Accounts..."
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  key={"bank"}
                                  label="Bank Accounts..."
                                />
                              )}
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    {this.state.cash ? (
                      <></>
                    ) : (
                      <>
                        <Grid container controlId="exampleForm.ControlSelect2">
                          <Grid item sm={6}>
                            <b style={{ fontSize: "1.5em" }}>Cheque No:</b>
                            <br />
                            <TextField
                              required
                              id="outlined-basic"
                              variant="outlined"
                              type="text"
                              value={this.state.cheque_no}
                              fullWidth
                              onChange={(e) => {
                                this.setState({ cheque_no: e.target.value });
                              }}
                              placeholder="Cheque No..."
                            />
                          </Grid>
                          <Grid item sm={2}></Grid>
                          <Grid item sm={4}>
                            <b style={{ fontSize: "1.5em" }}>
                              Date of Cheque No:
                            </b>
                            <div>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  inputFormat="dd/MM/yyyy"
                                  value={this.state.date}
                                  onChange={(date) => {
                                    this.setState({ date: date });
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </Grid>
                          <Grid item sm={2}></Grid>
                        </Grid>
                      </>
                    )}
                    <Grid container controlId="exampleForm.ControlSelect2">
                      <Grid item sm={12}>
                        <b style={{ fontSize: "1.5em" }}>Remarks</b>
                        <br />
                        <Form.Control
                          as="textarea"
                          rows="3"
                          value={this.state.remarks}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            this.setState({ remarks: e.target.value });
                          }}
                        />
                      </Grid>
                      <Grid item sm={2}></Grid>

                      <Grid item sm={2}></Grid>
                    </Grid>

                    <Grid container>
                      <Grid item sm={3}></Grid>
                      <Grid item sm={3}></Grid>
                      <Grid item sm={3}></Grid>
                      <Grid item sm={3}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="lg"
                            block
                            type="submit"
                          >
                            Save Record
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                  <Modal
                    open={this.state.add}
                    onClose={() => this.setState({ add: false })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        {this.state.title}
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {this.state.body}
                      </Typography>
                    </Box>
                  </Modal>
                </CardBody>
              </Card>
            </Grid>
          </Row>
        </Container>
      </>
    );
  }
}
export default Payment;

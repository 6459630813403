import React, { Component, PureComponent } from "react";
import {
    Form, Col,
    Row
} from 'react-bootstrap';
import 'whatwg-fetch';
import { MDBDataTable } from "mdbreact";
import { Card, CardHeader, CardBody } from "reactstrap";
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import PropTypes from 'prop-types';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import ReactLoading from "react-loading";
// ServerURL
import url from '../../serverURL.js';
import { forwardRef } from 'react';

import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

import MaterialTable from 'material-table'
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid, Container, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;
  
      return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
          {value}
        </text>
      );
    }
  }
  
  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
          </text>
        </g>
      );
    }

}


class Profit_Analytics extends Component {
    constructor() {
        super();
        this.state = {
            rows: [
            ],
            call: () => {
                var columns = this.state.columns;
                var rows = this.state.rows;
                return { columns, rows };
            },
            id: 0,
            cash_accounts: [],
            bank_accounts: [],
            date_from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-7),
            date_to: new Date(),
            final_flag: '',
            total_balance: 0,
            cash: true,
            bank: false,
            load: false,
            type: 'customer',
            ac_title_data: null,
            all_accounts: {},
            original_record: [],
            body: '',
            title: '',
            opening_balance: 0,
            opening_flag: '',
            add: false,
            netAmount:0,
            totalCost:0,
            totalProfit:0,
            date: new Date(),
            submit:false,
            StockAvgRate:{},

            chartRecord:[]
        };

        this.onSubmit = this.onSubmit.bind(this);
    }
   componentWillMount(){
    fetch(url + 'inventry/jv/accounts', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if(res.status== 400){
          alert('No Category Found');
        }else{
          return res.json()
        }
      }).then((client)=>{
        var cli=[]
        var accounts={}
        client.map((acc)=>{
          if(acc.type=='customer' || acc.type=='vendor'){
            cli.push(acc.name+"//"+acc.type)
            var obj={
              title:acc.name,
              type:acc.type,
              id:acc.id
            }
            accounts[acc.name]=obj
          }else{
            cli.push(acc.title+"//"+acc.type)
            var obj={
              title:acc.title,
              type:acc.type,
              id:acc.id
            }
            accounts[acc.title]=obj      
          }
          
        })
        console.log(accounts)
        this.setState({all_accounts:accounts})
        this.setState({load:true})
        this.setState({options:cli})
      })
    
   }
   numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
  }
    onSubmit(e) {
        e.preventDefault();
        var data = {
            from: this.state.date_from,
            to: this.state.date_to,
            ac_id:this.state.ac_title_data,
            code:"166"
        }
        this.setState({submit:true})





        fetch(url + 'inventry/sale/reportbyInvoiceAll', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((transactions) => {
            var data = {
                from: this.state.date_from,
                to: this.state.date_to
            }
            fetch(url + 'inventry/reporting/avGbyInvoice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((response) => {
         

             this.setState({submit:false})
             var arr=[]

             var profitBydate={}

             var chartRecord=[]
             transactions.map((row)=>{
                 
                 var avGPrice=response[row.invoiceno]
                 var today=(new Date(row.date).getDate())+"-"+(new Date(row.date).getMonth()+1)+"-"+(new Date(row.date).getFullYear())
                 if(profitBydate[today]==undefined){
                    profitBydate[today]={}
                    profitBydate[today]={
                        avG: row.payable - response[row.invoiceno],
                    }
                 }else{
                    profitBydate[today]={
                        avG:profitBydate[today].avG+(row.payable - response[row.invoiceno])
                    }
                 }


             })

             Object.keys(profitBydate).map((i)=>{
                 var obj={
                     name:i,
                     profit:parseFloat(profitBydate[i].avG.toFixed(2))
                 }
                 chartRecord.push(obj)
             })

             console.log("----------------")
             console.log(chartRecord)
             console.log("----------------")
             this.setState({chartRecord:chartRecord})
            
                
            })


            

            })
    
       

    }
    Clear_Text = () => {
        try {
            const instance = this._typeahead_1.getInstance();
            instance.clear();
        } catch (e) {

            console.log(e)

        }
    }
    Accounts = () => {

        return (
            <div>
               <Autocomplete
                    caseSensitive={false}
                    id="filtering-example"
                    ignoreDiacritics={true}
                    ref={(ref) => this._typeahead_1 = ref}
                    onChange={(event,text) => {
                        var text = text + ""
                        var name = text.split('//')
                        console.log(name)
                        var id = this.state.all_accounts[name[0]]
                        console.log(id)
                        this.setState({ ac_title_data: id })
                    }}
                    clearButton={true}
                    renderInput={(params) => <TextField {...params} label="AC Title.." />}
                  
                    options={this.state.options}
                    placeholder="A/C Title..."
                />
            </div>
        );
    };
    getData() {
        var columns = this.state.columns;
        var rows = this.state.rows;
        var filter = this.original;
        return { columns, rows };

    }
    handleChangeFrom = date => {
        this.setState({
            date_from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            date_to: date
        });
    };
    render() {
        return (
            <Grid>

                {
                    this.state.load ?
                        <Grid md={12}>
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody className="all-icons">
                                    <form onSubmit={this.onSubmit} className="Notifications">
                                        <h2><strong>Profit Analytics</strong></h2>




                                        <Grid container controlId="exampleForm.ControlSelect2">
                                           
                                            <Grid sm={2}>
                                                <b>From :</b>
                                                <br />
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker inputFormat="dd/MM/yyyy"

                                                        value={this.state.date_from}
                                                        onChange={(date_from) => {
                                                            this.setState({ date_from: date_from })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />

                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid sm={2}>
                                                <b>To :</b>
                                                <br />
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker inputFormat="dd/MM/yyyy"

                                                        value={this.state.date_to}
                                                        onChange={(date_to) => {
                                                            this.setState({ date_to: date_to })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />

                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid sm={1}></Grid>
                                            <Grid sm={4}>
                                            <b>A/C Title:</b>
                                            {this.Accounts()}
                                            </Grid>
                                            <Grid sm={1}></Grid>
                                            <Grid sm={2}>
                                                <br />
                                                <Button variant="contained" size="md" block type="submit" disabled={this.state.submit}>Search</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container controlId="exampleForm.ControlSelect2">
                                            <Grid sm={6}>
                                          
                                            </Grid>
                                            
                                            <Grid sm={2}>
                                            </Grid>
                                            <Grid sm={2}>
                                            </Grid>
                                           
                                        </Grid>
                                    </form>
                                    <Grid container controlId="formHorizontalEmail">
                                        <Grid md={12}>
                                            {
                                                this.state.submit
                                                ?
                                                <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                                                :
                                                <>
                                                  <LineChart
                                                    width={1100}
                                                    height={600}
                                                    data={this.state.chartRecord}
                                                    margin={{
                                                        top: 20,
                                                        right: 0,
                                                        left: 0,
                                                        bottom: 10,
                                                    }}
                                                    >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Line type="monotone" dataKey="profit" stroke="#8884d8" label={<CustomizedLabel />} />
                                                    </LineChart>
                                 
                                                </>

                                            }
                                       
                                        </Grid>
                                    </Grid>
                                    
                                
                                    <Modal
                                        open={this.state.add}
                                        onClose={() => this.setState({ add: false })}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                {this.state.title}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                {this.state.body}
                                            </Typography>
                                        </Box>
                                    </Modal>

                                </CardBody>
                            </Card>
                        </Grid>
                        :
                        <div style={{
                            margin: "auto",
                            marginTop: "10em",
                            height: "100em"
                        }}>
                            <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                        </div>
                }


            </Grid>
        );
    };
}
export default Profit_Analytics;
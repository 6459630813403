import React, { Component } from "react";
import {
    Form, Col,
    Row
} from 'react-bootstrap';
import 'whatwg-fetch';
import { MDBDataTable } from "mdbreact";
import { Card, CardHeader, CardBody } from "reactstrap";
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import ReactLoading from "react-loading";

import { forwardRef } from 'react';
// ServerURL
import url from '../../serverURL.js';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

import MaterialTable from 'material-table'
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid, Container, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';



const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



class GeneralLedger extends Component {
    constructor() {
        super();
        this.state = {
            columns: [

                {
                    label: "Invoice ID",
                    field: "transactions_id",
                    sort: "asc"
                },
                {
                    label: "Type",
                    field: "type",
                    sort: "asc"
                },
                {
                    label: "Date",
                    field: "date",
                    sort: "asc"
                },
                {
                    label: "Debit",
                    field: "debit",
                    sort: "asc"
                },
                {
                    label: "Credit",
                    field: "credit",
                    sort: "asc"
                },
                {
                    label: "Balance",
                    field: "balance",
                    sort: "asc"
                }
            ],
            rows: [
            ],
            call: () => {
                var columns = this.state.columns;
                var rows = this.state.rows;
                return { columns, rows };
            },
            items_columns: [
                {
                    label: "Code.",
                    field: "code",
                    sort: "asc"
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Quantity",
                    field: "quantity",
                    sort: "asc"
                },
                {
                    label: "Rate",
                    field: "rate",
                    sort: "asc"
                },
                {
                    label: "Discount",
                    field: "discount",
                    sort: "asc"
                },
                {
                    label: "GST",
                    field: "gst",
                    sort: "asc"

                },
                {
                    label: "Amount",
                    field: "amount",
                    sort: "asc"
                }
            ],
            items_rows: [

            ],
            id: 0,
            cash_accounts: [],
            bank_accounts: [],
            date_from: new Date().setMonth(new Date().getMonth() - 1),
            date_to: new Date(),
            final_flag: '',
            total_balance: 0,
            total_debit: 0,
            total_credit: 0,
            save: false,
            cash: true,
            bank: false,
            load: false,
            type: 'customer',
            ac_title_data: null,
            all_accounts: {},
            original_record: [],
            body: '',
            title: '',
            opening_balance: 0,
            opening_flag: '',
            add: false,
            date: new Date(),
            avGbyInvoice: {},
            month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            show: false
        };
        this.getItems = this.getItems.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentWillMount() {
        fetch(url + 'inventry/jv/accounts', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status == 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((client) => {
            var cli = []
            var accounts = {}
            client.map((acc) => {
                if (acc.type == 'customer' || acc.type == 'vendor') {
                    cli.push(acc.name + "||" + acc.type)
                    var obj = {
                        title: acc.name,
                        type: acc.type,
                        id: acc.id
                    }
                    accounts[acc.name] = obj
                } else {
                    if(acc.type=="Long Term Liabilities"){
                        return
                    }
                    cli.push(acc.title + "||" + acc.type)
                    var obj = {
                        title: acc.title,
                        type: acc.type,
                        id: acc.id
                    }
                    accounts[acc.title] = obj
                }

            })
            console.log(accounts)
            this.setState({ all_accounts: accounts })
            this.setState({ load: true })
            this.setState({ options: cli })
        })


    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({ rows: [] })
        this.setState({ total_credit: 0 })
        this.setState({ total_debit: 0 })
        this.setState({ total_balance: 0 })
        this.setState({ save: true })

        if (this.state.ac_title_data == null) {
            this.setState({ title: "A/C Title" })
            this.setState({ body: "Kindly Select A/C Title!!" })
            this.setState({ add: true })
            this.setState({ save: false })
            return
        }
        var from = new Date(this.state.date_from)
        from.setHours(1)
        var to = new Date(this.state.date_to)
        to.setHours(24)
        var data = {
            acc_id: this.state.ac_title_data.id,
            from: from.toISOString(),
            to: to.toISOString()
        }
        this.setState({ total_credit: 0 })
        this.setState({ total_debit: 0 })
        this.setState({ total_balance: 0 })
        this.setState({ opening_balance: 0 })

        var url = "/inventry/reporting/generalLedger"

        if (this.state.ac_title_data.id == "R-1-1-1") {
            url = "/inventry/reporting/sales"
            data.clienttype = "customer"

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((transactions) => {

                var Opening1 = transactions.Opening
                var During1 = transactions.During

                //////////////////// Sorting Opening Data
                var key = Object.keys(Opening1)
                    .sort(function order(key1, key2) {

                        if (new Date(Opening1[key1].date) < new Date(Opening1[key2].date)) return -1;
                        else if (new Date(Opening1[key1].date) > new Date(Opening1[key2].date)) return +1;
                        else return 0;
                    });

                var Opening = {};

                for (var i = 0; i < key.length; i++) {
                    Opening[key[i]] = Opening1[key[i]];
                    delete Opening1[key[i]];
                }

                ///////////////////

                //////////////////// Sorting During Data
                var key = Object.keys(During1)
                    .sort(function order(key1, key2) {

                        if (new Date(During1[key1].date) < new Date(During1[key2].date)) return -1;
                        else if (new Date(During1[key1].date) > new Date(During1[key2].date)) return +1;
                        else return 0;
                    });

                var During = {};

                for (var i = 0; i < key.length; i++) {
                    During[key[i]] = During1[key[i]];
                    delete During1[key[i]];
                }

                ///////////////////

                var openingBalance = 0
                var OpeningCredit = 0
                var OpeningDebit = 0
                // Opening

                Object.keys(Opening).map((key) => {
                    if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                        OpeningDebit = OpeningDebit + Opening[key].amount
                    }
                })


                var openDiff = OpeningDebit

                this.setState({ opening_flag: "Dr" })

                this.setState({ opening_balance: Math.abs(openDiff) })

                var OpeningBalanceValue = openDiff
                var DuringCreditBalance = 0
                var DuringDebitBalance = 0
                var the_rows = []

                var totalDebit = 0
                var totalCredit = 0
                var totalBalance = 0
                var openDiff = 0
                var flag = ""
                var counter = 0
                // During

                Object.keys(During).map((key) => {
                    var debit = 0
                    var credit = 0
                    var type = ""

                    if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                        if (key.includes('AIO-S-')) {
                            type = "Sale"
                        }
                        if (key.includes('AIO-PR-')) {
                            type = "Purchase Return"
                        }
                        totalDebit = totalDebit + During[key].amount
                        if (counter == 0) {
                            DuringDebitBalance = DuringDebitBalance + During[key].amount + OpeningBalanceValue
                        } else {
                            DuringDebitBalance = DuringDebitBalance + During[key].amount
                        }
                        counter++
                        debit = During[key].amount
                    }


                    var date = new Date(During[key].date)
                    var d = date.getDate()
                    var m = date.getMonth() + 1
                    var y = date.getFullYear()

                    openDiff = DuringDebitBalance


                    flag = "Dr"



                    var put_data = {
                        date: d + '-' + m + '-' + y,
                        dateString: date.toISOString(),
                        credit: <b class="credit" style={{ float: "right" }}>{this.numberWithCommas(credit.toFixed(2))}</b>,
                        debit: <b class="debit" style={{ float: "right" }}>{this.numberWithCommas(debit.toFixed(2))}</b>,
                        transactions_id: key,
                        type: type,
                        remarks : During[key].remarks,
                        balance: <b style={{ float: "right" }} >{this.numberWithCommas(Math.abs(openDiff).toFixed(2))} <b class="credit">{flag}</b></b>
                    }
                    the_rows.push(put_data)
                })

                this.setState({ rows: the_rows })
                this.setState({ total_credit: this.numberWithCommas(totalCredit.toFixed(2)) })
                this.setState({ total_debit: this.numberWithCommas(totalDebit.toFixed(2)) })
                this.setState({ total_balance: this.numberWithCommas(Math.abs(openDiff).toFixed(2)) })
                this.setState({ final_flag: flag })
                this.setState({ save: false })


            }).catch(() => {
                this.setState({ save: false })
            })

        }
        else if (this.state.ac_title_data.id == "R-1-1-2") {
            url = "/inventry/reporting/sales"
            data.clienttype = "CASH"
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((transactions) => {

                var Opening1 = transactions.Opening
                var During1 = transactions.During

                //////////////////// Sorting Opening Data
                var key = Object.keys(Opening1)
                    .sort(function order(key1, key2) {

                        if (new Date(Opening1[key1].date) < new Date(Opening1[key2].date)) return -1;
                        else if (new Date(Opening1[key1].date) > new Date(Opening1[key2].date)) return +1;
                        else return 0;
                    });

                var Opening = {};

                for (var i = 0; i < key.length; i++) {
                    Opening[key[i]] = Opening1[key[i]];
                    delete Opening1[key[i]];
                }

                ///////////////////

                //////////////////// Sorting During Data
                var key = Object.keys(During1)
                    .sort(function order(key1, key2) {

                        if (new Date(During1[key1].date) < new Date(During1[key2].date)) return -1;
                        else if (new Date(During1[key1].date) > new Date(During1[key2].date)) return +1;
                        else return 0;
                    });

                var During = {};

                for (var i = 0; i < key.length; i++) {
                    During[key[i]] = During1[key[i]];
                    delete During1[key[i]];
                }

                ///////////////////

                var openingBalance = 0
                var OpeningCredit = 0
                var OpeningDebit = 0
                // Opening

                Object.keys(Opening).map((key) => {
                    if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                        OpeningDebit = OpeningDebit + Opening[key].amount
                    }
                })


                var openDiff = OpeningDebit

                this.setState({ opening_flag: "Dr" })

                this.setState({ opening_balance: Math.abs(openDiff) })

                var OpeningBalanceValue = openDiff
                var DuringCreditBalance = 0
                var DuringDebitBalance = 0
                var the_rows = []

                var totalDebit = 0
                var totalCredit = 0
                var totalBalance = 0
                var openDiff = 0
                var flag = ""
                var counter = 0
                // During

                Object.keys(During).map((key) => {
                    var debit = 0
                    var credit = 0
                    var type = ""

                    if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                        if (key.includes('AIO-S-')) {
                            type = "Sale"
                        }
                        if (key.includes('AIO-PR-')) {
                            type = "Purchase Return"
                        }
                        totalDebit = totalDebit + During[key].amount
                        if (counter == 0) {
                            DuringDebitBalance = DuringDebitBalance + During[key].amount + OpeningBalanceValue
                        } else {
                            DuringDebitBalance = DuringDebitBalance + During[key].amount
                        }
                        counter++
                        debit = During[key].amount
                    }


                    var date = new Date(During[key].date)
                    var d = date.getDate()
                    var m = date.getMonth() + 1
                    var y = date.getFullYear()

                    openDiff = DuringDebitBalance


                    flag = "Dr"



                    var put_data = {
                        date: d + '-' + m + '-' + y,
                        dateString: date.toISOString(),
                        credit: <b class="credit" style={{ float: "right" }}>{this.numberWithCommas(credit.toFixed(2))}</b>,
                        debit: <b class="debit" style={{ float: "right" }}>{this.numberWithCommas(debit.toFixed(2))}</b>,
                        transactions_id: key,
                        type: type,
                        remarks : During[key].remarks,
                        balance: <b style={{ float: "right" }} >{this.numberWithCommas(Math.abs(openDiff).toFixed(2))} <b class="credit">{flag}</b></b>
                    }
                    the_rows.push(put_data)
                })

                this.setState({ rows: the_rows })
                this.setState({ total_credit: this.numberWithCommas(totalCredit.toFixed(2)) })
                this.setState({ total_debit: this.numberWithCommas(totalDebit.toFixed(2)) })
                this.setState({ total_balance: this.numberWithCommas(Math.abs(openDiff).toFixed(2)) })
                this.setState({ final_flag: flag })
                this.setState({ save: false })


            }).catch(() => {
                this.setState({ save: false })
            })

        }
        else if (this.state.ac_title_data.id == "C-1") {
            url = "/inventry/reporting/costofsales"
            data.clienttype = ""

            fetch(url + "inventry/reporting/avGbyInvoice", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((avGbyInvoice) => {
                this.setState({ avGbyInvoice: avGbyInvoice })

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((transactions) => {
                    console.log(transactions)
                    var Opening1 = transactions.Opening
                    var During1 = transactions.During

                    //////////////////// Sorting Opening Data
                    var key = Object.keys(Opening1)
                        .sort(function order(key1, key2) {

                            if (new Date(Opening1[key1].date) < new Date(Opening1[key2].date)) return -1;
                            else if (new Date(Opening1[key1].date) > new Date(Opening1[key2].date)) return +1;
                            else return 0;
                        });

                    var Opening = {};

                    for (var i = 0; i < key.length; i++) {
                        Opening[key[i]] = Opening1[key[i]];
                        delete Opening1[key[i]];
                    }

                    ///////////////////

                    //////////////////// Sorting During Data
                    var key = Object.keys(During1)
                        .sort(function order(key1, key2) {

                            if (new Date(During1[key1].date) < new Date(During1[key2].date)) return -1;
                            else if (new Date(During1[key1].date) > new Date(During1[key2].date)) return +1;
                            else return 0;
                        });

                    var During = {};

                    for (var i = 0; i < key.length; i++) {
                        During[key[i]] = During1[key[i]];
                        delete During1[key[i]];
                    }

                    ///////////////////

                    var openingBalance = 0
                    var OpeningCredit = 0
                    var OpeningDebit = 0
                    // Opening

                    Object.keys(Opening).map((key) => {
                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            OpeningDebit = OpeningDebit + this.state.avGbyInvoice[key]
                        }
                    })


                    var openDiff = OpeningDebit

                    this.setState({ opening_flag: "Dr" })

                    this.setState({ opening_balance: Math.abs(openDiff) })

                    var OpeningBalanceValue = openDiff
                    var DuringCreditBalance = 0
                    var DuringDebitBalance = 0
                    var the_rows = []

                    var totalDebit = 0
                    var totalCredit = 0
                    var totalBalance = 0
                    var openDiff = 0
                    var flag = ""
                    var counter = 0
                    // During

                    Object.keys(During).map((key) => {
                        var debit = 0
                        var credit = 0
                        var type = ""

                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            if (key.includes('AIO-S-')) {
                                type = "Sale"
                            }
                            if (key.includes('AIO-PR-')) {
                                type = "Purchase Return"
                            }
                            totalDebit = totalDebit + this.state.avGbyInvoice[key]
                            if (counter == 0) {
                                DuringDebitBalance = DuringDebitBalance + this.state.avGbyInvoice[key] + OpeningBalanceValue
                            } else {
                                DuringDebitBalance = DuringDebitBalance + this.state.avGbyInvoice[key]
                            }
                            counter++
                            debit = this.state.avGbyInvoice[key]
                        }


                        var date = new Date(During[key].date)
                        var d = date.getDate()
                        var m = date.getMonth() + 1
                        var y = date.getFullYear()

                        openDiff = DuringDebitBalance


                        flag = "Dr"



                        var put_data = {
                            date: d + '-' + m + '-' + y,
                            dateString: date.toISOString(),
                            credit: <b class="credit" style={{ float: "right" }}>{this.numberWithCommas(credit.toFixed(2))}</b>,
                            debit: <b class="debit" style={{ float: "right" }}>{this.numberWithCommas(debit.toFixed(2))}</b>,
                            transactions_id: key,
                            type: type,
                            balance: <b style={{ float: "right" }} >{this.numberWithCommas(Math.abs(openDiff).toFixed(2))} <b class="credit">{flag}</b></b>
                        }
                        the_rows.push(put_data)
                    })

                    this.setState({ rows: the_rows })
                    this.setState({ total_credit: this.numberWithCommas(totalCredit.toFixed(2)) })
                    this.setState({ total_debit: this.numberWithCommas(totalDebit.toFixed(2)) })
                    this.setState({ total_balance: this.numberWithCommas(Math.abs(openDiff).toFixed(2)) })
                    this.setState({ final_flag: flag })
                    this.setState({ save: false })


                }).catch(() => {
                    this.setState({ save: false })
                })

            })


        } else {

            if (this.state.ac_title_data.id == "A-1-1-4") {
                url = "/inventry/reporting/gl_inventory"
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((transactions) => {
                    console.log(transactions)
                    var Opening1 = transactions.Opening
                    var During1 = transactions.During
                    

                    //////////////////// Sorting Opening Data
                    var key = Object.keys(Opening1)
                        .sort(function order(key1, key2) {

                            if (new Date(Opening1[key1].date) < new Date(Opening1[key2].date)) return -1;
                            else if (new Date(Opening1[key1].date) > new Date(Opening1[key2].date)) return +1;
                            else return 0;
                        });

                    var Opening = {};

                    for (var i = 0; i < key.length; i++) {
                        Opening[key[i]] = Opening1[key[i]];
                        delete Opening1[key[i]];
                    }

                    ///////////////////

                    //////////////////// Sorting During Data
                    var key = Object.keys(During1)
                        .sort(function order(key1, key2) {

                            if (new Date(During1[key1].date) < new Date(During1[key2].date)) return -1;
                            else if (new Date(During1[key1].date) > new Date(During1[key2].date)) return +1;
                            else return 0;
                        });

                    var During = {};

                    for (var i = 0; i < key.length; i++) {
                        During[key[i]] = During1[key[i]];
                        delete During1[key[i]];
                    }

                    ///////////////////

                    var openingBalance = 0
                    var OpeningCredit = 0
                    var OpeningDebit = 0
                    // Opening

                    Object.keys(Opening).map((key) => {
                        console.log(key)
                        if (key.includes('AIO-P-') || key.includes('AIO-RF-')) {
                            OpeningDebit = OpeningDebit + Opening[key].amount

                        }


                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            OpeningCredit = OpeningCredit + Opening[key].amount
                        }
                    })


                    var openDiff = OpeningCredit - OpeningDebit
                    if (openDiff >= 0) {
                        this.setState({ opening_flag: "Cr" })
                    } else {
                        this.setState({ opening_flag: "Dr" })
                    }

                    this.setState({ opening_balance: Math.abs(openDiff) })

                    var OpeningBalanceValue = openDiff
                    var DuringCreditBalance = 0
                    var DuringDebitBalance = 0
                    var the_rows = []

                    var totalDebit = 0
                    var totalCredit = 0
                    var totalBalance = 0
                    var openDiff = 0
                    var flag = ""
                    var counter = 0


                    Object.keys(During).map((key) => {
                        var debit = 0
                        var credit = 0
                        var type = ""


                        if (key.includes('AIO-P-') || key.includes('AIO-RF-')) {
                            if (key.includes('AIO-P-')) {
                                type = "Purchase"
                            }
                            if (key.includes('AIO-RF-')) {
                                type = "Sale Return"
                            }
                            debit = During[key].amount
                            totalDebit = totalDebit + During[key].amount
                            if (counter == 0) {
                                DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                            } else {
                                DuringDebitBalance = DuringDebitBalance + During[key].amount
                            }
                            counter++
                        }
                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            if (key.includes('AIO-S-')) {
                                type = "Sale"
                            }
                            if (key.includes('AIO-PR-')) {
                                type = "Purchase Return"
                            }

                            ///

                            credit = During[key].amount
                            totalCredit = totalCredit + During[key].amount
                            if (counter == 0) {
                                DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? OpeningBalanceValue : OpeningBalanceValue)
                            } else {
                                DuringCreditBalance = DuringCreditBalance + During[key].amount
                            }
                            ///

                            counter++

                        }



                        var date = new Date(During[key].date)
                        var d = date.getDate()
                        var m = date.getMonth() + 1
                        var y = date.getFullYear()

                        openDiff = DuringCreditBalance - DuringDebitBalance

                        if (openDiff >= 0) {
                            flag = "Cr"
                        } else {
                            flag = "Dr"
                        }
                        // console.log("Putting Value")
                        // console.log(credit)
                        // console.log(debit)
                        // console.log(key)

                        var put_data = {
                            date: d + '-' + m + '-' + y,
                            dateString: date.toISOString(),
                            credit: <b class="credit" style={{ float: "right" }}>{this.numberWithCommas(credit.toFixed(2))}</b>,
                            debit: <b class="debit" style={{ float: "right" }}>{this.numberWithCommas(debit.toFixed(2))}</b>,
                            transactions_id: key,
                            type: type,
                            remarks : During[key].remarks,
                            balance: <b style={{ float: "right" }} >{this.numberWithCommas(Math.abs(openDiff).toFixed(2))} <b class="credit">{flag}</b></b>
                        }
                      
                        the_rows.push(put_data)
                    })
                    console.log("Rows")
                    console.log(the_rows)

                    this.setState({ rows: the_rows })
                    this.setState({ total_credit: this.numberWithCommas(totalCredit.toFixed(2)) })
                    this.setState({ total_debit: this.numberWithCommas(totalDebit.toFixed(2)) })
                    this.setState({ total_balance: this.numberWithCommas(Math.abs(openDiff).toFixed(2)) })
                    this.setState({ final_flag: flag })
                    this.setState({ save: false })


                }).catch((e) => {
                    console.log("Error")
                    console.log(e)
                    this.setState({ save: false })
                })

            } else {

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((transactions) => {
                   // console.log(transactions)
                    var Opening1 = transactions.Opening
                    var During1 = transactions.During

                    //////////////////// Sorting Opening Data
                    var key = Object.keys(Opening1)
                        .sort(function order(key1, key2) {

                            if (new Date(Opening1[key1].date) < new Date(Opening1[key2].date)) return -1;
                            else if (new Date(Opening1[key1].date) > new Date(Opening1[key2].date)) return +1;
                            else return 0;
                        });

                    var Opening = {};

                    for (var i = 0; i < key.length; i++) {
                        Opening[key[i]] = Opening1[key[i]];
                        delete Opening1[key[i]];
                    }

                    ///////////////////

                    //////////////////// Sorting During Data
                    var key = Object.keys(During1)
                        .sort(function order(key1, key2) {

                            if (new Date(During1[key1].date) < new Date(During1[key2].date)) return -1;
                            else if (new Date(During1[key1].date) > new Date(During1[key2].date)) return +1;
                            else return 0;
                        });

                    var During = {};

                    for (var i = 0; i < key.length; i++) {
                        During[key[i]] = During1[key[i]];
                        delete During1[key[i]];
                    }

                    ///////////////////

                    var openingBalance = 0
                    var OpeningCredit = transactions.openingClient.credit=="" ? 0 : transactions.openingClient.credit
                    var OpeningDebit = transactions.openingClient.debit==""? 0 : transactions.openingClient.debit

                    console.log("-----------------Opening Credit")
                    console.log(OpeningCredit)
                    console.log(OpeningDebit)
                    console.log("-----------------Opening Credit")
                    // Opening

                    Object.keys(Opening).map((key) => {
                        console.log(Opening[key].amount)
                        if (key.includes('AIO-P-') || key.includes('AIO-RF-')) {
                            OpeningCredit = parseFloat(OpeningCredit) + parseFloat(Opening[key].amount)
                        }
                        if (key.includes('AIO-PMT-')) {
                            if ((this.state.ac_title_data.id + "").includes("-")) { // Expense Account Debit
                                OpeningCredit = parseFloat(OpeningCredit) + parseFloat(Opening[key].amount)
                            } else {
                                OpeningDebit = parseFloat(OpeningDebit) + parseFloat(Opening[key].amount)
                            }
                        }
                        if (key.includes('AIO-R-')) {
                            if ((this.state.ac_title_data.id + "").includes("-")) {
                                OpeningDebit = parseFloat(OpeningDebit) + parseFloat(Opening[key].amount)
                            } else {
                                OpeningCredit = parseFloat(OpeningCredit) + parseFloat(Opening[key].amount)
                            }

                        }
                        // jv
                        if (key.includes('AIO-JV-')) {
                            if (Opening[key].type == 'debit') {
                                OpeningDebit = parseFloat(OpeningDebit) + parseFloat(Opening[key].amount)
                            } else {
                                OpeningCredit = parseFloat(OpeningCredit) + parseFloat(Opening[key].amount)
                            }

                        }
                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            OpeningDebit = parseFloat(OpeningDebit) + parseFloat(Opening[key].amount)
                        }
                    })

                    var flag = ""
                    console.log(OpeningCredit)
                    console.log(OpeningDebit)
                    console.log("---")
                    var openDiff = parseFloat(OpeningCredit=="" ? 0:OpeningCredit) - parseFloat(OpeningDebit=="" ? 0:OpeningDebit)
                    var OpeningDiff = OpeningCredit - OpeningDebit
                    if (openDiff >= 0) {
                        this.setState({ opening_flag: "Cr" })
                        this.setState({ final_flag: "Cr" })
                        flag="Cr"
                    } else {
                        this.setState({ opening_flag: "Dr" })
                        this.setState({ final_flag: "Dr" })
                        flag="Dr"
                    }
                    this.setState({ opening_balance: Math.abs(openDiff) })

                    console.log("-----------------Opening Bank")
                    console.log(openDiff)
                    console.log("-----------------Opening Bank")

                    var OpeningBalanceValue = openDiff
                    var DuringCreditBalance = 0
                    var DuringDebitBalance = 0
                    var the_rows = []

                    var totalDebit = 0
                    var totalCredit = 0
                    var totalBalance = 0
                    var openDiff = 0
                    
                    var counter = 0
                    // During
                    console.log("------------")
                    console.log(Object.keys(During).length)
                    console.log("------------")
                    Object.keys(During).map((key) => {
                        var debit = 0
                        var credit = 0
                        var type = ""

                        if (key.includes('AIO-P-') || key.includes('AIO-RF-')) {
                            if (key.includes('AIO-P-')) {
                                type = "Purchase"
                            }
                            if (key.includes('AIO-RF-')) {
                                type = "Sale Return"
                            }
                            credit = During[key].amount
                            totalCredit = totalCredit + During[key].amount
                            if (counter == 0) {
                                DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? OpeningBalanceValue : OpeningBalanceValue)
                            } else {
                                DuringCreditBalance = DuringCreditBalance + During[key].amount
                            }
                            counter++
                        }
                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            if (key.includes('AIO-S-')) {
                                type = "Sale"
                            }
                            if (key.includes('AIO-PR-')) {
                                type = "Purchase Return"
                            }
                            totalDebit = totalDebit + During[key].amount
                            if (counter == 0) {
                                DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                            } else {
                                DuringDebitBalance = DuringDebitBalance + During[key].amount
                            }
                            counter++
                            debit = During[key].amount
                        }
                        if (key.includes('AIO-PMT-')) {
                            if ((this.state.ac_title_data.id + "").includes("-") && !(key + "").includes("-DEBIT")) { // Expense Account Debit
                                type = "Payment"


                                totalCredit = totalCredit + During[key].amount
                                if (counter == 0) {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? OpeningBalanceValue : OpeningBalanceValue)
                                } else {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount
                                }
                                counter++
                                credit = During[key].amount

                            } else {
                                type = "Payment"


                                totalDebit = totalDebit + During[key].amount
                                if (counter == 0) {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                                } else {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount
                                }
                                counter++
                                debit = During[key].amount
                            }

                        }
                        // JV 
                        if (key.includes('AIO-JV-')) {
                            if (During[key].type == "credit") {
                                type = "JV"


                                totalCredit = totalCredit + During[key].amount
                                if (counter == 0) {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? OpeningBalanceValue : OpeningBalanceValue)
                                } else {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount
                                }
                                counter++
                                credit = During[key].amount

                            } else {
                                type = "JV"


                                totalDebit = totalDebit + During[key].amount
                                if (counter == 0) {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                                } else {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount
                                }
                                counter++
                                debit = During[key].amount
                            }

                        }
                        if (key.includes('AIO-R-')) {
                            if ((this.state.ac_title_data.id + "").includes("-") && !(key + "").includes("-CREDIT")) {
                                type = "Receipt"


                                totalDebit = totalDebit + During[key].amount
                                if (counter == 0) {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                                } else {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount
                                }
                                counter++
                                debit = During[key].amount

                            } else {
                                type = "Receipt"


                                totalCredit = totalCredit + During[key].amount
                                if (counter == 0) {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? OpeningBalanceValue : OpeningBalanceValue)
                                } else {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount
                                }
                                counter++
                                credit = During[key].amount
                            }



                        }


                        openDiff = DuringCreditBalance - DuringDebitBalance

                        if (openDiff >= 0) {
                            flag = "Cr"
                        } else {
                            flag = "Dr"
                        }


                        var invoice = key.split("-")

                        var put_data = {
                            date: During[key].date,
                            credit: this.numberWithCommas(credit.toFixed(2)),
                            debit: this.numberWithCommas(debit.toFixed(2)),
                            // view: <button className="btn btn-primary" key={invoice} onClick={() => {

                            //     if (key.includes("AIO-S-")) {
                            //         url = "/inventry/sale/id?invoiceno=" + (key.includes("-DEBIT") ? (invoice[0] + "-" + invoice[1] + "-" + invoice[2]) : key.includes("-CREDIT") ? (invoice[0] + "-" + invoice[1] + "-" + invoice[2]) : key.includes("JV") ? invoice.length == 4 ? (invoice[0] + "-" + invoice[1] + "-" + invoice[2]) : key : key)
                            //     }
                            //     else if (key.includes("AIO-P-")) {
                            //         url = "/inventry/sale/id?invoiceno=" + (key.includes("-DEBIT") ? (invoice[0] + "-" + invoice[1] + "-" + invoice[2]) : key.includes("-CREDIT") ? (invoice[0] + "-" + invoice[1] + "-" + invoice[2]) : key.includes("JV") ? invoice.length == 4 ? (invoice[0] + "-" + invoice[1] + "-" + invoice[2]) : key : key)
                            //     }
                            //     else {
                            //         alert("Disabled")
                            //     }

                            //     fetch(url).then(res => res.json()).then((sale) => {
                            //         this.setState({ items_rows: sale.items })
                            //         this.setState({ Modalinvoiceno: sale.invoiceno })
                            //         this.setState({ Modalclientid: sale.clientid })
                            //         this.setState({ apply_gst: sale.apply_gst })
                            //         this.setState({ gst: sale.gst })
                            //         this.setState({ gst_price: sale.gst_price })
                            //         this.setState({ net_price: sale.net_price })
                            //         this.setState({ Modalclientname: sale.clientname })
                            //         this.setState({ Modalclienttype: sale.clienttype })
                            //         this.setState({ Modalamount: sale.amount })
                            //         this.setState({ Modalpayable: sale.payable })
                            //         this.setState({ Modaldiscount: sale.discount })
                            //         this.setState({ Modalitems: sale.items })
                            //         this.setState({ Modalremarks: sale.remarks })
                            //         this.setState({ Modalcash: sale.cash })
                            //         this.setState({ show: true })
                            //     })





                            // }} >VIEW</button>,

                            transactions_id: key.includes("-DEBIT") ? (invoice[0] + "-" + invoice[1] + "-" + invoice[2]) : key.includes("-CREDIT") ? (invoice[0] + "-" + invoice[1] + "-" + invoice[2]) : key.includes("JV") ? invoice.length == 4 ? (invoice[0] + "-" + invoice[1] + "-" + invoice[2]) : key : key,
                            type: type,
                            remarks : During[key].remarks,
                            balance: this.numberWithCommas(Math.abs(openDiff).toFixed(2))
                        }
                        the_rows.push(put_data)
                    })
                    if (the_rows.length == 0) {
                        openDiff = OpeningDiff
                    }
                    this.setState({ rows: the_rows })
                    this.setState({ total_credit: this.numberWithCommas(totalCredit.toFixed(2)) })
                    this.setState({ total_debit: this.numberWithCommas(totalDebit.toFixed(2)) })
                    this.setState({ total_balance: this.numberWithCommas(Math.abs(openDiff).toFixed(2)) })
                    this.setState({ final_flag: flag })
                    this.setState({ save: false })


                }).catch(() => {
                    this.setState({ save: false })
                })


            }

        }
    }
    Clear_Text = () => {
        try {
            const instance = this._typeahead_1.getInstance();
            instance.clear();
        } catch (e) {

            console.log(e)

        }
    }
    getItems() {
        var columns = this.state.items_columns;
        var rows = this.state.items_rows;
        return { columns, rows };

    }
    Accounts = () => {

        return (
            <div>
                <Autocomplete
                    caseSensitive={false}
                    id="filtering-example"
                    ignoreDiacritics={true}
                    ref={(ref) => this._typeahead_1 = ref}
                    onChange={(event, text) => {
                        var text = text + ""
                        var name = text.split('||')

                        var id = this.state.all_accounts[name[0]]
                        console.log(id)
                        this.setState({ ac_title_data: id })
                    }}
                    options={this.state.options}
                    renderInput={(params) => <TextField {...params} label="AC Title.." />}
                    placeholder="A/C Title..."
                />
            </div>
        );
    };
    getData() {
        var columns = this.state.columns;
        var rows = this.state.rows;
        var filter = this.original;
        return { columns, rows };

    }
    handleChangeFrom = date => {
        this.setState({
            date_from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            date_to: date
        });
    };
    render() {
        return (
            <Grid>

                {
                    this.state.load ?
                        <Grid md={12}>
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody className="all-icons">
                                    <form onSubmit={this.onSubmit} className="Notifications">
                                        <h2><strong>General Ledger</strong></h2>




                                        <Grid container >
                                            <Grid sm={4}>
                                                <b>A/C Title:</b>
                                                {this.Accounts()}
                                            </Grid>
                                            <Grid sm={1}></Grid>
                                            <Grid sm={2}>
                                                <b>From :</b>
                                                <br />
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker inputFormat="dd/MM/yyyy"

                                                        value={this.state.date_from}
                                                        onChange={(date_from) => {
                                                            this.setState({ date_from: date_from })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />

                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid sm={2}>
                                                <b>To :</b>
                                                <br />
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker inputFormat="dd/MM/yyyy"

                                                        value={this.state.date_to}
                                                        onChange={(date_to) => {
                                                            this.setState({ date_to: date_to })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />

                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid sm={1}></Grid>
                                            <Grid sm={2}>
                                                <br />
                                            <Button variant="contained" size="md" block type="submit" disabled={this.state.save}>Search</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container controlId="exampleForm.ControlSelect2">
                                            <Grid sm={4}>
                                            <b>Opening Balance ({this.state.opening_flag})</b>
                                                <TextField
                                                    fullWidth
                                                    id="filled-basic"
                                                    variant="filled" type="text" value={this.numberWithCommas(this.state.opening_balance.toFixed(2))} class="credit form-control" placeholder="Opening Balance..." />
                                           
                                            </Grid>
                                           
                                            <Grid sm={2}>
                                            </Grid>
                                            <Grid sm={2}>

                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container controlId="formHorizontalEmail">
                                        <Grid md={12}>
                                            {
                                                this.state.save
                                                    ?
                                                    <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                                                    :
                                                    <TableContainer  >

                                                        <MaterialTable
                                                            title=""
                                                            icons={tableIcons}
                                                            columns={[
                                                                { title: 'Date', field: 'date', type: "date" },
                                                                { title: 'Invoice No.', field: 'transactions_id' },
                                                               
                                                                { title: 'Type', field: 'type' },
                                                                { title: 'Remarks', field: 'remarks' },
                                                                {
                                                                    title: 'Debit', field: 'debit',
                                                                    cellStyle: {
                                                                        color: 'red',
                                                                        fontWeight: "bold"
                                                                    },
                                                                    headerStyle: {
                                                                        backgroundColor: 'red',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Credit', field: 'credit',
                                                                    cellStyle: {
                                                                        color: 'blue',
                                                                        fontWeight: "bold"
                                                                    },
                                                                    headerStyle: {
                                                                        backgroundColor: 'blue',
                                                                    }
                                                                },
                                                                {
                                                                    title: 'Balance', field: 'balance',
                                                                    cellStyle: {
                                                                        color: 'black',
                                                                        fontWeight: "bold"
                                                                    },
                                                                    headerStyle: {
                                                                        backgroundColor: 'black',
                                                                    }

                                                                }

                                                            ]}
                                                            data={this.state.rows}
                                                            options={{
                                                                headerStyle: {
                                                                    backgroundColor: '#01579b',
                                                                    color: '#FFF',
                                                                    position: 'sticky',
                                                                    top: 0
                                                                },
                                                                maxBodyHeight: '500px' ,
                                                                search: true,
                                                                grouping: false,
                                                                exportButton: true,
                                                                selection: false,
                                                                showTitle: true,
                                                                paging: false

                                                            }}

                                                        />
                                                    </TableContainer>

                                            }

                                        </Grid>
                                    </Grid>

                                    <Grid container controlId="exampleForm.ControlSelect2">
                                        <Grid sm={6}>
                                        </Grid>
                                        <Grid sm={2}>
                                            <b class="debit">Debit</b>
                                            <TextField

                                                id="filled-basic"
                                                variant="filled" type="text" value={(this.state.total_debit)} class="debit form-control" required placeholder="Debit..." />
                                        </Grid>
                                        <Grid sm={2}>
                                            <b class="credit" >Credit</b>
                                            <TextField

                                                id="filled-basic"
                                                variant="filled" type="text" value={(this.state.total_credit)} class="credit form-control" required placeholder="Credit..." />
                                        </Grid>
                                        <Grid sm={2}>
                                            <b >Total Balance({this.state.final_flag})</b>
                                            <TextField

                                                id="filled-basic"
                                                variant="filled" type="text" value={(this.state.total_balance)} class=" form-control" required placeholder="Total Balance..." />
                                        </Grid>

                                    </Grid>
                                


                                </CardBody>
                                <Modal
                                        open={this.state.add}
                                        onClose={() => this.setState({ add: false })}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                {this.state.title}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                {this.state.body}
                                            </Typography>
                                        </Box>
                                    </Modal>
                            </Card>
                        </Grid>
                        :
                        <div style={{
                            margin: "auto",
                            marginTop: "10em",
                            height: "100em"
                        }}>
                            <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                        </div>
                }

                {/* <Modal
                    size="xl"
                    show={this.state.show}
                    onHide={() => this.setState({ show: false })}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <b> Invoice </b>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>

                            <Row>
                                <Grid lg={12}>
                                    <Card>
                                        <CardHeader>
                                        </CardHeader>
                                        <CardBody className="all-icons">
                                            <Form >

                                                <Grid container controlId="formHorizontalEmail">
                                                    <Grid sm={4}>
                                                        <b>Client Selection</b>
                                                        <Form.Group id="formGridCheckbox">
                                                            <Form.Check
                                                                custom
                                                                inline
                                                                checked={this.state.Modalclienttype == 'vendor'}
                                                                disabled
                                                                type="checkbox"
                                                                label="Vendor"
                                                                name="vendor"
                                                                id="vendor"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                inline
                                                                checked={this.state.Modalclienttype == 'customer'}
                                                                disabled

                                                                type="checkbox"
                                                                label="Customer"
                                                                name="customer"
                                                                id="customer"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                inline
                                                                checked={this.state.Modalclienttype == 'CASH'}
                                                                disabled

                                                                type="checkbox"
                                                                label="Cash"
                                                                name="cash"
                                                                id="cash"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid md={5}>
                                                        {this.state.Modalclienttype == 'CASH' ? <><b>Cash</b>
                                                            <Form.Control as="select" disabled>
                                                                <option  >{this.state.Modalclientname}</option>
                                                            </Form.Control>
                                                        </> : this.state.Modalclienttype == 'vendor' ?
                                                            <>
                                                                <b>Vendor</b>
                                                                <Form.Control as="select" disabled>
                                                                    <option  >{this.state.Modalclientname}</option>
                                                                </Form.Control>
                                                            </>
                                                            :
                                                            <><b>Customer</b>
                                                                <Form.Control as="select" disabled>
                                                                    <option  >{this.state.Modalclientname}</option>
                                                                </Form.Control>
                                                            </>
                                                        }
                                                    </Grid>


                                                    <Grid md={2}>
                                                    </Grid>
                                                </Grid>
                                                <Grid container controlId="formHorizontalEmail">
                                                    <Grid md={2}>
                                                        <b>Apply GST (%)</b>
                                                        <br />
                                                        <Form.Check
                                                            custom
                                                            inline
                                                            checked={this.state.apply_gst}
                                                            disabled
                                                            type="checkbox"
                                                            label="GST"
                                                            name="gst"
                                                            id="gst"
                                                        />
                                                    </Grid>
                                                    <Grid md={2}>
                                                        {this.state.apply_gst ? <><b>GST Percentage (%)</b>
                                                            <Form.Control type="number" value={this.state.gst} disabled />
                                                        </> : <></>}
                                                    </Grid>
                                                    <Grid md={8}></Grid>
                                                </Grid>
                                                <Grid container controlId="formHorizontalEmail">
                                                    <Grid md={12}>
                                                        <MDBDataTable
                                                            sortable={false}
                                                            bordered
                                                            noRecordsFoundLabel={"No Item"}
                                                            hover
                                                            small
                                                            data={this.getItems()}
                                                        ></MDBDataTable>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid sm={8}>
                                                        <b>Remarks</b>
                                                        <TextField

id="filled-basic"
variant="filled" type="text" value={this.state.Modalremarks} disabled placeholder="Remarks..." />
                                                    </Grid>
                                                    <Grid sm={1}>
                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <b>Amount</b>
                                                         <TextField

                                                        id="filled-basic"
                                                        variant="filled" type="number" value={this.state.Modalamount} disabled placeholder="Amount..." />
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid sm={8}>
                                                    </Grid>
                                                    <Grid sm={1}>
                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <b>GST</b>
                                                        <Form.Control type="number" value={this.state.gst_price} disabled placeholder="GST..." />
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid sm={8}>
                                                    </Grid>
                                                    <Grid sm={1}>
                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <b>Net Value</b>
                                                        <Form.Control type="number" value={this.state.net_price} disabled placeholder="Net Value..." />
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid sm={8}>
                                                    </Grid>
                                                    <Grid sm={1}>
                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <b>Discount</b>
                                                        <Form.Control type="number" value={this.state.Modaldiscount} disabled placeholder="Discount..." />
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid sm={3}>
                                                    </Grid>
                                                    <Grid sm={3}>
                                                    </Grid>
                                                    <Grid sm={3}>
                                                    </Grid>
                                                    <Grid sm={3}>
                                                        <b>Payable</b>
                                                        <Form.Control type="number" value={this.state.Modalpayable} disabled placeholder="Payable..." />
                                                    </Grid>
                                                </Grid>
                                            </Form>

                                        </CardBody>
                                    </Card>
                                </Grid>

                            </Row>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal> */}

            </Grid>
        );
    };
}
export default GeneralLedger;
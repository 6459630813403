import React, { Component } from "react";
import {
    Form, Col,
    Row
} from 'react-bootstrap';
import 'whatwg-fetch';
import { MDBDataTable } from "mdbreact";
import { Typeahead } from 'react-bootstrap-typeahead';
import Image from 'react-bootstrap/Image'

import { Card, CardHeader, CardBody, Input } from "reactstrap";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Autocomplete from '@mui/material/Autocomplete';

import ReactLoading from "react-loading";
import { forwardRef } from 'react';

import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';


import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import TextField from '@mui/material/TextField';

import MaterialTable from 'material-table'

import { Grid, Container, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};




class CreateSaleComponent extends Component {
    constructor() {
        super();
        this.state = {
            columns: [
                {
                    label: "Code",
                    field: "code",
                    sort: "asc"
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Rate",
                    field: "rate",
                    sort: "asc"
                },
                {
                    label: "Quantity",
                    field: "quantity",
                    sort: "asc"
                },
                {
                    label: "GST",
                    field: "gst",
                    sort: "asc"
                },
                {
                    label: "Discount",
                    field: "discount",
                    sort: "asc"
                },

                {
                    label: "Amount",
                    field: "amount",
                    sort: "asc"
                }
                ,
                {
                    label: "Remove",
                    field: "remove",
                    sort: "asc"
                }
            ],
            rows: [
            ],
            item_columns: [
                {
                    label: "Code",
                    field: "code",
                    sort: "asc"
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Category",
                    field: "category",
                    sort: "asc"
                },
                {
                    label: "Rate",
                    field: "rate",
                    sort: "asc"
                },
                {
                    label: "Stock",
                    field: "stock",
                    sort: "asc"
                },
                {
                    label: "Add",
                    field: "add",
                    sort: "asc"
                }
            ],
            vendor_rows: [
            ],
            customer_rows: [
            ],
            cash_rows: [
            ],
            call: () => {
                var columns = this.state.columns;
                var rows = this.state.rows;
                return { columns, rows };
            },
            code: 0,
            duplicate: [],

            name: '',
            cash_accounts: [],
            cash_data: null,
            min_stock: 0,
            rate: 0,
            load: false,
            options: [],

            TempDiscount: 0,
            DiscountPrice: 0,
            aLLocatedPerDiscount: 0,

            stock: 0,
            quantity: 0,
            save: false,
            gst_cash: 0,
            gst_customer: 0,
            gst_vendor: 0,
            gst_value: 0,
            barcode: 0,
            vendor: true,
            gst: false,
            sale_rate: 0,
            remarks: '',
            customer: false,
            vendor_data: null,
            table: {},
            profit_ratio: [],
            cash_profit: 0,
            customer_data: null,
            vendor_first: [],
            customer_first: [],
            cost: 0,
            show: false,
            cash: false,
            amount: 0,
            payable: 0,
            gst_price: 0,
            net_value: 0,
            invoice_items: [],
            discount: 0,
            alert: false,
            vendor_search_items: {},
            customer_search_items: {},
            cash_search_items: {},
            original_record: [],
            category: '',
            client_vendor: [],
            client_customer: [],
            add: false,
            title: '',
            body: '',
            date: new Date(),
            // 17 Aug 2021
            searchName: "",
            searchItems: [],
            // 19 Mar 2022
            AccountToCharge: "",
            AccountSelectedVendor: {},
            AccountSelectedCustomer: {},
            AccountSelectedAcc: {},

            openSearchItem: false


        };

        this.onSubmit = this.onSubmit.bind(this);
        this.Reload = this.Reload.bind(this)
        this.AddLine = this.AddLine.bind(this)
        this.Add = this.Add.bind(this)
        this.ReCalculate = this.ReCalculate.bind(this)
        this.getValue = this.getValue.bind(this);
        this.getSearchItems = this.getSearchItems.bind(this)
    }
    handleChange = date => {
        this.setState({
            date: date
        });
    };
    componentWillMount() {
        fetch('/inventry/client/vendor', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status == 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((clients) => {
            console.log(clients)
            this.setState({ client_vendor: clients })
            var objVender = {}
            clients.filter((client) => {
                var profits = this.state.profit_ratio
                profits[client.id] = client.profit_ratio

                objVender[client.name] = (client)

                this.setState({ profit_ratio: profits })
            })

            this.setState({ AccountSelectedVendor: objVender })

            if (clients.length > 0) {
                this.setState({ vendor_data: clients[0] })
            }

        })
        fetch('/inventry/accounts/cash', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status == 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((cash_accounts) => {
            this.setState({ cash_accounts: cash_accounts })

            var objAcc = {}
            cash_accounts.filter((acc) => {
                objAcc[acc.title] = acc
            })

            this.setState({ AccountSelectedAcc: objAcc })

            if (cash_accounts.length > 0) {
                this.setState({ cash_data: cash_accounts[0] })
            }

        })
        fetch('/inventry/client/customer', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status == 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((clients) => {

            var objCustomer = {}
            clients.filter((client) => {
                var profits = this.state.profit_ratio
                profits[client.id] = client.profit_ratio

                objCustomer[client.name] = client

                this.setState({ profit_ratio: profits })
            })
            this.setState({ AccountSelectedCustomer: objCustomer })

            this.setState({ client_customer: clients })
            if (clients.length > 0) {
                this.setState({ customer_data: clients[0] })
            }

        })
        fetch(`/inventry/configure`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        })
            .then((response) => response.json())
            .then((configure) => {
                this.setState({ gst_cash: configure[0].cash })
                this.setState({ gst_customer: configure[0].customer })
                this.setState({ gst_vendor: configure[0].vendor })
                this.setState({ cash_profit: configure[0].cash_profit })
                this.setState({ load: true })
            });



    }
    Add(code, name, category, rate, stock) {
        this.setState({ code: code })
        this.setState({ name: name })
        this.setState({ rate: rate })
        this.setState({ sale_rate: rate })
        this.setState({ stock: stock })
        this.setState({ category: category })
        this.setState({ quantity: 0 })
        this.setState({ show: false })
    }
    Reload() {
        fetch('/inventry/client/vendor', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status == 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((clients) => {
            console.log(clients)
            this.setState({ client_vendor: clients })
            clients.filter((client) => {
                var profits = this.state.profit_ratio
                profits[client.id] = client.profit_ratio
                this.setState({ profit_ratio: profits })
            })
            if (clients.length > 0) {
                this.setState({ vendor_data: clients[0] })
            }

        })
        fetch('/inventry/client/customer', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status == 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((clients) => {
            console.log(clients)
            clients.filter((client) => {
                var profits = this.state.profit_ratio
                profits[client.id] = client.profit_ratio
                this.setState({ profit_ratio: profits })
            })

            this.setState({ client_customer: clients })
            if (clients.length > 0) {
                this.setState({ customer_data: clients[0] })
            }

        })
        fetch(`/inventry/configure`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

        })
            .then((response) => response.json())
            .then((configure) => {
                console.log(configure)
                this.setState({ gst_cash: configure[0].cash })
                this.setState({ gst_customer: configure[0].customer })
                this.setState({ gst_vendor: configure[0].vendor })
                this.setState({ cash_profit: configure[0].cash_profit })
            });

    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    onSubmit() {
        this.setState({ save: true })
        var array = this.state.rows
        var book_items = []
        var configure = {
            gst_vendor: this.state.gst_vendor,
            gst_cash: this.state.gst_cash,
            gst_customer: this.state.gst_customer
        }
        if (this.state.vendor) {
            if (this.state.AccountToCharge == "" || this.state.AccountToCharge == null) {
                this.setState({ title: "Alert" })
                this.setState({ body: "Invalid Client" })
                this.setState({ alert: true })
                this.setState({ save: false })
                return
            }

        } else if (this.state.customer) {
            if (this.state.AccountToCharge == "" || this.state.AccountToCharge == null) {
                this.setState({ title: "Alert" })
                this.setState({ body: "Invalid Client" })
                this.setState({ alert: true })
                this.setState({ save: false })
                return
            }

        }
        else if (this.state.cash) {
            if (this.state.AccountToCharge == "" || this.state.AccountToCharge == null) {
                this.setState({ title: "Alert" })
                this.setState({ body: "Invalid Client" })
                this.setState({ alert: true })
                this.setState({ save: false })
                return
            }

        }
      
        if (array.length > 0 && this.state.amount != 0) {
            if (this.state.cash) {
                var data = {
                    clientid: this.state.AccountSelectedAcc[this.state.AccountToCharge].id,
                    clientname: this.state.AccountSelectedAcc[this.state.AccountToCharge].title,
                    clienttype: this.state.AccountSelectedAcc[this.state.AccountToCharge].type,
                    amount: this.state.amount,
                    payable: this.state.payable,
                    configure: configure,
                    discount: this.state.discount,
                    items: this.state.rows,
                    net_price: this.state.net_value,
                    gst_price: this.state.gst_price,
                    profit_ratio: this.state.cash_profit,
                    gst: this.state.gst_value,
                    apply_gst: this.state.gst,
                    cash: true,
                    item_codes: [],
                    remarks: this.state.remarks,
                    date: this.state.date,
                    userId: localStorage.getItem('id')

                }
                fetch('/inventry/sale', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((res) => { return res.json() }).then(res => {
                    if (res.status == 400) {
                        this.setState({ code: 0 })
                        this.setState({ name: '' })
                        this.setState({ rate: 0 })
                        this.setState({ stock: 0 })
                        this.setState({ quantity: 0 })
                        this.setState({ rows: [] })
                        this.setState({ amount: 0 })
                        this.setState({ discount: 0 })
                        this.setState({ payable: 0 })
                        this.setState({ title: "Sale" })
                        this.setState({ body: "Invoice Already Exists!!" })
                        this.setState({ alert: true })
                        this.setState({ save: false })
                    } else {

                        this.setState({ code: 0 })
                        this.setState({ name: '' })
                        this.setState({ rate: 0 })
                        this.setState({ stock: 0 })
                        this.setState({ quantity: 0 })
                        this.setState({ rows: [] })
                        this.setState({ amount: 0 })
                        this.setState({ discount: 0 })
                        this.setState({ payable: 0 })
                        this.setState({ title: "Sale" })
                        this.setState({ body: `Invoice No: ` + res.invoice })
                        this.setState({ alert: true })
                        this.setState({ save: false })

                    }
                })
            } else {
                if (this.state.vendor) {
                    var data = {
                        clientid: this.state.AccountSelectedVendor[this.state.AccountToCharge].id,
                        clientname: this.state.AccountSelectedVendor[this.state.AccountToCharge].name,
                        clienttype: 'vendor',
                        amount: this.state.amount,
                        net_price: this.state.net_value,
                        configure: configure,
                        gst_price: this.state.gst_price,
                        profit_ratio: 0,
                        gst: this.state.gst_value,
                        apply_gst: this.state.gst,
                        payable: this.state.payable,
                        discount: this.state.discount,
                        items: this.state.rows,
                        userId: localStorage.getItem('id'),
                        item_codes: [],
                        cash: false,
                        remarks: this.state.remarks,
                        date: this.state.date
                    }
                    fetch('/inventry/sale', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then((res) => { return res.json() }).then(res => {
                        if (res.status == 400) {
                            this.setState({ code: 0 })
                            this.setState({ name: '' })
                            this.setState({ rate: 0 })
                            this.setState({ stock: 0 })
                            this.setState({ quantity: 0 })
                            this.setState({ rows: [] })
                            this.setState({ amount: 0 })
                            this.setState({ discount: 0 })
                            this.setState({ payable: 0 })
                            this.setState({ title: "Sale" })
                            this.setState({ body: "Invoice Already Exists!!" })
                            this.setState({ alert: true })
                            this.setState({ save: false })
                        } else {

                            this.setState({ code: 0 })
                            this.setState({ name: '' })
                            this.setState({ rate: 0 })
                            this.setState({ stock: 0 })
                            this.setState({ quantity: 0 })
                            this.setState({ rows: [] })
                            this.setState({ amount: 0 })
                            this.setState({ discount: 0 })
                            this.setState({ payable: 0 })
                            this.setState({ title: "Sale" })
                            this.setState({ body: `Invoice No: ` + res.invoice })
                            this.setState({ alert: true })
                            this.setState({ save: false })

                        }
                    })
                } else {
                    var data = {
                        clientid: this.state.AccountSelectedCustomer[this.state.AccountToCharge].id,
                        clientname: this.state.AccountSelectedCustomer[this.state.AccountToCharge].name,
                        clienttype: 'customer',
                        amount: this.state.amount,
                        configure: configure,
                        payable: this.state.payable,
                        net_price: this.state.net_value,

                        item_codes: [],
                        gst_price: this.state.gst_price,
                        profit_ratio: 0,
                        gst: this.state.gst_value,
                        apply_gst: this.state.gst,
                        discount: this.state.discount,
                        cash: false,
                        items: this.state.rows,
                        remarks: this.state.remarks,
                        date: this.state.date,
                        userId: localStorage.getItem('id')
                    }
                    fetch('/inventry/sale', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then((res) => { return res.json() }).then(res => {
                        if (res.status == 400) {
                            this.setState({ code: 0 })
                            this.setState({ name: '' })
                            this.setState({ rate: 0 })
                            this.setState({ stock: 0 })
                            this.setState({ quantity: 0 })
                            this.setState({ rows: [] })
                            this.setState({ title: "Sale" })
                            this.setState({ body: "Invoice Already Exists!!" })
                            this.setState({ alert: true })
                            this.setState({ save: false })
                        } else {

                            this.setState({ code: 0 })
                            this.setState({ name: '' })
                            this.setState({ rate: 0 })
                            this.setState({ stock: 0 })
                            this.setState({ quantity: 0 })
                            this.setState({ rows: [] })

                            this.setState({ title: "Sale" })
                            this.setState({ body: `Invoice No: ` + res.invoice })
                            this.setState({ alert: true })
                            this.setState({ save: false })
                        }
                    })
                }
            }
        } else {
            this.setState({ title: "Alert" })
            this.setState({ body: "Kindly Create Invoice First !!!" })
            this.setState({ alert: true })
            this.setState({ save: false })
        }
    }
    async ReCalculate(objArr) {
        var amount = 0
        var total_gst = 0
        var total_discount = 0


        const dataArr = [...objArr]

        var res = await dataArr.map((item) => {

            var amt = parseFloat(parseFloat(item.rate) * parseInt(item.quantity))

            var AfterDiscount = amt - item.discount

            total_discount = item.discount + total_discount

            var gst = item.gst

            var amount_gst = (AfterDiscount / 100) * gst

            item.amount = amount_gst + AfterDiscount

            total_gst = total_gst + amount_gst

            amount = parseFloat(amt) + amount

            return amount
        })

        Promise.all(res).then((values) => {
            this.setState({ gst_price: total_gst })
            this.setState({ net_value: (amount + total_gst) })
            this.setState({ payable: ((amount + total_gst) - total_discount) })
            this.setState({ amount: amount })
            this.setState({ discount: total_discount })
        })

    }
    getValue(code) {
        console.log(code)
        return this.state.table[code]
    }
    AddLine(e) {
        e.preventDefault();
        if (this.state.code != 0) {
            if (this.state.quantity > 0) {
                if (this.state.rate > 0) {


                    var objArr = []


                    var obj = {
                        code: this.state.code,
                        name: this.state.name,
                        quantity: this.state.quantity,
                        rate: parseFloat(this.state.rate).toFixed(2),
                        amount: parseFloat((this.state.rate * this.state.quantity).toFixed(2)),
                        gst: this.state.gst ? this.state.gst_value : 0,
                        discount: 0
                    }
                    objArr.push(obj)






                    this.setState({ rows: [...this.state.rows, ...objArr] })

                    this.ReCalculate([...this.state.rows, ...objArr])

                    this.setState({ code: 0 })
                    this.setState({ name: '' })
                    this.setState({ stock: 0 })
                    this.setState({openSearchItem:true})
                    document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0].click()
                    document.getElementsByClassName("rbt-input-main")[0].focus()
                } else {
                    this.setState({ title: "Alert" })
                    this.setState({ body: "Invlaid Rate" })
                    this.setState({ alert: true })

                }
            } else {
                this.setState({ title: "Alert" })
                this.setState({ body: "Invlaid Quantity" })
                this.setState({ alert: true })

            }



        } else {
            this.setState({ title: "Alert" })
            this.setState({ body: "Kindly Select Item!!" })
            this.setState({ alert: true })

        }

    }
    Add_Item = () => {

        return (
            <div>
                <Autocomplete
                    openOnFocus
                    open={this.state.openSearchItem}
                    onOpen={() => this.setState({ openSearchItem: true })}
                    onClose={() => this.setState({ openSearchItem: false })}
                    caseSensitive={false}
                    id="controllable-states-demo"
                    ignoreDiacritics={true}
                    onInputChange={(event, text) => {
                        if (text == "") {
                            return
                        }
                        this.setState({ searchName: text })
                    }}
                    style={{
                        width: "20em"
                    }}
                    onChange={(event, text) => {
                        console.log(text)
                        var text = text + ""
                        if (text.trim() != "") {

                            var temp = {
                                name: text
                            }
                            fetch('/inventry/item/rate', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(temp)
                            }).then(res => {
                                if (res.status == 400) {
                                    alert('No Category Found');
                                } else {
                                    return res.json()
                                }
                            }).then((data) => {

                                this.Add(data.code, data.name, data.category, data.rate.toFixed(2), data.stock)
                            })
                            document.getElementById("Select_Quality").focus()
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label="Search" />}
                    clearButton={true}
                    autoFocus={true}
                    clearText={this.state.openSearchItem}
                    clearOnEscape={this.state.openSearchItem}
                    options={this.state.searchItems}
                    placeholder="Items..."
                />
            </div>
        );
    };
    getData() {
        var columns = this.state.columns;
        var rows = this.state.rows;
        var filter = this.original;
        return { columns, rows };

    }
    getSearchItems() {
        return this.state.searchItems;
    }
    getItems() {
        if (this.state.vendor) {
            var columns = this.state.item_columns;
            var rows = this.state.vendor_rows;
            return { columns, rows };
        }
        else if (this.state.customer) {
            var columns = this.state.item_columns;
            var rows = this.state.customer_rows;
            return { columns, rows };
        }
        else if (this.state.cash) {
            var columns = this.state.item_columns;
            var rows = this.state.cash_rows;
            return { columns, rows };
        }

    }

    render() {
        return (
            <Grid container>
                {
                    this.state.load ?

                        <Grid item lg={12}>
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody className="all-icons">
                                    <form onSubmit={this.AddLine}>

                                        <Grid container spacing={5}>
                                            <Grid item sm={2}>
                                                <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Date</b>
                                                <div>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DatePicker inputFormat="dd/MM/yyyy"

                                                            value={this.state.date}
                                                            onChange={(date) => {
                                                                this.setState({ date: date })
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />

                                                    </LocalizationProvider>
                                                </div>
                                            </Grid>
                                            <Grid item sm={4}>

                                                <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Search - {this.state.searchName}</b>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "100%"

                                                }}>
                                                    {this.Add_Item()}
                                                    <Button variant="contained" size="md" onClick={() => {

                                                        if (this.state.searchName.trim() == "") {
                                                            alert("Invalid Product Name")
                                                            return
                                                        }
                                                        var temp = {
                                                            name: this.state.searchName
                                                        }
                                                        fetch('/inventry/item/ItemsNameWise', {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify(temp)
                                                        }).then(res => {
                                                            if (res.status == 400) {
                                                                alert('No Category Found');
                                                            } else {
                                                                return res.json()
                                                            }
                                                        }).then((items) => {
                                                            var rows = []
                                                            items.map((data) => {
                                                                rows.push(data.name)
                                                            })

                                                            this.setState({ searchItems: rows })
                                                            this.setState({ openSearchItem: true })
                                                            document.getElementsByClassName("rbt-input-main")[0].focus()
                                                        })

                                                    }} style={{
                                                        alignSelf: "center"
                                                    }} >Search</Button>
                                                </div>
                                            </Grid>
                                            <Grid item md={2}>
                                                <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Code</b>
                                                <br />
                                                <TextField

                                                    id="filled-basic"
                                                    variant="filled" type="number" value={this.state.code} required placeholder="Item Code.." />

                                            </Grid>
                                            <Grid item md={2}>
                                                <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Name</b>
                                                <br />
                                                <TextField

                                                    id="filled-basic"
                                                    variant="filled" type="text" value={this.state.name} required placeholder="Item Name..." />
                                            </Grid>
                                            <Grid item md={2}></Grid>

                                        </Grid>

                                        <Grid container spacing={3}>

                                            <Grid item sm={3}>
                                                <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Client Selection</b>
                                                <Grid container id="formGridCheckbox" style={{ justifyContent: "space-evenly" }}>
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        checked={this.state.vendor}
                                                        disabled={this.state.cash}
                                                        onChange={() => {
                                                            this.setState({ vendor: !this.state.vendor })
                                                            this.setState({ customer: !this.state.customer })
                                                            if (this.state.vendor == false || this.state.customer == false) {
                                                                this.setState({ cash: false })
                                                            }
                                                            if (this.state.vendor == false) {
                                                                var gst = this.state.gst_vendor
                                                                this.setState({ gst_value: gst })

                                                            }
                                                            this.setState({ vendor_data: null })
                                                            this.setState({ customer_data: null })
                                                            this.setState({ cash_data: null })
                                                            this.setState({ AccountToCharge: "" })

                                                        }}
                                                        type="checkbox"
                                                        label={<b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}> Vendor </b>}
                                                        name="vendor"
                                                        id="vendor"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        checked={this.state.customer}
                                                        disabled={this.state.cash}
                                                        onChange={() => {
                                                            this.setState({ vendor: !this.state.vendor })
                                                            this.setState({ customer: !this.state.customer })
                                                            if (this.state.vendor == false || this.state.customer == false) {
                                                                this.setState({ cash: false })
                                                            }

                                                            if (this.state.customer == false) {

                                                                this.setState({ customer_rows: this.state.customer_first })
                                                                var gst = this.state.gst_customer
                                                                this.setState({ gst_value: gst })

                                                            }
                                                            this.setState({ vendor_data: null })
                                                            this.setState({ customer_data: null })
                                                            this.setState({ cash_data: null })
                                                            this.setState({ AccountToCharge: "" })


                                                        }}
                                                        type="checkbox"
                                                        label={<b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}> Customer </b>}
                                                        name="customer"
                                                        id="customer"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        checked={this.state.cash}
                                                        onChange={() => {
                                                            this.setState({ cash: !this.state.cash })
                                                            this.setState({ vendor: false })
                                                            this.setState({ customer: false })
                                                            console.log(this.state.cash)
                                                            if (this.state.cash) {
                                                                this.setState({ vendor: true })
                                                                var gst = this.state.gst_vendor
                                                                this.setState({ gst_value: gst })
                                                                this.setState({ vendor_rows: this.state.vendor_first })

                                                            }


                                                            if (this.state.cash == false) {

                                                                var gst = this.state.gst_cash
                                                                this.setState({ gst_value: gst })
                                                            }

                                                            if (this.state.customer == false) {
                                                                this.setState({ customer_rows: this.state.customer_first })
                                                            }
                                                            this.setState({ vendor_data: null })
                                                            this.setState({ customer_data: null })
                                                            this.setState({ cash_data: null })

                                                            this.setState({ AccountToCharge: "" })

                                                        }}
                                                        type="checkbox"
                                                        label={<b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}> Cash </b>}
                                                        name="cash"
                                                        id="cash"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Apply GST (%)</b>
                                                <br />
                                                <Form.Check
                                                    custom
                                                    inline
                                                    checked={this.state.gst}
                                                    onChange={(e) => {
                                                        this.setState({ gst: !this.state.gst })

                                                        if (this.state.vendor) {
                                                            this.setState({ gst_value: this.state.gst_vendor })
                                                        }
                                                        else if (this.state.customer) {
                                                            this.setState({ gst_value: this.state.gst_customer })
                                                        }
                                                        else if (this.state.cash) {
                                                            this.setState({ gst_value: this.state.gst_cash })
                                                        }
                                                        if (this.state.gst == false) {
                                                            if (this.state.vendor) {
                                                                Object.keys(this.state.table).filter((key) => {
                                                                    document.getElementById("gst" + key).value = this.state.gst_vendor
                                                                })
                                                            }
                                                            else if (this.state.customer) {
                                                                Object.keys(this.state.table).filter((key) => {
                                                                    document.getElementById("gst" + key).value = this.state.gst_customer
                                                                })
                                                            }
                                                            else if (this.state.cash) {
                                                                Object.keys(this.state.table).filter((key) => {
                                                                    document.getElementById("gst" + key).value = this.state.gst_cash
                                                                })
                                                            }
                                                        }

                                                    }}
                                                    type="checkbox"
                                                    label={<b style={{ fontSize: '1em' }}>GST</b>}
                                                    name="gst"
                                                    id="gst"
                                                />
                                            </Grid>
                                            <Grid item sm={2}>
                                                <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Quantity</b>
                                                <br />
                                                <TextField
                                                    required
                                                    variant="filled" type="number" value={this.state.quantity} id="Select_Quality" onChange={(e) => { this.setState({ quantity: e.target.value }) }} min="1" required placeholder="Quantity..." />
                                            </Grid>
                                            <Grid item sm={2} style={{ marginLeft: '0.5em' }}>
                                                <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Rate</b>
                                                <br />
                                                <TextField
                                                    required
                                                    id="filled-basic"
                                                    variant="filled" type="number" value={this.state.rate} onChange={(e) => { this.setState({ rate: e.target.value }) }} step="any" min="1" required placeholder="Rate..." />
                                            </Grid>

                                            <Grid item md={1}>
                                                <br />
                                                <Button variant="contained" size="md" type="submit" >Add</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container>

                                            <Grid item md={3}>
                                                {
                                                    <Autocomplete

                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={this.state.cash ? this.state.cash_accounts.map((option) => option.title) : this.state.vendor ? this.state.client_vendor.map((option) => option.name) : this.state.client_customer.map((option) => option.name)}
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => <TextField {...params} label="Client Selection" />}
                                                        value={this.state.AccountToCharge}
                                                        onChange={(event, newInputValue) => {
                                                            this.setState({ AccountToCharge: newInputValue })
                                                        }}

                                                    />
                                                }

                                            </Grid>
                                            <Grid item md={1}>

                                            </Grid>

                                            <Grid item md={2} style={{ marginTop: -25 }}>
                                                {this.state.gst ? <><b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>GST Percentage (%)</b>
                                                    <TextField

                                                        id="filled-basic"
                                                        variant="filled" type="number" value={this.state.gst_value} disabled={true} onChange={(e) => {
                                                            if (e.target.value >= 0) {
                                                                this.setState({ gst_value: e.target.value })
                                                                if (this.state.amount > 0) {
                                                                    var percentage = (this.state.amount / 100) * e.target.value
                                                                    this.setState({ gst_price: percentage })
                                                                    var net = this.state.amount + percentage
                                                                    this.setState({ net_value: net })
                                                                    this.setState({ payable: net })
                                                                    this.setState({ discount: 0 })
                                                                    this.setState({ aLLocatedPerDiscount: 0 })
                                                                    this.setState({ TempDiscount: 0 })
                                                                    this.setState({ DiscountPrice: 0 })
                                                                }

                                                            } else {
                                                                this.setState({ alert: true })
                                                                this.setState({ title: "Alert" })
                                                                this.setState({ body: "Invalid GST" })
                                                            }
                                                        }} />
                                                </> : <></>}
                                            </Grid>


                                            <Grid item md={8}>

                                            </Grid>
                                        </Grid>
                                    </form>
                                    <Grid container as={Row} controlId="formHorizontalEmail">
                                        <Grid item md={12}>
                                            <MaterialTable
                                                title=""
                                                icons={tableIcons}
                                                columns={[
                                                    { title: 'Code', field: 'code' },
                                                    { title: 'Name', field: 'name' },
                                                    { title: 'Rate', field: 'rate', grouping: false, type: "numeric" },
                                                    { title: 'Quantity', field: 'quantity', grouping: false, type: "numeric" },
                                                    { title: 'GST (%)', field: 'gst', grouping: false, type: "numeric" },
                                                    { title: 'Discount (Rs)', field: 'discount', grouping: false, type: "numeric" },
                                                    { title: 'Net Cost', field: 'amount', grouping: false, type: "numeric" }

                                                ]}
                                                data={this.state.rows}
                                                options={{
                                                  headerStyle: {
                                                            backgroundColor: '#01579b',
                                                            color: '#FFF',
                                                            position: 'sticky',
                                                            top: 0
                                                        },
                                                        maxBodyHeight: '850px' ,
                                                    search: false,
                                                    grouping: true,
                                                    selection: true,
                                                    showTitle: false,

                                                }}
                                                actions={[
                                                    {
                                                        tooltip: 'Remove All Selected Users',
                                                        icon: () => <DeleteOutline />,
                                                        onClick: (evt, data) => {

                                                            let _data = [...this.state.rows];
                                                            data.forEach(rd => {
                                                                _data = _data.filter(t => t.tableData.id !== rd.tableData.id);
                                                            });
                                                            this.setState({ rows: [..._data] })
                                                            this.ReCalculate([..._data])

                                                        }
                                                    }
                                                ]}
                                                editable={{

                                                    onRowUpdate: (newData, oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            setTimeout(() => {
                                                                const dataUpdate = [...this.state.rows];
                                                                const index = oldData.tableData.id;
                                                                // Update Cost

                                                                console.log(newData)

                                                                var discount = newData.discount
                                                                var gst = newData.gst
                                                                var rate = newData.rate
                                                                var quantity = newData.quantity

                                                                var amount = (quantity * rate)
                                                                console.log('-------------Discount------------------')
                                                                console.log(discount)
                                                                console.log('---------------After Discount----------------')
                                                                var AfterDiscountAmount = amount - discount
                                                                console.log(AfterDiscountAmount)
                                                                console.log('---------------After GST----------------')
                                                                var gstPercent = (AfterDiscountAmount / 100) * gst

                                                                var net_cost = AfterDiscountAmount + gstPercent
                                                                console.log(net_cost)
                                                                newData.amount = net_cost

                                                                dataUpdate[index] = newData;
                                                                this.setState({ rows: [...dataUpdate] })
                                                                this.ReCalculate([...dataUpdate])


                                                                resolve();
                                                            }, 1000)
                                                        }),
                                                    onRowDelete: oldData =>
                                                        new Promise((resolve, reject) => {
                                                            setTimeout(() => {
                                                                const dataDelete = [...this.state.rows];
                                                                const index = oldData.tableData.id;
                                                                dataDelete.splice(index, 1);
                                                                this.setState({ rows: [...dataDelete] })
                                                                this.ReCalculate([...dataDelete])

                                                                resolve()
                                                            }, 1000)
                                                        }),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item sm={3}>
                                            <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Remarks</b>
                                            <br />
                                            <Form.Control as="textarea" rows="3" value={this.state.remarks} onChange={(e) => { this.setState({ remarks: e.target.value }) }} />

                                        </Grid>
                                        <Grid item sm={3}>
                                            <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Amount</b>
                                            <br />
                                            <TextField

                                                id="filled-basic"
                                                variant="filled" type="text" value={this.numberWithCommas(this.state.amount)} step="any" min="1" required placeholder="Amount..." />
   <br />
                                            <b>Discount % (line items)</b>

                                            <span style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "2em"
                                            }}>
                                                <TextField

                                                    id="filled-basic"
                                                    variant="filled"
                                                    required
                                                    id="outlined-required"
                                                    label=""
                                                    title="Applied Discount"
                                                    label="Applied Discount"

                                                    style={{
                                                        borderRadius: 5,
                                                        fontSize: 20,
                                                        width: "20em",
                                                        fontWeight: 'bold'
                                                    }}
                                                    value={this.state.aLLocatedPerDiscount + " %"}
                                                    variant="outlined"
                                                />


                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{
                                                        height: '50%',
                                                        marginTop: "1em"
                                                    }}
                                                    onClick={
                                                        async () => {

                                                            var dis = parseFloat(this.state.DiscountPrice)
                                                            if (this.state.TempDiscount != 0) {
                                                                dis = parseFloat(((this.state.net_value) / 100) * this.state.TempDiscount)
                                                                this.setState({ DiscountPrice: dis })
                                                            }
                                                            if (this.state.DiscountPrice != 0) {
                                                                if (this.state.DiscountPrice > (this.state.net_value - this.state.discount)) {
                                                                    alert("Discount can't be more than Payable Amount")
                                                                    return
                                                                }

                                                            }


                                                            // Each Test Price Calculation

                                                            var temp_net_cost = 0
                                                            var payable = 0
                                                            var counter = 0
                                                            var index = 0
                                                            var calculating_discount = 0
                                                            var total_gst = 0




                                                            // Get All Rows

                                                            var hold = await this.state.rows.map((row) => {
                                                                // Net Cost

                                                                // Discount Value of That Row
                                                                var amt = (parseFloat(row.rate) * parseFloat(row.quantity))


                                                                var cost = amt


                                                                var vP = ((cost) / (this.state.net_value)) * 100


                                                                var allocate_the_discount = Math.round((vP * dis) / 100)

                                                                console.log("Allocate Discount = " + allocate_the_discount)

                                                                if (this.state.rows.length == (index + 1)) {

                                                                    allocate_the_discount = dis - calculating_discount

                                                                }

                                                                calculating_discount = calculating_discount + allocate_the_discount

                                                                console.log("Calculated Discount = " + calculating_discount)

                                                                if (parseFloat(cost) == 0) {
                                                                    return row
                                                                }

                                                                temp_net_cost = parseFloat(cost) - allocate_the_discount

                                                                index = index + 1
                                                                if (temp_net_cost < 0) {
                                                                    counter = counter + 1
                                                                    return row

                                                                }

                                                                var net_final_cost = temp_net_cost
                                                                payable = payable + net_final_cost

                                                                console.log("Allocated Discount = " + allocate_the_discount)

                                                                var gst = row.gst
                                                                var amount_gst = (amt / 100) * gst

                                                                total_gst = parseFloat(parseFloat(total_gst + amount_gst).toFixed(2))

                                                                row.discount = parseFloat(parseFloat(allocate_the_discount).toFixed(2))

                                                                row.amount = parseFloat(parseFloat(net_final_cost + amount_gst).toFixed(2))

                                                                return row
                                                            })

                                                            Promise.all(hold).then((values) => {
                                                                if (counter == 0) {

                                                                    this.setState({ discount: parseFloat(parseFloat(dis.toFixed(2))) })
                                                                    this.setState({ TempDiscount: 0 })
                                                                    this.setState({ aLLocatedPerDiscount: parseFloat(parseFloat((dis / (this.state.net_value)) * 100).toFixed(2)) })
                                                                    this.setState({ payable: parseFloat(parseFloat((this.state.net_value - dis).toFixed(2))) })
                                                                    this.setState({ gst_price: parseFloat(parseFloat(total_gst.toFixed(2))) })



                                                                }
                                                            })

                                                        }
                                                    }>Apply</Button>
                                            </span>
                                            <span style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginRight: "0.5em",
                                                marginTop: "2em"
                                            }}>
                                                <TextField

                                                    id="filled-basic"
                                                    variant="filled"
                                                    required
                                                    id="outlined-required"
                                                    label=""

                                                    style={{
                                                        borderRadius: 5,
                                                    }}

                                                    type="number"
                                                    onChange={(e) => {
                                                        this.setState({ TempDiscount: parseFloat(e.target.value) })
                                                        this.setState({ DiscountPrice: 0 })
                                                    }}
                                                    value={this.state.TempDiscount}
                                                    variant="outlined"
                                                />
                                                <b style={{
                                                    textAlign: "center",
                                                    marginTop: 5
                                                }}>%</b>
                                                <TextField

                                                    id="filled-basic"
                                                    variant="filled"
                                                    required
                                                    id="outlined-required"
                                                    label=""
                                                    style={{
                                                        borderRadius: 5,
                                                    }}
                                                    type="number"
                                                    onChange={(e) => {
                                                        this.setState({ DiscountPrice: parseFloat(e.target.value) })
                                                        this.setState({ TempDiscount: 0 })
                                                    }}
                                                    value={this.state.DiscountPrice}
                                                    variant="outlined"
                                                />
                                                <b style={{
                                                    textAlign: "center",
                                                    marginTop: 5
                                                }}>Rs.</b>
                                            </span>


                                        </Grid>
                                        <Grid item sm={3}>
                                            <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>GST</b>
                                            <br />
                                            <TextField

                                                id="filled-basic"
                                                variant="filled" type="text" value={this.numberWithCommas(this.state.gst_price.toFixed(2))} onChange={(e) => {


                                                }} step="any" min="1" required placeholder="GST Price..." />
                                                <br />
                                            <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Discount</b>
                                            <br />
                                            <TextField

                                                id="filled-basic"
                                                variant="filled" type="text" value={this.numberWithCommas(this.state.discount.toFixed(2))} step="any" min="1" required placeholder="Discount..." />

                                        </Grid>
                                        <Grid item sm={3}>
                                            <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Net Value</b>
                                            <br />
                                            <TextField

                                                id="filled-basic"
                                                variant="filled" type="text" value={this.numberWithCommas(this.state.net_value.toFixed(2))} onChange={(e) => {


                                                }} step="any" min="1" required placeholder="Net Value..." />
                                                   <br />
                                            <b style={{ fontSize: '1em', color: "rgb(32,55,100)" }}>Payable</b>
                                            <br />
                                            <TextField

                                                id="filled-basic"
                                                variant="filled" type="text" value={this.numberWithCommas(this.state.payable.toFixed(2))} step="any" min="1" required placeholder="Rate..." />

                                            <br />
                                            <br />
                                            <Button variant="contained" to="#" size="lg" disabled={this.state.save} style={{ fontSize: "2em" }} onClick={() => { this.onSubmit() }} >Save</Button>

                                        </Grid>
                                    </Grid>

                                    <Modal
                                        open={this.state.alert}
                                        onClose={() => this.setState({ alert: false })}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                {this.state.title}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                {this.state.body}
                                            </Typography>
                                        </Box>
                                    </Modal>



                                </CardBody>
                            </Card>
                        </Grid>
                        :
                        <div style={{
                            margin: "auto",
                            marginTop: "10em",
                            height: "100em"
                        }}>
                            <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                        </div>
                }

            </Grid>
        );
    };
}
export default CreateSaleComponent;
import React, { Component } from "react";

import 'whatwg-fetch';
import { MDBDataTable } from "mdbreact";
import {
    Form
} from 'react-bootstrap';
import { Card, CardHeader, CardBody } from "reactstrap";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReactLoading from "react-loading";
import { Grid, Container, Typography } from '@mui/material';
// ServerURL
import url from '../../serverURL.js';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


class CashInHand extends Component {
    constructor() {
        super();
        this.state = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc"
                },
                {
                    label: "Title",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Type",
                    field: "type",
                    sort: "asc"
                }
            ],
            rows: [
            ],
            id: 0,
            options: [],
            name: '',
            all_accounts: {},
            mobile: 0,
            cheque_date: new Date(),
            advance_tax: 0,
            email: '',
            _typeahead: null,
            cash_accounts: [],
            cash_data: null,
            bank_data: null,
            ac_title_data: null,
            cheque_no: 0,
            bank_accounts: [],
            all_payment: [],
            cash: true,
            bank: false,
            ntn: 0,
            stn: 0,
            address: '',
            type: 'customer',
            amount: 0,
            debit: 0,
            credit: 0,
            receiptNo: "111",
            remarks: "",
            original_record: [],
            category: '',
            categories: [],
            add: false,
            title: '',
            body: '',
            date: new Date(),
            startDate: new Date(),
            endDate: new Date(),
            Cash_Opening: 0,
            Opening: 0,
            Cash_Debit: 0,
            Cash_Credit: 0,
            save: false

        };

        this.onSubmit = this.onSubmit.bind(this);
        this.Reload = this.Reload.bind(this)
    }
    Reload() {

    }
    handleChange = date => {
        this.setState({
            date: date
        });
    };
    handleChange_Cheque = date => {
        this.setState({
            cheque_date: date
        });
    };
    componentWillMount() {
        fetch(url + 'inventry/accounts', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status == 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((accounts) => {
            console.log(accounts)
            var cash = []
            var bank = []
            var pay = []
            accounts.map((acc) => {
                if (acc.type == "CASH") {
                    cash.push(acc.title + "/" + acc.type)
                }
                if (acc.type == "BANK") {
                    bank.push(acc.title + "/" + acc.type)
                }
                pay[acc.title] = {
                    id: acc.id,
                    title: acc.title,
                    type: acc.type
                }
            })
            this.setState({ all_payment: pay })
            this.setState({ cash_accounts: cash })
            this.setState({ bank_accounts: bank })

        })

        fetch(url + 'inventry/receipt/receiptNo', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status == 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((counter) => {
            this.setState({ receiptNo: counter.ReceiptNo })
        })

        fetch(url + 'inventry/client', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status == 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((client) => {
            var cli = []
            var accounts = []
            client.map((acc) => {
                cli.push(acc.name + "/" + acc.type)
                accounts[acc.name] = {
                    id: acc.id,
                    name: acc.name,
                    type: acc.type
                }
            })
            this.setState({ all_accounts: accounts })
            this.setState({ options: cli })
        })


    }
    componentWillReceiveProps() {
        this.Reload()
    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    onSubmit(e) {
        e.preventDefault();


        if (this.state.cash && (this.state.cash_data == undefined || this.state.cash_data == null)) {
            this.setState({ title: "Cash" })
            this.setState({ body: "Cash Account is Invalid!" })
            this.setState({ add: true })
            return
        }
        if (this.state.bank && (this.state.bank_data == undefined || this.state.bank_data == null)) {
            this.setState({ title: "Bank" })
            this.setState({ body: "Bank Account is Invalid!" })
            this.setState({ add: true })
            return
        }

        if (this.state.startDate == null) {
            this.setState({ title: "Start Date" })
            this.setState({ body: "Start Date is Invalid!" })
            this.setState({ add: true })
            return
        }

        if (this.state.endDate == null) {
            this.setState({ title: "End Date" })
            this.setState({ body: "End Date is Invalid!" })
            this.setState({ add: true })
            return
        }

        var method = ""
        var title = ""
        var id = ""
        if (this.state.cash) {
            method = "CASH"
            title = this.state.cash_data.title
            id = this.state.cash_data.id
        } else {
            method = "BANK"
            title = this.state.bank_data.title
            id = this.state.bank_data.id
        }

        var data = {
            from: this.state.startDate.toISOString(),
            to: this.state.endDate.toISOString(),
            acc_id: id
        }

        this.setState({ save: true })


        fetch(url + 'inventry/reporting/generalLedger', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(async res => {

            if (res.status == 400) {
                this.setState({ title: "Alert" })
                this.setState({ body: "Receipt Already Exists!!" })
                this.setState({ add: true })
            } else {
                var transactions = await res.json()
                var Opening1 = transactions.Opening
                var During1 = transactions.During

                //////////////////// Sorting Opening Data
                var key = Object.keys(Opening1)
                    .sort(function order(key1, key2) {

                        if (new Date(Opening1[key1].date) < new Date(Opening1[key2].date)) return -1;
                        else if (new Date(Opening1[key1].date) > new Date(Opening1[key2].date)) return +1;
                        else return 0;
                    });

                var Opening = {};

                for (var i = 0; i < key.length; i++) {
                    Opening[key[i]] = Opening1[key[i]];
                    delete Opening1[key[i]];
                }

                ///////////////////

                //////////////////// Sorting During Data
                var key = Object.keys(During1)
                    .sort(function order(key1, key2) {

                        if (new Date(During1[key1].date) < new Date(During1[key2].date)) return -1;
                        else if (new Date(During1[key1].date) > new Date(During1[key2].date)) return +1;
                        else return 0;
                    });

                var During = {};

                for (var i = 0; i < key.length; i++) {
                    During[key[i]] = During1[key[i]];
                    delete During1[key[i]];
                }

                ///////////////////

                var openingBalance = 0
                var OpeningCredit = 0
                var OpeningDebit = 0
                // Opening
                if (data.acc_id == "R-1-1-1" || data.acc_id == "R-1-1-2") {
                    Object.keys(Opening).map((key) => {
                        console.log(key)
                        if (key.includes('AIO-P-') || key.includes('AIO-RF-')) {
                            OpeningDebit = OpeningDebit + Opening[key].amount
                        }
                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            OpeningCredit = OpeningCredit + Opening[key].amount
                        }
                    })
                }
                else if (data.acc_id == "C-1") {
                    Object.keys(Opening).map((key) => {
                        console.log(key)
                        if (key.includes('AIO-P-') || key.includes('AIO-RF-')) {
                            OpeningCredit = OpeningCredit + Opening[key].balance
                        }
                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            OpeningDebit = OpeningDebit + Opening[key].balance
                        }
                    })
                }
                else {
                    Object.keys(Opening).map((key) => {
                        console.log(key)
                        if (key.includes('AIO-P-') || key.includes('AIO-RF-')) {
                            OpeningCredit = OpeningCredit + Opening[key].amount
                        }
                        if (key.includes('AIO-PMT-')) {
                            if ((id + "").includes("-") && !(id + "").includes("E-")) { // Expense Account Debit
                                OpeningCredit = OpeningCredit + Opening[key].amount
                            } else {
                                OpeningDebit = OpeningDebit + Opening[key].amount
                            }
                        }
                        if (key.includes('AIO-R-')) {
                            if ((id + "").includes("-")) {
                                OpeningDebit = OpeningDebit + Opening[key].amount
                            } else {
                                OpeningCredit = OpeningCredit + Opening[key].amount
                            }

                        }
                        // jv
                        if (key.includes('AIO-JV-')) {
                            if (Opening[key].type == 'debit') {
                                OpeningDebit = OpeningDebit + Opening[key].amount
                            } else {
                                OpeningCredit = OpeningCredit + Opening[key].amount
                            }

                        }
                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            OpeningDebit = OpeningDebit + Opening[key].amount
                        }
                    })
                }

                console.log(OpeningDebit)
                console.log(OpeningCredit)
                var openDiff = OpeningCredit - OpeningDebit
                if (openDiff >= 0) {
                    //  this.setState({ opening_flag: "Cr" })
                } else {
                    //  this.setState({ opening_flag: "Dr" })
                }
                this.setState({ Opening: Math.abs(openDiff) })

                var OpeningBalanceValue = openDiff
                var DuringCreditBalance = 0
                var DuringDebitBalance = 0
                var the_rows = []

                var totalDebit = 0
                var totalCredit = 0
                var totalBalance = 0
                var openDiff = 0
                var flag = ""
                var counter = 0
                // During
                Object.keys(During).map((key) => {
                    var debit = 0
                    var credit = 0
                    var type = ""
                    if (data.acc_id == "R-1-1-1" || data.acc_id == "R-1-1-2") {

                        if (key.includes('AIO-RF-')) {

                            if (key.includes('AIO-RF-')) {
                                type = "Sale Return"
                            }
                            debit = During[key].amount
                            totalDebit = totalDebit + During[key].amount
                            if (counter == 0) {
                                DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? OpeningBalanceValue : OpeningBalanceValue)
                            } else {
                                DuringDebitBalance = DuringDebitBalance + During[key].amount
                            }
                            counter++
                        }
                        if (key.includes('AIO-S-')) {
                            if (key.includes('AIO-S-')) {
                                type = "Sale"
                            }

                            totalCredit = totalCredit + During[key].amount
                            if (counter == 0) {
                                DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                            } else {
                                DuringCreditBalance = DuringCreditBalance + During[key].amount
                            }
                            counter++
                            credit = During[key].amount
                        }
                    }
                    else if (data.acc_id == "C-1") {
                        if (key.includes('AIO-P-') || key.includes('AIO-RF-')) {
                            if (key.includes('AIO-P-')) {
                                type = "Purchase"
                            }
                            if (key.includes('AIO-RF-')) {
                                type = "Sale Return"
                            }
                            credit = During[key].balance
                            totalCredit = totalCredit + During[key].balance
                            if (counter == 0) {
                                DuringCreditBalance = DuringCreditBalance + During[key].balance + (OpeningBalanceValue > 0 ? OpeningBalanceValue : OpeningBalanceValue)
                            } else {
                                DuringCreditBalance = DuringCreditBalance + During[key].balance
                            }
                            counter++
                        }
                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            if (key.includes('AIO-S-')) {
                                type = "Sale"
                            }
                            if (key.includes('AIO-PR-')) {
                                type = "Purchase Return"
                            }
                            totalDebit = totalDebit + During[key].balance
                            if (counter == 0) {
                                DuringDebitBalance = DuringDebitBalance + During[key].balance + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                            } else {
                                DuringDebitBalance = DuringDebitBalance + During[key].balance
                            }
                            counter++
                            debit = During[key].balance
                        }
                    }
                    else {
                        if (key.includes('AIO-P-') || key.includes('AIO-RF-')) {
                            if (key.includes('AIO-P-')) {
                                type = "Purchase"
                            }
                            if (key.includes('AIO-RF-')) {
                                type = "Sale Return"
                            }
                            credit = During[key].amount
                            totalCredit = totalCredit + During[key].amount
                            if (counter == 0) {
                                DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? OpeningBalanceValue : OpeningBalanceValue)
                            } else {
                                DuringCreditBalance = DuringCreditBalance + During[key].amount
                            }
                            counter++
                        }
                        if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                            if (key.includes('AIO-S-')) {
                                type = "Sale"
                            }
                            if (key.includes('AIO-PR-')) {
                                type = "Purchase Return"
                            }
                            totalDebit = totalDebit + During[key].amount
                            if (counter == 0) {
                                DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                            } else {
                                DuringDebitBalance = DuringDebitBalance + During[key].amount
                            }
                            counter++
                            debit = During[key].amount
                        }
                        if (key.includes('AIO-PMT-')) {
                            if ((id + "").includes("-") && !(id + "").includes("E-")) { // Expense Account Debit
                                type = "Payment"


                                totalCredit = totalCredit + During[key].amount
                                if (counter == 0) {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                                } else {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount
                                }
                                counter++
                                credit = During[key].amount

                            } else {
                                type = "Payment"


                                totalDebit = totalDebit + During[key].amount
                                if (counter == 0) {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                                } else {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount
                                }
                                counter++
                                debit = During[key].amount
                            }

                        }
                        // JV 
                        if (key.includes('AIO-JV-')) {
                            if (During[key].type == "credit") {
                                type = "JV"


                                totalCredit = totalCredit + During[key].amount
                                if (counter == 0) {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                                } else {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount
                                }
                                counter++
                                credit = During[key].amount

                            } else {
                                type = "JV"


                                totalDebit = totalDebit + During[key].amount
                                if (counter == 0) {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                                } else {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount
                                }
                                counter++
                                debit = During[key].amount
                            }

                        }
                        if (key.includes('AIO-R-')) {
                            if ((id + "").includes("-")) {
                                type = "Receipt"


                                totalDebit = totalDebit + During[key].amount
                                if (counter == 0) {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                                } else {
                                    DuringDebitBalance = DuringDebitBalance + During[key].amount
                                }
                                counter++
                                debit = During[key].amount

                            } else {
                                type = "Receipt"


                                totalCredit = totalCredit + During[key].amount
                                if (counter == 0) {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount + (OpeningBalanceValue > 0 ? -OpeningBalanceValue : Math.abs(OpeningBalanceValue))
                                } else {
                                    DuringCreditBalance = DuringCreditBalance + During[key].amount
                                }
                                counter++
                                credit = During[key].amount
                            }


                        }
                    }
                    var date = new Date(During[key].date)
                    var d = date.getDate()
                    var m = date.getMonth() + 1
                    var y = date.getFullYear()

                    openDiff = DuringCreditBalance - DuringDebitBalance

                    if (openDiff >= 0) {
                        flag = "Cr"
                    } else {
                        flag = "Dr"
                    }


                    // var put_data = {
                    //   date: d + '-' + m + '-' + y,
                    //   dateString:date.toISOString(),
                    //   credit: <b class="credit" style={{float:"right"}}>{this.numberWithCommas(credit.toFixed(2))}</b>,
                    //   debit: <b class="debit" style={{float:"right"}}>{this.numberWithCommas(debit.toFixed(2))}</b>,
                    //   transactions_id: key,
                    //   type: type,
                    //   balance: <b style={{float:"right"}} >{this.numberWithCommas(Math.abs(openDiff).toFixed(2))} <b class="credit">{flag}</b></b>
                    // }
                    // the_rows.push(put_data) 
                })

                this.setState({ rows: the_rows })
                this.setState({ Cash_Credit: totalCredit })
                this.setState({ Cash_Debit: totalDebit })
                // this.setState({total_balance:this.numberWithCommas(Math.abs(openDiff).toFixed(2))})
                // this.setState({final_flag:flag})

                this.setState({ save: false })


            }
        })


    }
    Clear_Text = () => {
        try {
            const instance = this._typeahead_1.getInstance();
            instance.clear();
            instance.focus();
        } catch (e) {
            try {
                const instance = this._typeahead_2.getInstance();
                instance.clear();
                instance.focus();
            } catch (e) {

                console.log(e)
            }
        }
    }


    Accounts = () => {

        return (
            <div>
                <Autocomplete
                    caseSensitive={false}
                    id="filtering-example"
                    clearButton={true}
                    autoFocus={true}
                    ignoreDiacritics={true}
                    onChange={(event,text) => {
                        var text = text + ""
                        var name = text.split('/')
                        var id = this.state.all_accounts[name[0]]
                        console.log("Data")
                        this.setState({ ac_title_data: id })
                    }}
                    options={this.state.options}
                    renderInput={(params) => <TextField {...params} label="AC Title.." />}
                   
                    placeholder="A/C Title..."
                />
            </div>
        );
    };

    getData() {
        var columns = this.state.columns;
        var rows = this.state.rows;
        var filter = this.original;
        return { columns, rows };

    }
    render() {
        return (
            <Grid>
                <Grid lg={2}>

                </Grid>
                <Grid md={8}>
                    <Card>
                        <CardHeader>
                        </CardHeader>
                        <CardBody className="all-icons">
                            <form onSubmit={this.onSubmit} className="Notifications">
                                <Grid container>

                                    <Grid sm={12} md={12} style={{ textAlign: 'center', position: "absolute", justifyContent: "center" }}>   <h1><strong>CASH IN HAND</strong></h1></Grid>
                                 
                                </Grid>

                                <Grid container controlId="formHorizontalEmail">
                                    <Grid sm={4}>

                                    </Grid>
                                    <Grid sm={2}>
                                        <b style={{ fontSize: '1.5em' }}>FROM</b>
                                        <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker inputFormat="dd/MM/yyyy"

                                                        value={this.state.startDate}
                                                        onChange={(startDate) => {
                                                            this.setState({ startDate: startDate })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />

                                                </LocalizationProvider>

                                    </Grid>
                                    <Grid sm={2} >

                                        <b style={{ fontSize: '1.5em' }}>TO</b>
                                             <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker inputFormat="dd/MM/yyyy"

                                                        value={this.state.endDate}
                                                        onChange={(endDate) => {
                                                            this.setState({ endDate: endDate })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />

                                                </LocalizationProvider>

                                    </Grid>

                                    <Grid sm={4}>

                                    </Grid>
                                </Grid>
                                <br />
                                <br />
                                <Grid container controlId="formHorizontalEmail">
                                <Grid sm={4}>
                                        <b style={{ fontSize: '1.5em' }}>Account Selection</b>
                                        <Grid container id="formGridCheckbox" style={{justifyContent:"space-evenly"}}>
                                            <Form.Check
                                                custom
                                                inline
                                                checked={this.state.cash}
                                                onChange={() => {
                                                    this.Clear_Text();

                                                    this.setState({ cash: !this.state.cash })

                                                    this.setState({ bank: !this.state.bank })
                                                }}
                                                type="checkbox"
                                                label={<b style={{ fontSize: '1.5em' }}> CASH </b>}
                                                name="cash"
                                                id="cash"
                                            />
                                            <Form.Check
                                                custom
                                                inline
                                                checked={this.state.bank}
                                                onChange={() => {
                                                    this.Clear_Text();
                                                    this.setState({ cash: !this.state.cash })

                                                    this.setState({ bank: !this.state.bank })
                                                }}
                                                type="checkbox"
                                                label={<b style={{ fontSize: '1.5em' }}> BANK </b>}
                                                name="bank"
                                                id="bank"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sm={4}>
                                        {this.state.cash ?
                                            <b style={{ fontSize: '1.5em' }}>Cash Account</b>
                                            :
                                            <b style={{ fontSize: '1.5em' }}>Bank Account</b>
                                        }
                                        {this.state.cash ? <div>
                                            <Autocomplete
                                            key={"cash"}
                                                caseSensitive={false}
                                                id="filtering-example"
                                                ignoreDiacritics={true}
                                                ref={(ref) => this._typeahead_1 = ref}
                                                onChange={(event,text) => {
                                                    var text = text + ""
                                                    var name = text.split('/')
                                                    var id = this.state.all_payment[name[0]]
                                                    console.log("Payment Cash")
                                                    this.setState({ cash_data: id })
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Payment Acc.." />}
                    
                                                options={this.state.cash_accounts}
                                                placeholder="Cash Accounts..."
                                            />
                                        </div> : <div>
                                        <Autocomplete
                                              key={"bank"}
                                                caseSensitive={false}
                                                id="filtering"
                                                ref={(ref) => this._typeahead_2 = ref}
                                                ignoreDiacritics={true}
                                                options={this.state.bank_accounts}
                                                onChange={(event,text) => {
                                                    if (this.state.bank) {
                                                        var text = text + ""
                                                        var name = text.split('/')
                                                        var id = this.state.all_payment[name[0]]
                                                        console.log("Payment Bank")
                                                        this.setState({ bank_data: id })

                                                    }
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Payment Acc.." />}
                    
                                                placeholder="Bank Accounts..."
                                            />
                                        </div>}
                                    </Grid>
                                    <Grid sm={1}></Grid>
                                    <Grid sm={3}>
                                        <br />
                                        <br />
                                        <Button variant="contained" size="lg" block type="submit" disabled={this.state.save}>Search</Button>
                                    </Grid>
                                </Grid>
                                <br />
                                <br />
                                <br />

                                {
                                    this.state.save
                                        ?
                                        <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                                        :
                                        <>
                                            <Grid container controlId="exampleForm.ControlSelect2">
                                                <Grid sm={2}>
                                                </Grid>
                                                <Grid sm={8} style={{ textAlign: 'center' }}>
                                                    <b style={{ fontSize: '2em', textAlign: 'center' }}>Opening Cash Balance : {this.numberWithCommas(this.state.Opening.toFixed(2))}</b>
                                                </Grid>
                                                <Grid sm={2}>
                                                </Grid>
                                            </Grid>
                                            <Grid container controlId="exampleForm.ControlSelect2">
                                                <Grid sm={2}>
                                                </Grid>
                                                <Grid sm={8} style={{ textAlign: 'center', color: "blue" }}>
                                                    <b style={{ fontSize: '2em' }}>Cash Debit in the Period : {this.numberWithCommas(this.state.Cash_Debit.toFixed(2))}</b>
                                                </Grid>
                                                <Grid sm={2}>
                                                </Grid>
                                            </Grid>
                                            <Grid container controlId="exampleForm.ControlSelect2">
                                                <Grid sm={2}>
                                                </Grid>
                                                <Grid sm={8} style={{ textAlign: 'center', color: "red" }}>
                                                    <b style={{ fontSize: '2em' }}>Cash Credit in the Period : {this.numberWithCommas(this.state.Cash_Credit.toFixed(2))}</b>
                                                </Grid>
                                                <Grid sm={2}>
                                                </Grid>
                                            </Grid>
                                            <Grid container controlId="exampleForm.ControlSelect2">
                                                <Grid sm={2}>
                                                </Grid>
                                                <Grid sm={8} style={{ textAlign: 'center', color: "red" }}>
                                                    <b style={{ fontSize: '3em' }}>Cash in Hand : {this.numberWithCommas((this.state.Cash_Debit - this.state.Cash_Credit).toFixed(2))}</b>
                                                </Grid>
                                                <Grid sm={2}>
                                                </Grid>
                                            </Grid>
                                        </>
                                }


                            </form>
                            <Modal
                                        open={this.state.add}
                                        onClose={() => this.setState({ add: false })}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                {this.state.title}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                {this.state.body}
                                            </Typography>
                                        </Box>
                                    </Modal>

                        </CardBody>
                    </Card>
                </Grid>

            </Grid>
        );
    };
}
export default CashInHand;
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { Grid, Container, Typography } from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import PointofsaleComponent from './pointofsaleComponent';

// ServerURL
import url from '../../serverURL.js';

export default function Create({ createClose }) {
    const [open, setOpen] = React.useState(true);
    const [scroll, setScroll] = React.useState('paper');
    const [showConfirmation, setShowConfirmation] = React.useState(false); // Track confirmation dialog visibility

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        if (showConfirmation) {
            // If confirmation dialog is already shown, close the modal directly
            setOpen(false);
            createClose();
        } else {
            // Show confirmation dialog when handleClose is called for the first time
            setShowConfirmation(true);
        }
    };

    const handleConfirmationClose = (confirmed) => {
        setShowConfirmation(false);
        if (confirmed) {
            setOpen(false);
            createClose();
        }
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const getDate = () => {
        return `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
    }

    return (
        <div>
            <Dialog
                open={open}
                fullScreen={true}
                fullWidth={true}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent dividers={scroll === 'paper'}>
                    <PointofsaleComponent />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog
                open={showConfirmation}
                onClose={() => handleConfirmationClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm Close</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to close the modal?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmationClose(false)}>No</Button>
                    <Button onClick={() => handleConfirmationClose(true)} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
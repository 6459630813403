import React, { Component } from "react";
import {
    Form, Col,
    Row
} from 'react-bootstrap';
import 'whatwg-fetch';

import { Card, CardHeader, CardBody, Input } from "reactstrap";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';

import Autocomplete from '@mui/material/Autocomplete';

import ReactLoading from "react-loading";
import { forwardRef } from 'react';
// ServerURL
import url from '../../serverURL.js';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';


import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import TextField from '@mui/material/TextField';

import MaterialTable from 'material-table'

import { Grid, Container, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



class BalanceSheet extends Component {
    constructor() {
        super();
        this.state = {
            columns: [

                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Account Title",
                    field: "title",
                    sort: "asc"
                },
                {
                    label: "Debit (OB)",
                    field: "opening_debit",
                    sort: "asc"
                },
                {
                    label: "Credit (OB)",
                    field: "opening_credit",
                    sort: "asc"
                },
                {
                    label: "Debit (During)",
                    field: "process_debit",
                    sort: "asc"
                },
                {
                    label: "Credit (During)",
                    field: "process_credit",
                    sort: "asc"
                },
                {
                    label: "Debit (CB)",
                    field: "closing_debit",
                    sort: "asc"
                },
                {
                    label: "Credit (CB)",
                    field: "closing_credit",
                    sort: "asc"
                }


            ],
            rows: [
            ],
            call: () => {
                var columns = this.state.columns;
                var rows = this.state.rows;
                return { columns, rows };
            },
            id: 0,
            cash_accounts: [],
            bank_accounts: [],
            date_from: new Date().setMonth(new Date().getMonth() - 1),
            date_to: new Date(),
            final_flag: '',
            total_opening_debit: 0,
            total_opening_credit: 0,
            total_process_debit: 0,
            total_process_credit: 0,
            total_closing_debit: 0,
            total_closing_credit: 0,
            cash: true,
            bank: false,
            type: 'customer',
            ac_title_data: null,
            all_accounts: {},
            original_record: [],
            body: '',
            title: '',
            opening_balance: 0,
            opening_flag: '',
            add: false,
            date: new Date(),
            save: false,
            avGbyInvoice: {},

            revenue: [],
            expense: [],
            analytics: [],
            asset: [],
            libilities: [],

            total_assets: 0,
            total_libilities: 0,

            total_owner_equity: 0,
            owner_equity: []


        };

        this.onSubmit = this.onSubmit.bind(this);
    }
    componentWillMount() {


    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    onSubmit(e) {
        e.preventDefault();

        this.setState({ total_opening_credit: 0 })
        this.setState({ total_opening_debit: 0 })
        this.setState({ total_process_credit: 0 })
        this.setState({ total_process_debit: 0 })
        var data = {
            from: this.state.date_from,
            to: this.state.date_to
        }
        this.setState({ save: true })
        var url = "/inventry/reporting/costofsales"
        data.clienttype = ""

        var OpeningDebit_COS = 0
        var OpeningCredit_COS = 0
        var ProcessDebit = 0
        var ProcessCredit = 0
        var ClosingDebit = 0
        var ClosingCredit = 0

        fetch(url + "inventry/reporting/avGbyInvoice", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((avGbyInvoice) => {
            this.setState({ avGbyInvoice: avGbyInvoice })

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((transactions) => {
                //  console.log(transactions)
                var Opening1 = transactions.Opening
                var During1 = transactions.During

                //////////////////// Sorting Opening Data
                var key = Object.keys(Opening1)
                    .sort(function order(key1, key2) {

                        if (new Date(Opening1[key1].date) < new Date(Opening1[key2].date)) return -1;
                        else if (new Date(Opening1[key1].date) > new Date(Opening1[key2].date)) return +1;
                        else return 0;
                    });

                var Opening = {};

                for (var i = 0; i < key.length; i++) {
                    Opening[key[i]] = Opening1[key[i]];
                    delete Opening1[key[i]];
                }

                ///////////////////

                //////////////////// Sorting During Data
                var key = Object.keys(During1)
                    .sort(function order(key1, key2) {

                        if (new Date(During1[key1].date) < new Date(During1[key2].date)) return -1;
                        else if (new Date(During1[key1].date) > new Date(During1[key2].date)) return +1;
                        else return 0;
                    });

                var During = {};

                for (var i = 0; i < key.length; i++) {
                    During[key[i]] = During1[key[i]];
                    delete During1[key[i]];
                }

                ///////////////////

                var openingBalance = 0
                var OpeningCredit = 0
                var OpeningDebit = 0
                // Opening

                Object.keys(Opening).map((key) => {
                    if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                        OpeningDebit = OpeningDebit + this.state.avGbyInvoice[key]
                    }
                })


                var openDiff = OpeningDebit
                // SetUp the Opening of Cost of Sales
                if (openDiff > 0) {
                    OpeningDebit_COS = openDiff

                } else {
                    OpeningCredit_COS = Math.abs(openDiff)
                }
                this.setState({ opening_flag: "Dr" })

                this.setState({ opening_balance: Math.abs(openDiff) })

                var OpeningBalanceValue = openDiff
                var DuringCreditBalance = 0
                var DuringDebitBalance = 0
                var the_rows = []

                var totalDebit = 0
                var totalCredit = 0
                var totalBalance = 0
                var openDiff = 0
                var flag = ""
                var counter = 0
                // During

                Object.keys(During).map((key) => {
                    var debit = 0
                    var credit = 0
                    var type = ""

                    if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                        if (key.includes('AIO-S-')) {
                            type = "Sale"
                        }
                        if (key.includes('AIO-PR-')) {
                            type = "Purchase Return"
                        }
                        totalDebit = totalDebit + this.state.avGbyInvoice[key]
                        if (counter == 0) {
                            DuringDebitBalance = DuringDebitBalance + this.state.avGbyInvoice[key] + OpeningBalanceValue
                        } else {
                            DuringDebitBalance = DuringDebitBalance + this.state.avGbyInvoice[key]
                        }
                        counter++
                        debit = this.state.avGbyInvoice[key]
                    }


                    var date = new Date(During[key].date)
                    var d = date.getDate()
                    var m = date.getMonth() + 1
                    var y = date.getFullYear()

                    openDiff = DuringDebitBalance


                    flag = "Dr"



                    //  the_rows.push(put_data)
                })
                if (flag == "Dr") {
                    ProcessCredit = totalCredit
                    ProcessDebit = totalDebit
                    ClosingDebit = Math.abs(openDiff)
                } else {
                    ProcessCredit = totalCredit
                    ProcessDebit = totalDebit
                    ClosingCredit = Math.abs(openDiff)
                }

                //////////////////////////////////////////////// Calling Up the Trail Balance
                var revenue = []
                var expense = []
                var owner_equity = []
                var analytics = []

                // 16 Apr 2022
                // The Previous Calculation of Profit & Loss will give us the gross profit.
                var asset = []
                var libilities = []

                fetch(url + 'inventry/reporting/trial', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((transactions) => {
                    var the_rows = []
                    var total_opening_credit = 0
                    var total_opening_debit = 0
                    var total_process_debit = 0
                    var total_process_credit = 0
                    var total_closing_debit = 0
                    var total_closing_credit = 0
                    transactions.push({
                        detail: { id: "C-1", name: "Cost of Sales", type: "COS" },
                        opening: { credit: OpeningCredit_COS, debit: OpeningDebit_COS },
                        process: { credit: ProcessCredit, debit: ProcessDebit },
                    })

                    var total_revenue = 0
                    var total_expense = 0
                    var total_owner_equity = 0

                    var total_assets = 0
                    var total_libilities = 0

                    var analytics = []

                    analytics.push({
                        title: "Cost of Sales",
                        value: this.numberWithCommas((ProcessDebit == "0" ? ProcessCredit : ProcessDebit).toFixed(2))
                    })


                    transactions.map((data) => {
                        if (data.detail.name == "Credit Sales" || data.detail.name == "Cash Sales") {
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: this.numberWithCommas(data.process.credit.toFixed(2))
                            }
                            total_revenue = total_revenue + data.process.credit
                            revenue.push(put_data)
                        }

                        // Enable the oTHER iNCOME
                        if (data.detail.name == "Discount" || data.detail.name == "Miscellaneous Income") {
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: this.numberWithCommas(data.process.credit.toFixed(2))
                            }
                            total_revenue = total_revenue + data.process.credit
                            revenue.push(put_data)
                        }
                        if ((data.detail.id + "").includes("E-") && (data.detail.id + "").split("-").length == 4) {
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: this.numberWithCommas(Math.abs(data.process.debit - data.process.credit).toFixed(2))
                            }
                            total_expense = total_expense + parseFloat(data.process.debit)
                            expense.push(put_data)

                        }
                        // Owner Equity
                        if ((data.detail.id + "").includes("O-1") && (data.detail.id + "").split("-").length == 4) {
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: this.numberWithCommas(Math.abs(data.process.debit - data.process.credit).toFixed(2))
                            }
                            total_owner_equity = total_owner_equity + parseFloat(Math.abs(data.process.debit - data.process.credit))
                            owner_equity.push(put_data)

                        }
                        // Asset Component 
                        if (data.detail.type.includes("CASH") || data.detail.type.includes("BANK") || data.detail.type.includes("Non-Current Assets") || data.detail.type.includes("customer")) {
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: this.numberWithCommas(Math.abs(data.process.debit - data.process.credit).toFixed(2))
                            }
                            total_assets = total_assets + parseFloat((data.process.debit - data.process.credit))
                            asset.push(put_data)
                        }
                        // Inventory - Reverse Transaction - Cost of Sales
                        if (data.detail.type.includes("Inventory")) {
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: this.numberWithCommas(Math.abs(data.process.debit - parseFloat(ProcessDebit)).toFixed(2))
                            }
                            total_assets = total_assets + parseFloat((data.process.debit - parseFloat(ProcessDebit)))
                            asset.push(put_data)
                        }


                        // Liabilities
                        if (data.detail.type.includes("vendor") || data.detail.type.includes("Short Term Loan") || ((data.detail.id + "").includes("L-2") && (data.detail.id + "").split("-").length == 4)) {
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: this.numberWithCommas(Math.abs(data.process.debit - data.process.credit).toFixed(2))
                            }
                            total_libilities = total_libilities + parseFloat(Math.abs(data.process.debit - data.process.credit))
                            libilities.push(put_data)
                        }

                    })
                    var gross_profit = total_revenue - (ProcessDebit == "0" ? ProcessCredit : ProcessDebit)
                    analytics.push({
                        title: "Gross Profit",
                        value: this.numberWithCommas(gross_profit.toFixed(2))
                    })
                    analytics.push({
                        title: "Total Expense",
                        value: this.numberWithCommas((total_expense).toFixed(2))
                    })
                    var net_profit_before_depreciation = gross_profit - total_expense
                    analytics.push({
                        title: "Net Profit Before Depreciation",
                        value: this.numberWithCommas((net_profit_before_depreciation).toFixed(2))
                    })
                    analytics.push({
                        title: "Depreciation",
                        value: 0
                    })
                    analytics.push({
                        title: "Net Profit After Depreciation",
                        value: this.numberWithCommas((net_profit_before_depreciation).toFixed(2))
                    })
                    analytics.push({
                        title: "Net Profit Before Tax",
                        value: this.numberWithCommas((net_profit_before_depreciation).toFixed(2))
                    })
                    analytics.push({
                        title: "Income Tax",
                        value: 0
                    })
                    analytics.push({
                        title: "Net Profit",
                        value: this.numberWithCommas((net_profit_before_depreciation).toFixed(2))
                    })

                    // Adding Net Profit to Owner's Equity
                    var put_data = {
                        id: "P-1",
                        title: "Profit & Loss",
                        type: "Profit & Loss",
                        value: this.numberWithCommas(Math.abs(net_profit_before_depreciation).toFixed(2))
                    }
                    total_owner_equity = total_owner_equity + parseFloat(Math.abs(net_profit_before_depreciation))
                    owner_equity.push(put_data)






                    this.setState({ revenue: revenue })
                    this.setState({ expense: expense })
                    this.setState({ analytics: analytics })
                    this.setState({ libilities: libilities })
                    this.setState({ asset: asset })

                    this.setState({ owner_equity: owner_equity })
                    this.setState({ total_owner_equity: total_owner_equity })

                    this.setState({ total_assets: total_assets })
                    this.setState({ total_libilities: total_libilities })


                    this.setState({ save: false })

                })




            }).catch(() => {
                this.setState({ save: false })
            })

        })
    }
    Clear_Text = () => {
        try {
            const instance = this._typeahead_1.getInstance();
            instance.clear();
        } catch (e) {

            console.log(e)

        }
    }

    getData() {
        var columns = this.state.columns;
        var rows = this.state.rows;
        var filter = this.original;
        return { columns, rows };

    }
    handleChangeFrom = date => {
        this.setState({
            date_from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            date_to: date
        });
    };
    render() {
        return (
            <Grid>

                <Grid md={12}>
                    <Card>
                        <CardHeader>
                        </CardHeader>
                        <CardBody className="all-icons">
                            <form onSubmit={this.onSubmit} className="Notifications">





                                <Grid container controlId="exampleForm.ControlSelect2">
                                    <Grid sm={2}>
                                        <h2><strong>Balance Sheet</strong></h2>
                                    </Grid>
                                    <Grid sm={2}></Grid>
                                    <Grid sm={2}>
                                        <b>UpTo :</b>
                                        <br />
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker inputFormat="dd/MM/yyyy"

                                                value={this.state.date_to}
                                                onChange={(date_to) => {
                                                    this.setState({ date_to: date_to })
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid sm={1}></Grid>
                                    <Grid sm={2}>
                                        <br />
                                        <Button variant="contained" size="md" block type="submit" disabled={this.state.save}>Search</Button>
                                    </Grid>
                                    <Grid sm={3}></Grid>
                                </Grid>

                            </form>
                            {
                                this.state.save
                                    ?
                                    <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                                    :
                                    <Grid container >
                                        <Grid md={6}>
                                            <MaterialTable
                                                title={<b>{"ASSET"}</b>}
                                                icons={tableIcons}
                                                columns={[
                                                    { title: 'ID', field: 'id' },
                                                    {
                                                        title: 'Type', field: 'type', defaultSort: 'asc',
                                                        cellStyle: {
                                                            color: 'blue',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'blue',
                                                            color: "white"
                                                        }
                                                    },
                                                    {
                                                        title: 'Account Title', field: 'title',
                                                        cellStyle: {
                                                            color: 'red',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'red',
                                                            color: "white"
                                                        }
                                                    },
                                                    {
                                                        title: 'PKR', field: 'value',
                                                        cellStyle: {
                                                            color: 'black',
                                                            fontWeight: "bold"
                                                        }
                                                    },

                                                ]}
                                                data={this.state.asset}
                                                options={{
                                                    grouping: true
                                                }}
                                            />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <b class="Total Asset">Total Asset</b>
                                                <TextField

                                                    id="filled-basic"
                                                    variant="filled" type="text" value={this.numberWithCommas(this.state.total_assets.toFixed(2))} style={{
                                                        fontWeight: "bold"
                                                    }} required placeholder="Asset..." />
                                            </div>

                                        </Grid>
                                        <Grid md={6} >
                                            <MaterialTable
                                                title={<b>{"LIABILITIES"}</b>}
                                                icons={tableIcons}
                                                columns={[
                                                    { title: 'ID', field: 'id' },
                                                    {
                                                        title: 'Type', field: 'type', defaultGroupSort: 'desc',
                                                        cellStyle: {
                                                            color: 'blue',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'blue',
                                                            color: "white"
                                                        }
                                                    },
                                                    {
                                                        title: 'Account Title', field: 'title',
                                                        cellStyle: {
                                                            color: 'red',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'red',
                                                            color: "white"
                                                        }
                                                    },
                                                    {
                                                        title: 'PKR', field: 'value',
                                                        cellStyle: {
                                                            color: 'black',
                                                            fontWeight: "bold"
                                                        }
                                                    },

                                                ]}
                                                data={this.state.libilities}
                                                options={{
                                                    grouping: true,
                                                    exportButton: true
                                                }}
                                            />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <b class="Total Liabilities">Total Liabilities</b>
                                                <TextField

                                                    id="filled-basic"
                                                    variant="filled" type="text" value={this.numberWithCommas(this.state.total_libilities.toFixed(2))} style={{
                                                        fontWeight: "bold"
                                                    }} required placeholder="Liabilities..." />
                                            </div>
                                        </Grid>
                                        <Grid md={6}></Grid>
                                        <Grid md={6}>
                                            <MaterialTable
                                                title={<b>{"Owner's Equity"}</b>}
                                                icons={tableIcons}
                                                columns={[
                                                    { title: 'ID', field: 'id' },
                                                    {
                                                        title: 'Type', field: 'type', defaultSort: 'asc',
                                                        cellStyle: {
                                                            color: 'blue',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'blue',
                                                            color: "white"
                                                        }
                                                    },
                                                    {
                                                        title: 'Account Title', field: 'title',
                                                        cellStyle: {
                                                            color: 'red',
                                                            fontWeight: "bold"
                                                        },
                                                        headerStyle: {
                                                            backgroundColor: 'red',
                                                            color: "white"
                                                        }
                                                    },
                                                    {
                                                        title: 'PKR', field: 'value',
                                                        cellStyle: {
                                                            color: 'black',
                                                            fontWeight: "bold"
                                                        }
                                                    },

                                                ]}
                                                data={this.state.owner_equity}
                                                options={{
                                                    grouping: true
                                                }}
                                            />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <b class="Total Asset">Total SHAREHOLDER CAPITAL</b>
                                                <TextField

                                                    id="filled-basic"
                                                    variant="filled" type="text" value={this.numberWithCommas(this.state.total_owner_equity.toFixed(2))} style={{
                                                        fontWeight: "bold"
                                                    }} required placeholder="Asset..." />
                                            </div>


                                        </Grid>
                                    </Grid>


                            }


                            <Modal
                                open={this.state.alert}
                                onClose={() => this.setState({ alert: false })}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        {this.state.title}
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        {this.state.body}
                                    </Typography>
                                </Box>
                            </Modal>
                        </CardBody>
                    </Card>
                </Grid>

            </Grid>
        );
    };
}
export default BalanceSheet;
import React, { Component } from "react";
import {
    Form, Col,
    Row
} from 'react-bootstrap';
import 'whatwg-fetch';

import { Card, CardHeader, CardBody, Input } from "reactstrap";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';

import Autocomplete from '@mui/material/Autocomplete';

import ReactLoading from "react-loading";
import { forwardRef } from 'react';
// ServerURL
import url from '../../serverURL.js';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';


import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import TextField from '@mui/material/TextField';

import MaterialTable from 'material-table'

import { Grid, Container, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


class PurchaseReport extends Component {
    constructor() {
        super();
        this.state = {
            rows: [
            ],
            call: () => {
                var columns = this.state.columns;
                var rows = this.state.rows;
                return { columns, rows };
            },
            id: 0,
            cash_accounts: [],
            bank_accounts: [],
            date_from: new Date().setMonth(new Date().getMonth() - 1),
            date_to: new Date(),
            final_flag: '',
            total_balance: 0,
            cash: true,
            bank: false,
            load: false,
            type: 'customer',
            ac_title_data: null,
            all_accounts: {},
            original_record: [],
            body: '',
            title: '',
            opening_balance: 0,
            opening_flag: '',
            add: false,
            date: new Date(),
            amount:0,
            discount:0,
            net_cost:0,
            submit:false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }
   componentWillMount(){
  
      fetch(url + 'inventry/jv/accounts', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if(res.status== 400){
          alert('No Category Found');
        }else{
          return res.json()
        }
      }).then((client)=>{
        var cli=[]
        var accounts={}
        client.map((acc)=>{
          if(acc.type=='customer' || acc.type=='vendor'){
            cli.push(acc.name+"-"+acc.type)
            var obj={
              title:acc.name,
              type:acc.type,
              id:acc.id
            }
            accounts[acc.name]=obj
          }else{
            cli.push(acc.title+"-"+acc.type)
            var obj={
              title:acc.title,
              type:acc.type,
              id:acc.id
            }
            accounts[acc.title]=obj      
          }
          
        })
        console.log(accounts)
        this.setState({all_accounts:accounts})
        this.setState({load:true})
        this.setState({options:cli})
      })
        
   }
   numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
  }
    onSubmit(e) {
        e.preventDefault();
        var data = {
            from: this.state.date_from,
            to: this.state.date_to,
            ac_id:this.state.ac_title_data
        }
        fetch(url + 'inventry/purchase/report', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((transactions) => {
          this.setState({rows:transactions})
          var amount=0
          var discount=0
          var net_cost=0

          transactions.map((trans)=>{
           amount=amount+ ((trans.rate)*(trans.quantity))
           discount=discount+ trans.discount
           net_cost=net_cost+ (((trans.rate)*(trans.quantity)) -  trans.discount)
          })
          this.setState({amount:amount})

          this.setState({discount:discount})

          this.setState({net_cost:net_cost})
        })

    }
    Clear_Text = () => {
        try {
            const instance = this._typeahead_1.getInstance();
            instance.clear();
        } catch (e) {

            console.log(e)

        }
    }
    Accounts = () => {

        return (
            <div>
                <Autocomplete
                    caseSensitive={false}
                    id="filtering-example"
                    ignoreDiacritics={true}
                    ref={(ref) => this._typeahead_1 = ref}
                    onChange={(event,text) => {
                        var text = text + ""
                        var name = text.split('-')
                        console.log(name)
                        var id = this.state.all_accounts[name[0]]
                        console.log(id)
                        this.setState({ ac_title_data: id })
                    }}
                    renderInput={(params) => <TextField {...params} label="AC Title.." />}
                   
                    clearButton={true}
                    options={this.state.options}
                    placeholder="A/C Title..."
                />
            </div>
        );
    };
    getData() {
        var columns = this.state.columns;
        var rows = this.state.rows;
        var filter = this.original;
        return { columns, rows };

    }
    handleChangeFrom = date => {
        this.setState({
            date_from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            date_to: date
        });
    };
    render() {
        return (
            <Grid>

                {
                    this.state.load ?
                        <Grid md={12}>
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody className="all-icons">
                                    <form onSubmit={this.onSubmit} className="Notifications">
                                        <h2><strong>Purchase Report</strong></h2>




                                        <Grid container controlId="exampleForm.ControlSelect2">
                                           
                                            <Grid sm={2}>
                                                <b>From :</b>
                                                <br />
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker inputFormat="dd/MM/yyyy"

                                                        value={this.state.date_from}
                                                        onChange={(date_from) => {
                                                            this.setState({ date_from: date_from })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />

                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid sm={2}>
                                                <b>To :</b>
                                                <br />
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker inputFormat="dd/MM/yyyy"

                                                        value={this.state.date_to}
                                                        onChange={(date_to) => {
                                                            this.setState({ date_to: date_to })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />

                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid sm={1}></Grid>
                                            <Grid sm={4}>
                                            <b>A/C Title:</b>
                                            {this.Accounts()}
                                            </Grid>
                                            <Grid sm={1}></Grid>
                                            <Grid sm={2}>
                                                <br />
                                                <Button variant="contained" size="md" block type="submit">Search</Button>
                                            </Grid>
                                        </Grid>
                                       
                                    </form>
                                    <Grid controlId="formHorizontalEmail">
                                        <Grid md={12}>
                                           

                                            {
                                                this.state.submit
                                                ?
                                                <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                                                :
                                               
                                                <MaterialTable
                                                    title=""
                                                    icons={tableIcons}
                                                    columns={[
                                                        { title: 'Date', field: 'date' , type:"date"},
                                                        { title: 'Client Title', field: 'client' },
                                                        { title: 'Invoice No.', field: 'invoiceno' },
                                                        { title: 'Code', field: 'code' },
                                                        { title: 'Name', field: 'name' },
                                                        { title: 'Rate', field: 'rate' },
                                                        { title: 'Quantity', field: 'quantity' },
                                                        { title: 'Discount', field: 'discount' },
                                                        { title: 'Amount', field: 'amount' }
                                                    ]}
                                                    data={this.state.rows}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#01579b',
                                                            color: '#FFF'
                                                        },
                                                        search: true,
                                                        grouping: true,
                                                        exportButton:true,
                                                        selection: true,
                                                        showTitle: true,
                                                        paging: false,
                                                        maxBodyHeight:"500px"
        
                                                    }}
                                                    actions={[
                                                        {
                                                          tooltip: 'Calculate',
                                                          icon: ()=><FilterList />,
                                                          onClick: (event, rowData) => {
                                                            var amount=0
                                                            var discount=0
                                                            var net_cost=0
                                                  
                                                            rowData.map((row)=>{
                                                             amount=amount+ ((row.rate)*(row.quantity))
                                                             discount=discount+ row.discount
                                                             net_cost=net_cost+ (((row.rate)*(row.quantity)) -  row.discount)
                                                            })
                                                            this.setState({amount:amount})
                                                  
                                                            this.setState({discount:discount})
                                                  
                                                            this.setState({net_cost:net_cost})
                                                  
                                                            // Do save operation
                                                          }
                                                        }
                                                      ]}
                                                />

                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container controlId="exampleForm.ControlSelect2">
                                    <Grid sm={4}>
                                    </Grid>
                                    <Grid sm={2} style={{
                                        display:'flex',
                                        flexDirection:'column'
                                    }}>
                                    <b class="Total Net Amount">Total Amount</b>    
                                   <TextField
                                                    fullWidth
                                                    id="filled-basic"
                                                    variant="filled"  type="text" value={this.numberWithCommas(this.state.amount.toFixed(2))}  style={{
                                        fontWeight:"bold"
                                    }}  required placeholder="Debit..." />
                                    </Grid>
                                    <Grid sm={1}>
                                    </Grid>
                                    <Grid sm={2} style={{
                                          display:'flex',
                                        flexDirection:'column'
                                    }}>
                                    <b class="Total Net Cost" >Total Discount</b>    
                                   <TextField
                                                    fullWidth
                                                    id="filled-basic"
                                                    variant="filled" type="text" value={this.numberWithCommas(this.state.discount.toFixed(2))}  style={{
                                        fontWeight:"bold"
                                    }}   required placeholder="Credit..." />
                                    </Grid>
                                    <Grid sm={1}>
                                    </Grid>
                                    <Grid sm={2} style={{
                                          display:'flex',
                                        flexDirection:'column'
                                    }}>
                                    <b class="Total Profit">Total Net Amount</b>    
                                   <TextField
                                                    fullWidth
                                                    id="filled-basic"
                                                    variant="filled"  type="text" value={this.numberWithCommas(this.state.net_cost.toFixed(2))} style={{
                                        fontWeight:"bold"
                                    }} required placeholder="Total Balance..." />
                                    </Grid>
   
                                    </Grid>
                                
                                    <Modal
                                        open={this.state.add}
                                        onClose={() => this.setState({ add: false })}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                {this.state.title}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                {this.state.body}
                                            </Typography>
                                        </Box>
                                    </Modal>

                                </CardBody>
                            </Card>
                        </Grid>
                        :
                        <div style={{
                            margin: "auto",
                            marginTop: "10em",
                            height: "100em"
                        }}>
                            <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                        </div>
                }


            </Grid>
        );
    };
}
export default PurchaseReport;
import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import PreviewIcon from '@mui/icons-material/Preview';

// Alert Thing
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
// ServerURL
import url from 'src/serverURL.js';


import { Grid, Container, Typography } from '@mui/material';

import MaterialTable from 'material-table'

import EditItem from './edit'



const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    PreviewIcon: forwardRef((props, ref) => <PreviewIcon {...props} ref={ref} />),
};

export default function AddItem() {

    const [rows, setRows] = useState([])
    const [form, setForm] = useState({})
    const [category, setCategories] = useState([])

    const [loading,setLoading]=React.useState(true)

    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);

    //Edit Hooks
    const [editFlag,setEditFlag]=React.useState(false)
    const [editData,setEditData]=React.useState(false)
    //
    const [alertText,setAlertText]=React.useState("Item Added Successfully")

    

    const handleClick = (data) => {
        if(data!=undefined && data!=null){
            setAlertText(data)
        }
        setOpen(true);
          
        Reload();
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };
    const handleClickError = () => {
        setError(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setError(false);
    };
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });


    const getDate = () => {
        return `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
    }
    const submit = () => {
        
        /* eslint-disable */
        if (form.name === undefined || form.category === undefined) {
            handleClickError()
           return
        }
        var data = {
            name: form.name,
            category: form.category,
            stock: form.stock === undefined ? "" : form.stock,
            barcode: form.stock === undefined ? "" : form.barcode,
            cost: form.cost === undefined ? "" : form.cost,
            description: form.description === undefined ? "" : form.description,
            min_stock: form.min_stock === undefined ? "" : form.min_stock,
            rate: form.rate === undefined ? "" : form.rate,
            userId: localStorage.getItem('id')
        }
        fetch(url + 'inventry/item', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status == 400) {
                handleClickError()
             
                return
            }
            setForm({})
            handleClick()

        })
    }
    useEffect(() => {

        fetch(url + 'inventry/category', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((categories) => {
            const box = categories.filter((data) => (
                {
                    name: data.name,
                    code: data.code,
                    category: data.category,
                    
                })
            )
            setCategories(box)

        })

        fetch(url + 'inventry/item', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((categories) => {
            // const box = categories.filter((data) => (
            //     {
            //         name: data.name,
            //         code: data.code,
            //         category: data.category,

            //         actions:<div>
            //         <Button variant="contained" color="secondary">
            //             View
            //         </Button>
            //         <Button variant="outlined" color="success">
            //             Edit
            //         </Button>
                    
            // </div>
            //     })
            // )
            setRows(categories)
            setLoading(false)
        })

    }, [])

    const Reload = () => {
        setLoading(true)
        fetch(url + 'inventry/category', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((categories) => {
            const box = categories.filter((data) => (
                {
                    name: data.name,
                    code: data.code,
                    category: data.category
                })
            )
            setCategories(box)

        })

        fetch(url + 'inventry/item', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((categories) => {
            // const box = categories.filter((data) => (
            //     {
            //         name: data.name,
            //         code: data.code,
            //         category: data.category
            //     })
            // )
            setRows(categories)
            setLoading(false)
        })

    }
    const editClose=()=>{
        setEditFlag(false)
    }

    return (
        <>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                {
                    editFlag ?
                    <EditItem editData={editData} editClose={editClose} handleClickError={handleClickError} handleClick={handleClick}/>
                    :
                    null
                }
              
                <div>
                {
                    !loading ?

                    <>
                    <TextField
                      
                        label="Date"
                        variant="filled"
                        disabled
                        value={getDate()}
                    />
                    <TextField
                      
                        id="filled-required"
                        label="Name"
                        variant="filled"
                        value={form.name === undefined ? "" : form.name}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                name: e.target.value
                            })

                        }}
                    />
                    <TextField
                      
                        id="filled-required"
                        label="Description"
                        variant="filled"
                        value={form.description}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                description: e.target.value
                            })

                        }}
                    />
                    <TextField
                      
                        id="filled-required"
                        label="Barcode"
                        type='text'
                        variant="filled"
                        value={form.barcode}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                barcode: e.target.value
                            })

                        }}
                    />

                    <FormControl sx={{ m: 1, minWidth: 245 }}>
                        <InputLabel id="demo-simple-select-filled-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={form.category}
                            onChange={(e) => {
                                setForm({
                                    ...form,
                                    category: e.target.value
                                })

                            }}

                        >
                            <MenuItem value={undefined}>Select</MenuItem>
                            {
                                category.map((row) => {
                                    return <MenuItem value={row.name}>{row.name}</MenuItem>
                                })
                            }

                        </Select>
                    </FormControl>
                    <TextField
                      
                        id="filled-required"
                        label="Sale Price"
                        variant="filled"
                        type='number'
                        value={form.rate}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                rate: e.target.value
                            })

                        }}
                    />
                    <TextField
                      
                        id="filled-required"
                        label="Minimum Stock Quantity"
                        variant="filled"
                        value={form.min_stock}
                        type='number'
                        onChange={(e) => {
                            setForm({
                                ...form,
                                min_stock: e.target.value
                            })

                        }}
                    />
                    <TextField
                      
                        id="filled-required"
                        label="Purchase Price"
                        variant="filled"
                        type='number'
                        value={form.cost}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                cost: e.target.value
                            })

                        }}
                    />
</>
:
null
}
                    <br />
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                    }}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {alertText}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                    }}>
                        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                       Invalid Details
                        </Alert>
                    </Snackbar>
                    <div style={{ padding: 10 }}>
                        <Button variant="contained" to="#" onClick={submit} >
                            Add Item
                        </Button>
                    </div>
                
                    
                </div>
                {
                    loading ?
                    <CircularProgress />
                    :
                    <MaterialTable
                    icons={tableIcons}
                    title="Items"
                    columns={[
                        { title: 'Code', field: 'code' },
                        { title: 'Name', field: 'name' },
                        { title: 'Category', field: 'category' }

                    ]}
                    data={rows}
                    options={{
                        pageSizeOptions:[20,40,100],
                        pageSize:20
                    }
                    }
                    actions={[
                  
                        {
                            icon: ()=><Edit />,
                            tooltip: 'Edit',
                            onClick: (event, rowData) =>{
                                setEditData(rowData)
                                setEditFlag(true)
                            }
                          },
                          {
                            icon: ()=><DeleteOutline />,
                            tooltip: 'Delete',
                            onClick: (event, rowData) =>{


                                let text = "Are you Sure to Delete? ";
                                if (confirm(text) == true) {
                                 
                                var data={
                                    code:rowData.code
                                }
                                fetch(url + 'inventry/item', {
                                    method: 'DELETE',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(data)
                                }).then(res => {
                                    if (res.status == 400) {
                                        handleClickError()
                                     
                                        return
                                    }
                                    handleClick("Deleted Successfully!")
                            
                                    handleClose()
                                })
                                } 


                            }
                          }
                      ]}
                    />
                }
             
            </Box>

        </>

    );
}

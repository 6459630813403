import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import aiodock from "../hitech.png";

// Create styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 5,
  },
  logo: {
    width: 180,
    height: 80,
  },
  section: {
    // margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  tableHeader: {
    fontSize: 5,
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    padding: 5,
    backgroundColor: "#EEEEEE",
    marginTop: 10,
    fontFamily: "Helvetica-Bold",
  },
  tableRow: {
    fontSize: 5,
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  tableRowOdd: {
    backgroundColor: "#DDDDDD",
  },
  tableTotal: {
    fontSize: 12,
    fontWeight: "bold",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EEEEEE",
    marginTop: 10,
  },
});

const separator = (numb) => {
  var str = (numb + "").split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return str.join(".");
};

const InvoicePdf = ({
  clientName,
  invoiceNo,
  products,
  client,
  discountTotal,
  date,
  gstTotal,
  netBillTotal,
  payable,
}) => {
  return (
    <Document>
      <Page size={{ width: "415px" }} style={styles.page}>
        <View style={styles.section}>
          <View style={styles.logo}>
            <Image
              style={{ fontSize: 12, textAlign: "center", width: "100%" }}
              src={aiodock}
            />
          </View>

          {/* ****************************************************************************************** */}
          {/* Date & Invoice Number */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",

              paddingLeft: 3,
              paddingRight: 3,
            }}
            fixed
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                alignItems: "left",
              }}
            >
              <Text
                style={{
                  marginTop: 1,
                  fontSize: 6,
                }}
              >
                Invoice No.:
              </Text>
              <Text
                style={{
                  marginTop: 1,
                  fontSize: 6,
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {invoiceNo}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                width: "50%",
                alignItems: "right",
              }}
            >
              <Text
                style={{
                  marginTop: 1,
                  fontSize: 6,
                }}
              >
                {new Date(date).toDateString()}
              </Text>
            </View>
          </View>

          {/* ******************************************************************************* */}
          {/* Client Name */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "left",
              }}
            >
              <Text
                style={{
                  marginTop: 1,
                  fontSize: 6,
                }}
              >
                Billed To:
              </Text>
              <Text
                style={{
                  marginTop: 1,
                  fontSize: 6,
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {client.length != 0 ? client[0].name : ""}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          {/* Client Address */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 10,
            }}
          >
            <View
              style={{
                width: "100%",
                alignItems: "left",
              }}
            >
              <Text
                style={{
                  fontSize: 6,
                }}
              >
                Address: {client.length != 0 ? client[0].address : ""}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}

          {/* Information Table */}
          <View style={styles.tableHeader}>
            <Text style={{ width: '8%' }}>Sr.</Text>
            <Text style={{ width: "20%" }}>Name</Text>
            <Text style={{ width: "17%" }}>Price</Text>
            <Text style={{ width: "8%" }}>Qty</Text>
            <Text style={{ width: "15%" }}>GST</Text>
            <Text style={{ width: "12%" }}>Disc</Text>
            <Text style={{ width: "20%" }}>Amount</Text>
          </View>

          {products.map((product, index) => (
            <View
              style={[styles.tableRow]}
              key={index}
            >
              <Text style={{ width: '5%' }}>{index + 1}</Text>
              <Text style={{ width: "23%", fontSize: 6 }}>{product.name}</Text>
              <Text style={{ width: "17%", fontSize: 6 }}>
                {separator(parseInt(product.rate))}
              </Text>
              <Text style={{ width: "8%", fontSize: 6, textAlign: "center" }}>
                {product.quantity}
              </Text>
              <Text style={{ width: "15%", fontSize: 6 }}>
                {separator(parseInt(((product.rate*product.quantity)/100)*product.gst))}
              </Text>
              <Text style={{ width: "12%", fontSize: 6 }}>
                {separator(product.discount)}
              </Text>
              <Text style={{ width: "20%", fontSize: 6 }}>
                {separator(parseInt(product.amount))}
              </Text>
            </View>
          ))}

          {/* ******************************************************************************* */}

          {/* Net Amount Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  borderColor: "black",
                  borderWidth: 0.5,
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Total Amount"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  borderColor: "black",
                  borderWidth: 0.25,
                  // fontFamily: "Helvetica-Bold",
                  backgroundColor: "#eeeeee",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                { separator(parseInt(netBillTotal)) + "/-"}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          {/* Net GST Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 2,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  borderColor: "black",
                  borderWidth: 0.5,
                  color: "black",
                  fontSize: 5,
                  // paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Total GST"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  borderColor: "black",
                  borderWidth: 0.25,
                  // fontFamily: "Helvetica-Bold",
                  backgroundColor: "#eeeeee",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                { separator(parseInt(gstTotal)) + "/-"}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          {/* Net Discount Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 2,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  borderColor: "black",
                  borderWidth: 0.5,
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Total Discount"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  borderColor: "black",
                  borderWidth: 0.25,
                  // fontFamily: "Helvetica-Bold",
                  backgroundColor: "#eeeeee",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                { separator(parseInt(discountTotal)) + "/-"}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          {/* Net Payable Section */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 2,
              marginBottom: 30,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            ></View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
              }}
            >
              <Text
                style={{
                  width: "50%",
                  borderColor: "black",
                  borderWidth: 0.5,
                  color: "black",
                  fontSize: 5,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                {"Total Payable"}
              </Text>
              <Text
                style={{
                  width: "50%",
                  borderColor: "black",
                  borderWidth: 0.25,
                  // fontFamily: "Helvetica-Bold",
                  backgroundColor: "#eeeeee",
                  fontSize: 6,
                  paddingBottom: 7,
                  paddingTop: 3,
                  paddingLeft: 5,
                  textAlign: "right",
                }}
              >
                {" "}
                { separator(parseInt(payable))+ "/-"}
              </Text>
            </View>
          </View>
          {/* ******************************************************************************* */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              bottom: 0,
              position: "absolute",
              paddingLeft: 6,
              paddingRight: 6,
            }}
            fixed
          >
          
            <View
              style={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  // fontFamily: "Helvetica-Bold",
                  textAlign: "left",
                  marginTop: 1,
                  fontSize: 6,
                
                }}
              >
                {
                  "Address: Shop No.3 pak plaza, Liaqat Rd, Naya Mohalla, Rawalpindi"
                }
              </Text>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 6,
                  marginTop: 1,
                 
                }}
              >
                {"Software designed by: aiodock.com"}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;

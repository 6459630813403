import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import aiodock from "../hitech.png";

// Create styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 10,
  },
  logo: {
    width: 180,
    height: 80,
    paddingLeft: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  tableHeader: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    padding: 5,
    backgroundColor: "#EEEEEE",
  },
  tableRow: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  tableRowOdd: {
    backgroundColor: "#DDDDDD",
  },
  tableTotal: {
    fontSize: 12,
    fontWeight: "bold",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EEEEEE",
    marginTop: 10,
  },
});

const separator = (numb) => {
  var str = (numb + "").split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return str.join(".");
};

const InvoicePdf = ({
  clientName,
  invoiceNo,
  products,
  client,
  discountTotal,
  date,
  gstTotal,
  netBillTotal,
  payable,
  remarks
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              top: 0,
              alignItems: "center",
              paddingTop: 1,
              paddingLeft: 10,
            }}
            fixed
          >
            <View style={styles.logo}>
              <Image
                style={{ fontSize: 12, textAlign: "center", width: "100%" }}
                src={aiodock}
              />
            </View>

            <View
              style={{
                textAlign: "left",
                width: "100%",
                marginLeft: 5,
                flex: 1,
                marginTop: 10,
                flexDirection: "row",
                paddingLeft: 30,
                paddingRight: 30,
                lineHeight: "1.5px",
              }}
            >
              <View
                style={{
                  textAlign: "center",
                  width: "50%",
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    marginTop: 1,
                    fontSize: 14,
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {"Sale Invoice"}
                </Text>

                <Text
                  style={{
                    marginTop: 1,
                    fontSize: 10,
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {invoiceNo}
                </Text>
                <Text
                  style={{
                    marginTop: 1,
                    fontSize: 9,
                  }}
                >
                  {"Invoice Date : " + new Date(date).toDateString()}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <View
                  style={{
                    textAlign: "left",
                    borderWidth: 0.5,
                    padding: 5,
                    marginTop: 5,
                  }}
                >
                  <Text
                    style={{
                      marginTop: 1,
                      fontSize: 9,
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {"Billed To"}
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                    }}
                  >
                    {"Client"}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Helvetica-Bold",

                      marginTop: 1,
                      fontSize: 9,
                    }}
                  >
                    {client.length != 0 ? client[0].name : ""}
                  </Text>

                  <Text
                    style={{
                      fontSize: 8,
                      marginTop: 1,
                    }}
                  >
                    {"Address"}
                  </Text>
                  <Text
                    style={{
                      marginTop: 1,
                      fontSize: 8,
                    }}
                  >
                    {client.length != 0 ? client[0].address : ""}
                  </Text>
                </View>

                <View
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Text
                    style={{
                      marginTop: 1,
                      fontSize: 9,
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {"Currency"}
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                    }}
                  >
                    {"PKR"}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.tableHeader}>
            <Text style={{ width: "8%" }}>Sr.No</Text>
            <Text style={{ width: "8%" }}>Code</Text>
            <Text style={{ width: "24%" }}>Name</Text>
            <Text style={{ width: "10%" }}>Price</Text>
            <Text style={{ width: "10%" }}>Quantity</Text>
            <Text style={{ width: "10%" }}>Discount</Text>
            <Text style={{ width: "10%" }}>GST</Text>
            <Text style={{ width: "10%" }}>Amount</Text>
            <Text style={{ width: "10%" }}>Net Amount</Text>
          </View>

          {products.map((product, index) => (
            <View
              style={[styles.tableRow, index % 2 === 1 && styles.tableRowOdd]}
              key={index}
            >
              <Text style={{ width: "8%" }}>{index + 1}</Text>
              <Text style={{ width: "8%" }}>{product.code}</Text>
              <Text style={{ width: "24%" }}>{product.name}</Text>
              <Text style={{ width: "10%" }}>{separator(parseInt(product.rate))}</Text>
              <Text style={{ width: "10%" }}>{product.quantity}</Text>
              <Text style={{ width: "10%" }}>{separator(parseInt(product.discount))}</Text>
              <Text style={{ width: "10%" }}>
                {" "}
                {separator(
                  parseInt(
                    ((product.rate * product.quantity) / 100) * product.gst
                  )
                )}
              </Text>
              <Text style={{ width: "10%" }}>
                {separator(parseInt(product.rate) * product.quantity)}
              </Text>
              <Text style={{ width: "10%" }}>{separator(parseInt(product.amount))}</Text>
            </View>
          ))}
          <></>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View style={{ width: "60%" }}></View>

            <View
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: 10,
                width: "40%",
                marginTop: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 1,
                  fontStyle: "bold",
                }}
                key={"221"}
              >
                <Text
                  style={{
                    width: "40%",
                    textAlign: "left",
                    borderColor: "black",
                    borderWidth: 0.5,
                    height: "100%",
                    color: "black",
                    padding: 5,
                  }}
                >
                  {"Total Amount"}
                </Text>
                <Text
                  style={{
                    width: "60%",
                    height: "100%",
                    textAlign: "right",
                    borderColor: "black",
                    borderWidth: 0.25,
                    fontFamily: "Helvetica-Bold",
                    backgroundColor: "#eeeeee",
                    padding: 5,
                  }}
                >
                  {"Rs " + separator(parseInt(netBillTotal)) + "/-"}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 1,
                  fontStyle: "bold",
                }}
                key={"221"}
              >
                <Text
                  style={{
                    width: "40%",
                    textAlign: "left",
                    borderColor: "black",
                    borderWidth: 0.5,
                    height: "100%",
                    color: "black",
                    padding: 5,
                  }}
                >
                  {"Total GST"}
                </Text>
                <Text
                  style={{
                    width: "60%",
                    height: "100%",
                    textAlign: "right",
                    borderColor: "black",
                    borderWidth: 0.25,
                    fontFamily: "Helvetica-Bold",
                    backgroundColor: "#eeeeee",
                    padding: 5,
                  }}
                >
                  {"Rs " + separator(parseInt(gstTotal)) + "/-"}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",

                  marginTop: 1,
                  fontStyle: "bold",
                }}
                key={"2w1"}
              >
                <Text
                  style={{
                    width: "40%",
                    textAlign: "left",
                    borderColor: "black",
                    borderWidth: 0.5,
                    height: "100%",
                    padding: 5,
                  }}
                >
                  {"Total Discount"}
                </Text>
                <Text
                  style={{
                    width: "60%",
                    height: "100%",
                    textAlign: "right",
                    fontFamily: "Helvetica-Bold",
                    borderColor: "black",
                    borderWidth: 0.25,
                    backgroundColor: "#eeeeee",
                    padding: 5,
                  }}
                >
                  {"Rs " + separator(parseInt(discountTotal)) + "/-"}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 1,
                  fontStyle: "bold",
                }}
                key={"221"}
              >
                <Text
                  style={{
                    width: "40%",
                    textAlign: "left",
                    borderColor: "black",
                    borderWidth: 0.5,
                    height: "100%",
                    padding: 5,
                  }}
                >
                  {"Total Payable"}
                </Text>
                <Text
                  style={{
                    width: "60%",
                    height: "100%",
                    textAlign: "right",
                    borderColor: "black",
                    padding: 5,
                    fontFamily: "Helvetica-Bold",
                    borderWidth: 0.25,
                    backgroundColor: "#eeeeee",
                    paddingTop: 2,
                  }}
                >
                  {"Rs " + separator(parseInt(payable)) + "/-"}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: 10,
              paddingLeft: 30,
              paddingRight: 30,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: "left",
                width: "20%",
                fontSize: 12,
                marginTop: 2,
                color: "#555555",
              }}
            >
              Remarks:{" "}
            </Text>
          </View>
          {remarks!=""
          ?
          <View
          style={{
            textAlign: "left",
            width: "100%",
            marginTop: 10,
            paddingLeft: 40,
            paddingRight: 30,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              textAlign: "left",
              width: "100%",
              borderWidth: 0.5,
              backgroundColor: "#eeeeee",
              padding: 5,
              fontSize: 10,
            }}
          >
            {remarks}
          </Text>
        </View>
        :
        ""

          }
          <View
            fixed
            style={{
              position: "absolute",
              width: "100%",
              bottom: 0,
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  textAlign: "center",
                  marginTop: 1,
                  fontSize: 10,
                }}
              >
                {
                  "Address : E16/5a Rajpoot Street Satellite Town, Rawalpindi"
                }
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 8,
                  marginTop: 1,
                  fontSize: 10,
                }}
              >
                {"Software designed by: https://aiodock.com"}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;

import * as React from 'react';
import { forwardRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import PreviewIcon from '@mui/icons-material/Preview';

// Alert Thing
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import url from '../../serverURL.js';



import { Grid, Container, Typography } from '@mui/material';

import MaterialTable from 'material-table'

import EditItem from './edit'



const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    PreviewIcon: forwardRef((props, ref) => <PreviewIcon {...props} ref={ref} />),
};

export default function AddItem() {

    const [rows, setRows] = useState([])
    const [form, setForm] = useState({})
    const [category, setCategories] = useState([])

    const [loading,setLoading]=React.useState(true)

    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);

    //Edit Hooks
    const [editFlag,setEditFlag]=React.useState(false)
    const [editData,setEditData]=React.useState(false)

    // After Update
    const [editLoading,setEditLoading]=React.useState(false)
    //
    const [alertText,setAlertText]=React.useState("Client Added Successfully")

    const handleClick = (data) => {
        if(data!=undefined && data!=null){
            setAlertText(data)
        }
        setOpen(true);
          
        Reload();
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };
    const handleClickError = () => {
        setError(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setError(false);
    };
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });


    const getDate = () => {
        return `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
    }

    const deleteClient=(id)=>{
        var result = window.confirm("Want to delete?");
        if (result) {
            fetch(url + 'inventry/client', {

                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json'
                },
                body:JSON.stringify({
                    id:id
                })
              }).then(res => {
                    if (res.status == 400) {
                        handleClickError()
                     
                        return
                    }
                    handleClick("Sucessfully Deleted")
        
            })  
        }
         
    }

    const submit = () => {
      

        /* eslint-disable */
        if (form.name === undefined || form.type === undefined ||  form.type === "select" || form.name === "" || form.name.trim() == "") {
            handleClickError()
           return
        }
        setEditLoading(true)
        var data = {
            name:form.name==undefined ? "" :form.name,
            mobile:form.mobile==undefined ? "" : form.mobile,
            type:form.type==undefined ? "" :form.type,
            email:form.email==undefined ? "" :form.email,
            ntn:form.ntn==undefined ? "" : form.ntn,
            stn:form.stn==undefined ? "" : form.stn,
            address:form.address==undefined ? "" : form.address ,
            debit:form.debit==undefined ? "" : form.debit,
            credit:form.credit==undefined ? "" :form.credit,
            userId: localStorage.getItem('id')
        }
        fetch(url + 'inventry/client', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify(data)
      }).then(res => {
            if (res.status == 400) {
                setEditLoading(false)
                handleClickError()
             
                return
            }
            setEditLoading(false)
            

            setForm({})
            handleClick()

        })
    }

    useEffect(() => {

       

        fetch(url + 'inventry/client', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((clients) => {
            const box = clients.filter((data) => (
                {
                    name:data.name,
                    id:data.id,
                    type:data.type
                 
                })
            )
            setRows(box)
            setLoading(false)
        })

    }, [])

    

    const Reload = () => {
        setLoading(true)
       

        fetch(url + 'inventry/client', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 400) {
                alert('No Category Found');
            } else {
                return res.json()
            }
        }).then((clients) => {
            const box = clients.filter((data) => (
                {
                    name:data.name,
                    id:data.id,
                    type:data.type
                 
                })
            )
            setRows(box)
            setLoading(false)
        })

       

    }
    const editClose=()=>{
        setEditFlag(false)
    }

    return (
        <>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                {
                    editFlag ?
                    <EditItem editData={editData} editClose={editClose} handleClickError={handleClickError} handleClick={handleClick}/>
                    :
                    null
                }
                {

!editLoading
?
<div>

<TextField
    
    label="Date"
    variant="filled"
    disabled
    value={getDate()}
/>
<TextField
    
    id="filled-"
    label="Name"
    variant="filled"
    value={form.name === undefined ? "" : form.name}
    required
    onChange={(e) => {
        setForm({
            ...form,
            name: e.target.value
        })

    }}
/>
 <FormControl sx={{ m: 1, minWidth: 245 }}>
    <InputLabel id="demo-simple-select-filled-label">Type</InputLabel>
    <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        required
        value={form.type}
        onChange={(e) => {
            setForm({
                ...form,
                type: e.target.value
            })

        }}

    >
        <MenuItem value={"select"}>Select</MenuItem>
        <MenuItem value={"vendor"}>Vendor</MenuItem>
        <MenuItem value={"customer"}>Customer</MenuItem>
        

    </Select>
</FormControl>
<TextField
    
    id="filled-basic"
    label="Email"
    variant="filled"
    value={form.email === undefined ? "" : form.email}
    onChange={(e) => {
        setForm({
            ...form,
            email: e.target.value
        })

    }}
/>
  <TextField
    
    id="filled-basic"
    label="Mobile"
    variant="filled"
    value={form.mobile === undefined ? "" : form.mobile}
    onChange={(e) => {
        console.log(e.target.value)
        setForm({
            ...form,
            mobile: e.target.value
        })

    }}
/>

 <TextField
    
    id="filled-basic"
    label="NTN No."
    variant="filled"
    value={form.ntn === undefined ? "" : form.ntn}
    onChange={(e) => {
        setForm({
            ...form,
            ntn: e.target.value
        })

    }}
/>
 <TextField
    
    id="filled-basic"
    label="STN No."
    variant="filled"
    value={form.stn === undefined ? "" : form.stn}
    onChange={(e) => {
        setForm({
            ...form,
            stn: e.target.value
        })

    }}
/>
 <TextField
    
    id="filled-basic"
    variant="filled"
    label="Address"
    value={form.address === undefined ? "" : form.address}
    onChange={(e) => {
        setForm({
            ...form,
            address: e.target.value
        })

    }}
/>
  <TextField
    
    id="filled-basic"
    label="Debit"
    variant="filled"
    value={form.debit === undefined ? "" : form.debit}
    onChange={(e) => {
        setForm({
            ...form,
            debit: e.target.value
        })

    }}
/>
   <TextField
    
    id="filled-basic"
    label="Credit"
    variant="filled"
    value={form.credit === undefined ? "" : form.credit}
    onChange={(e) => {
        setForm({
            ...form,
            credit: e.target.value
        })

    }}
/>


<br />
<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{
     vertical: 'top',
     horizontal: 'right',
}}>
    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
    {alertText}
    </Alert>
</Snackbar>
<Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{
     vertical: 'top',
     horizontal: 'right',
}}>
    <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
   Invalid Details
    </Alert>
</Snackbar>
<div style={{ padding: 10 }}>
    <Button variant="contained" to="#" onClick={submit} >
        Add Client
    </Button>
</div>


</div>
:
<CircularProgress />

                }
              
            
                {
                    loading ?
                    <CircularProgress />
                    :
                    <MaterialTable
                    icons={tableIcons}
                    title="Items"
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: 'Title', field: 'name' },
                        { title: 'Type', field: 'type' }

                    ]}
                    data={rows}
                    actions={[
                  
                        {
                            icon: ()=><Edit />,
                            tooltip: 'Edit',
                            onClick: (event, rowData) =>{
                                setEditData(rowData)
                                setEditFlag(true)
                            }
                          },

                        {
                            icon: ()=><Delete />,
                            tooltip: 'Delete',
                            onClick: (event, rowData) =>{
                                deleteClient(rowData.id)
                            }
                          }
                      ]}
                    />
                }
             
            </Box>

        </>

    );
}

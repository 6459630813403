import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { Grid, Container, Typography } from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import CreatePurchaseReturnComponent from './createPurchaseReturnComponent';

// ServerURL
import url from 'src/serverURL.js';

export default function Create({  createClose}) {
    const [open, setOpen] = React.useState(true);
    const [scroll, setScroll] = React.useState('paper');
  

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
        createClose()
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }

      


    }, [open]);

    const getDate = () => {
        return `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
    }
    

    return (
        <div>
            <Dialog
                open={open}
                fullScreen={true}
                fullWidth={true}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Create Purchase Return</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                <CreatePurchaseReturnComponent />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React, { Component } from "react";
import {
    Form, Col,
    Row
} from 'react-bootstrap';
import 'whatwg-fetch';

import { Card, CardHeader, CardBody, Input } from "reactstrap";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';

import Autocomplete from '@mui/material/Autocomplete';

import ReactLoading from "react-loading";
import { forwardRef } from 'react';
// ServerURL
import url from '../../serverURL.js';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';


import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import TextField from '@mui/material/TextField';

import MaterialTable from 'material-table'

import { Grid, Container, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



class Profit_Loss extends Component {
    constructor() {
        super();
        this.state = {
            columns: [

                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Account Title",
                    field: "title",
                    sort: "asc"
                },
                {
                    label: "Debit (OB)",
                    field: "opening_debit",
                    sort: "asc"
                },
                {
                    label: "Credit (OB)",
                    field: "opening_credit",
                    sort: "asc"
                },
                {
                    label: "Debit (During)",
                    field: "process_debit",
                    sort: "asc"
                },
                {
                    label: "Credit (During)",
                    field: "process_credit",
                    sort: "asc"
                },
                {
                    label: "Debit (CB)",
                    field: "closing_debit",
                    sort: "asc"
                },
                {
                    label: "Credit (CB)",
                    field: "closing_credit",
                    sort: "asc"
                }


            ],
            rows: [
            ],
            call: () => {
                var columns = this.state.columns;
                var rows = this.state.rows;
                return { columns, rows };
            },
            id: 0,
            cash_accounts: [],
            bank_accounts: [],
            date_from: new Date().setMonth(new Date().getMonth() - 1),
            date_to: new Date(),
            final_flag: '',
            total_opening_debit: 0,
            total_opening_credit: 0,
            total_process_debit: 0,
            total_process_credit: 0,
            total_closing_debit: 0,
            total_closing_credit: 0,
            cash: true,
            bank: false,
            type: 'customer',
            ac_title_data: null,
            all_accounts: {},
            original_record: [],
            body: '',
            title: '',
            opening_balance: 0,
            opening_flag: '',
            add: false,
            date: new Date(),
            save: false,
            avGbyInvoice: {},

            revenue: [],
            expense: [],
            analytics: []
        };

        this.onSubmit = this.onSubmit.bind(this);
    }
    componentWillMount() {


    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    onSubmit(e) {
        e.preventDefault();

        this.setState({ total_opening_credit: 0 })
        this.setState({ total_opening_debit: 0 })
        this.setState({ total_process_credit: 0 })
        this.setState({ total_process_debit: 0 })
        var data = {
            from: this.state.date_from,
            to: this.state.date_to,
            module: "Profit_Loss"
        }
        this.setState({ save: true })
        var url = "/inventry/reporting/costofsales"
        data.clienttype = ""

        var OpeningDebit_COS = 0
        var OpeningCredit_COS = 0
        var ProcessDebit = 0
        var ProcessCredit = 0
        var ClosingDebit = 0
        var ClosingCredit = 0

        fetch("/inventry/reporting/avGbyInvoice", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((avGbyInvoice) => {
            this.setState({ avGbyInvoice: avGbyInvoice })

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((transactions) => {
                //  console.log(transactions)
                var Opening1 = transactions.Opening
                var During1 = transactions.During

                //////////////////// Sorting Opening Data
                var key = Object.keys(Opening1)
                    .sort(function order(key1, key2) {

                        if (new Date(Opening1[key1].date) < new Date(Opening1[key2].date)) return -1;
                        else if (new Date(Opening1[key1].date) > new Date(Opening1[key2].date)) return +1;
                        else return 0;
                    });

                var Opening = {};

                for (var i = 0; i < key.length; i++) {
                    Opening[key[i]] = Opening1[key[i]];
                    delete Opening1[key[i]];
                }

                ///////////////////

                //////////////////// Sorting During Data
                var key = Object.keys(During1)
                    .sort(function order(key1, key2) {

                        if (new Date(During1[key1].date) < new Date(During1[key2].date)) return -1;
                        else if (new Date(During1[key1].date) > new Date(During1[key2].date)) return +1;
                        else return 0;
                    });

                var During = {};

                for (var i = 0; i < key.length; i++) {
                    During[key[i]] = During1[key[i]];
                    delete During1[key[i]];
                }

                ///////////////////

                var openingBalance = 0
                var OpeningCredit = 0
                var OpeningDebit = 0
                // Opening

                Object.keys(Opening).map((key) => {
                    if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                        OpeningDebit = OpeningDebit + this.state.avGbyInvoice[key]
                    }
                })


                var openDiff = OpeningDebit
                // SetUp the Opening of Cost of Sales
                if (openDiff > 0) {
                    OpeningDebit_COS = openDiff

                } else {
                    OpeningCredit_COS = Math.abs(openDiff)
                }
                this.setState({ opening_flag: "Dr" })

                this.setState({ opening_balance: Math.abs(openDiff) })

                var OpeningBalanceValue = openDiff
                var DuringCreditBalance = 0
                var DuringDebitBalance = 0
                var the_rows = []

                var totalDebit = 0
                var totalCredit = 0
                var totalBalance = 0
                var openDiff = 0
                var flag = ""
                var counter = 0
                // During

                Object.keys(During).map((key) => {
                    var debit = 0
                    var credit = 0
                    var type = ""

                    if (key.includes('AIO-S-') || key.includes('AIO-PR-')) {
                        if (key.includes('AIO-S-')) {
                            type = "Sale"
                        }
                        if (key.includes('AIO-PR-')) {
                            type = "Purchase Return"
                        }
                        totalDebit = totalDebit + this.state.avGbyInvoice[key]
                        if (counter == 0) {
                            DuringDebitBalance = DuringDebitBalance + this.state.avGbyInvoice[key] + OpeningBalanceValue
                        } else {
                            DuringDebitBalance = DuringDebitBalance + this.state.avGbyInvoice[key]
                        }
                        counter++
                        debit = this.state.avGbyInvoice[key]
                    }


                    var date = new Date(During[key].date)
                    var d = date.getDate()
                    var m = date.getMonth() + 1
                    var y = date.getFullYear()

                    openDiff = DuringDebitBalance


                    flag = "Dr"



                    //  the_rows.push(put_data)
                })
                if (flag == "Dr") {
                    ProcessCredit = totalCredit
                    ProcessDebit = totalDebit
                    ClosingDebit = Math.abs(openDiff)
                } else {
                    ProcessCredit = totalCredit
                    ProcessDebit = totalDebit
                    ClosingCredit = Math.abs(openDiff)
                }

                //////////////////////////////////////////////// Calling Up the Trail Balance
                var revenue = []
                var expense = []
                var analytics = []

                fetch('/inventry/reporting/trial', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((transactions) => {
                    var the_rows = []
                    var total_opening_credit = 0
                    var total_opening_debit = 0
                    var total_process_debit = 0
                    var total_process_credit = 0
                    var total_closing_debit = 0
                    var total_closing_credit = 0
                    transactions.push({
                        detail: {id: "C-1", name: "Cost of Sales", type: "COS"},
                        opening:{credit: OpeningCredit_COS , debit: OpeningDebit_COS},
                        process:{credit: ProcessCredit , debit: ProcessDebit},
                      })

                    var total_revenue=0
                    var total_expense=0

                    var analytics=[]

                    analytics.push({
                        title:"Cost of Sales",
                        value : this.numberWithCommas((ProcessDebit=="0" ? ProcessCredit : ProcessDebit).toFixed(2))
                    })


                    transactions.map((data) => {
                        if (data.detail.name == "Credit Sales" || data.detail.name == "Cash Sales") {
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: this.numberWithCommas(data.process.credit.toFixed(2))
                            }
                            total_revenue=total_revenue + data.process.credit
                            revenue.push(put_data)
                        }
                     
                        // Enable the oTHER iNCOME
                        if (data.detail.name == "Discount" || data.detail.name == "Miscellaneous Income") {
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: this.numberWithCommas(data.process.credit.toFixed(2))
                            }
                            console.log("Discount")
                            console.log(data.detail.name)
                            console.log("Credit")
                            console.log(data.process.credit)
                            console.log("Debit")
                            console.log(data.process.debit)
                            total_revenue=total_revenue + data.process.credit
                            revenue.push(put_data)
                        }
                        if (data.detail.id.includes("E-") &&  data.detail.id.split("-").length==4 ) {
                            
                            var put_data = {
                                id: data.detail.id,
                                title: data.detail.name,
                                type: data.detail.type,
                                value: data.process.credit=="0" || data.process.credit==0 ?  this.numberWithCommas(data.process.debit.toFixed(2)) : this.numberWithCommas(data.process.credit.toFixed(2))
                            }
                            expense.push(put_data)
                            // console.log("Expenses")
                            // console.log(data.detail.name)
                            // console.log("Credit : "+data.process.credit)
                            // console.log("Debit : "+data.process.debit)
                            total_expense=total_expense + (data.process.credit=="0" || data.process.credit==0 ?  data.process.debit : data.process.credit)
                            //expense.push(put_data)

                        }

                    })
                    var gross_profit=total_revenue - (ProcessDebit=="0" ? ProcessCredit : ProcessDebit)
                    analytics.push({
                        title:"Gross Profit",
                        value: this.numberWithCommas(gross_profit.toFixed(2))
                    })
                    analytics.push({
                        title:"Total Expense",
                        value: this.numberWithCommas((total_expense).toFixed(2))
                    })
                    var net_profit_before_depreciation=gross_profit - total_expense
                    analytics.push({
                        title:"Net Profit Before Depreciation",
                        value: this.numberWithCommas((net_profit_before_depreciation).toFixed(2))
                    })
                    analytics.push({
                        title:"Depreciation",
                        value: 0
                    })
                    analytics.push({
                        title:"Net Profit After Depreciation",
                        value: this.numberWithCommas((net_profit_before_depreciation).toFixed(2))
                    })
                    analytics.push({
                        title:"Net Profit Before Tax",
                        value: this.numberWithCommas((net_profit_before_depreciation).toFixed(2))
                    })
                    analytics.push({
                        title:"Income Tax",
                        value: 0
                    })
                    analytics.push({
                        title:"Net Profit",
                        value: this.numberWithCommas((net_profit_before_depreciation).toFixed(2))
                    })




                    //   expense.push({
                    //             id: "C-1", 
                    //             name: "Cost of Sales", 
                    //             type: "COS",
                    //             value:ProcessDebit
                    //   })

                    this.setState({ revenue: revenue })
                    this.setState({ expense: expense })
                    this.setState({ analytics: analytics })
                    


                    // var OpeningDebit_Profit_Loss =0
                    // var OpeningCredit_Profit_Loss=0
                    // var ProcessDebit_Profit_Loss=0
                    // var ProcessCredit_Profit_Loss=0

                    //   transactions.map((data)=>{
                    //     if(data.detail.name=="Credit Sales" || data.detail.name=="Cash Sales"){
                    //       OpeningCredit_Profit_Loss=    data.opening.credit +OpeningCredit_Profit_Loss
                    //       OpeningDebit_Profit_Loss=   data.opening.debit + OpeningDebit_Profit_Loss
                    //       ProcessCredit_Profit_Loss=   data.process.credit + ProcessCredit_Profit_Loss
                    //       ProcessDebit_Profit_Loss=   data.process.debit + ProcessDebit_Profit_Loss
                    //     }


                    //   })


                    //   transactions.filter((data)=>{
                    //     var credit= parseFloat(data.process.credit)
                    //     var debit= parseFloat(data.process.debit)
                    //     var closing_debit=0
                    //     var closing_credit=0

                    //     if((debit-credit) >0 ){
                    //       closing_debit=debit-credit
                    //     }else{
                    //       closing_credit=Math.abs(credit-debit)
                    //     }

                    //       var put_data={
                    //         id:data.detail.id,
                    //         title:data.detail.name,
                    //         type:data.detail.type,
                    //         opening_credit: this.numberWithCommas(data.opening.credit.toFixed(2)),
                    //         opening_debit: this.numberWithCommas(data.opening.debit.toFixed(2)),
                    //         closing_debit: this.numberWithCommas(closing_debit.toFixed(2)),
                    //         closing_credit: this.numberWithCommas(closing_credit.toFixed(2)),
                    //         process_credit: this.numberWithCommas(data.process.credit.toFixed(2)),
                    //         process_debit: this.numberWithCommas(data.process.debit.toFixed(2)),
                    //      }
                    //      total_opening_credit=total_opening_credit+parseFloat(data.opening.credit)
                    //      total_opening_debit=total_opening_debit+parseFloat(data.opening.debit)
                    //      total_process_debit=total_process_debit+parseFloat(data.process.debit)
                    //      total_process_credit=total_process_credit+parseFloat(data.process.credit)
                    //      total_closing_credit=total_closing_credit+closing_credit
                    //      total_closing_debit=total_closing_debit+closing_debit
                    //      the_rows.push(put_data) 
                    //     }
                    //     )

                    //     this.setState({rows:the_rows})
                    //     this.setState({total_opening_credit: this.numberWithCommas(total_opening_credit.toFixed(2))})

                    //     this.setState({total_opening_debit: this.numberWithCommas(total_opening_debit.toFixed(2))})

                    //     this.setState({total_process_credit: this.numberWithCommas(total_process_credit.toFixed(2))})
                    //     this.setState({total_process_debit: this.numberWithCommas(total_process_debit.toFixed(2))})
                    //     this.setState({total_closing_credit: this.numberWithCommas(total_closing_credit.toFixed(2))})
                    //     this.setState({total_closing_debit: this.numberWithCommas(total_closing_debit.toFixed(2))})
                    this.setState({ save: false })

                })




            }).catch(() => {
                this.setState({ save: false })
            })

        })
    }
    Clear_Text = () => {
        try {
            const instance = this._typeahead_1.getInstance();
            instance.clear();
        } catch (e) {

            console.log(e)

        }
    }

    getData() {
        var columns = this.state.columns;
        var rows = this.state.rows;
        var filter = this.original;
        return { columns, rows };

    }
    handleChangeFrom = date => {
        this.setState({
            date_from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            date_to: date
        });
    };
    render() {
        return (
            <Grid>

                <Grid md={12}>
                    <Card>
                        <CardHeader>
                        </CardHeader>
                        <CardBody className="all-icons">
                            <form onSubmit={this.onSubmit} className="Notifications">
                               
                                <Grid container controlId="exampleForm.ControlSelect2">
                                    <Grid sm={2}>
                                    <h2><strong>Profit & Loss</strong></h2>
                                    
                                    </Grid> 
                                    <Grid sm={2}>
                                        <b>From :</b>
                                        <br />
                                        <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DatePicker inputFormat="dd/MM/yyyy"

                                                            value={this.state.date_from}
                                                            onChange={(date_from) => {
                                                                this.setState({ date_from: date_from })
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />

                                                    </LocalizationProvider>
                                    </Grid>
                                    <Grid sm={1}></Grid>
                                    <Grid sm={2}>
                                        <b>To :</b>
                                        <br />
                                        <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DatePicker inputFormat="dd/MM/yyyy"

                                                            value={this.state.date_to}
                                                            onChange={(date_to) => {
                                                                this.setState({ date_to: date_to })
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />

                                                    </LocalizationProvider>
                                    </Grid>
                                    <Grid sm={1}></Grid>
                                    <Grid sm={2}>
                                        <br />
                                    <Button variant="contained" size="md" block type="submit" disabled={this.state.save}>Search</Button>
                                    </Grid>
                                    <Grid sm={4}></Grid>
                                </Grid>
                                
                            </form>
                            {
                                        this.state.save
                                            ?
                                            <ReactLoading type={"spokes"} color="rgb(32,55,100)" width={100} height={100} />
                                            :
                            <Grid container >
                                <Grid md={6}>
                                    <MaterialTable
                                        title={<b>{"REVENUE"}</b>}
                                        icons={tableIcons}
                                        columns={[
                                            { title: 'ID', field: 'id' },
                                            { title: 'Type', field: 'type',defaultGroupSort:'desc',
                                            cellStyle: {
                                                color: 'blue',
                                                fontWeight:"bold"
                                              },
                                              headerStyle: {
                                                backgroundColor: 'blue',
                                                color:"white"
                                              }
                                             },
                                            { title: 'Account Title', field: 'title' ,
                                            cellStyle: {
                                                color: 'red',
                                                fontWeight:"bold"
                                              },
                                              headerStyle: {
                                                backgroundColor: 'red',
                                                color:"white"
                                              }
                                            },
                                            { title: 'PKR', field: 'value' ,
                                            cellStyle: {
                                                color: 'black',
                                                fontWeight:"bold"
                                              }
                                            },

                                        ]}
                                        data={this.state.revenue}
                                        options={{
                                            grouping: true
                                        }}
                                    />
                                </Grid>
                                <Grid md={6} >
                                    <MaterialTable
                                        title={<b>{"EXPENSES"}</b>}
                                        icons={tableIcons}
                                        columns={[
                                            { title: 'ID', field: 'id' },
                                            { title: 'Type', field: 'type',defaultGroupSort:'desc',
                                            cellStyle: {
                                                color: 'blue',
                                                fontWeight:"bold"
                                              },
                                              headerStyle: {
                                                backgroundColor: 'blue',
                                                color:"white"
                                              }
                                             },
                                            { title: 'Account Title', field: 'title' ,
                                            cellStyle: {
                                                color: 'red',
                                                fontWeight:"bold"
                                              },
                                              headerStyle: {
                                                backgroundColor: 'red',
                                                color:"white"
                                              }
                                            },
                                            { title: 'PKR', field: 'value' ,
                                            cellStyle: {
                                                color: 'black',
                                                fontWeight:"bold"
                                              }
                                            },
                                            
                                        ]}
                                        data={this.state.expense}
                                        options={{
                                            grouping: true,
                                            exportButton:true
                                        }}
                                    />
                                </Grid>
                                
                                <Grid md={6}>
                                <MaterialTable
                                        title={<b>{"Analytics"}</b>}
                                        icons={tableIcons}
                                        columns={[
                                            { title: 'Title', field: 'title' , editable: 'never'},
                                            { title: 'PKR', field: 'value',
                                            cellStyle: {
                                                color: 'black',
                                                fontWeight:"bold"
                                              } 
                                            },
                                           
                                        ]}
                                        data={this.state.analytics}
                                        // cellEditable={{
                                        //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                        //       return new Promise((resolve, reject) => {
                                        //         console.log('newValue: ' + newValue);

                                        //         var profit=0
                                        //         if(rowData.title=="Depreciation"){
                                        //             var analytics= this.state.analytics.map((row)=>{
                                        //                 if(row.title=="Depreciation"){
                                        //                     return {
                                        //                         title:"Depreciation",
                                        //                         value: this.numberWithCommas((parseFloat(newValue)).toFixed(2))
                                        //                     }
                                        //                 }else{
                                        //                     if(row.title=="Net Profit Before Depreciation"){
                                        //                         profit=row.value
                                        //                         return row
                                        //                     }
                                        //                     if(row.title=="Net Profit After Depreciation" || row.title=="Net Profit Before Tax" || row.title=="Net Profit"){
                                        //                         return {
                                        //                             title:row.title,
                                        //                             value : this.numberWithCommas(((parseFloat(profit) - parseFloat(newValue))).toFixed(2)) 
                                        //                         }

                                        //                     }
                                        //                     return row
                                        //                 }
                                        //             })
                                        //             this.setState({analytics:analytics})

                                        //         }
                                        //         console.log(rowData);
                                        //         console.log(columnDef);
                                                

                                        //         setTimeout(resolve, 1000);
                                        //       });
                                        //     }
                                        //   }}
                                        options={{
                                            grouping: false,
                                            exportButton:true,
                                            pageSize:10
                                        }}
                                    />

                                </Grid>
                            </Grid>
                           
                    
                    }

                         
<Modal
                                        open={this.state.add}
                                        onClose={() => this.setState({ add: false })}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                {this.state.title}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                {this.state.body}
                                            </Typography>
                                        </Box>
                                    </Modal>

                        </CardBody>
                    </Card>
                </Grid>

            </Grid>
        );
    };
}
export default Profit_Loss;
import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import "whatwg-fetch";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import { forwardRef } from "react";
import Print from "@mui/icons-material/Print";
import { Grid, Container, Typography, Autocomplete } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import url from "../../serverURL.js";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import PDF from "./pdf";
import { PDFViewer } from "@react-pdf/renderer";
import TextField from "@mui/material/TextField";
import EditJV from "./editJV";
import CreateJV from "./createJV";
import muiComma from './commaFunction'
import MaterialTable from "material-table";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class JVManage extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      call: () => {
        var columns = this.state.columns;
        var rows = this.state.rows;
        return { columns, rows };
      },
      id: 0,
      cash_accounts: [],
      bank_accounts: [],
      date_from: new Date().setMonth(new Date().getMonth() - 1),
      date_to: new Date(),
      final_flag: "",
      total_balance: 0,
      cash: true,
      bank: false,
      load: true,
      type: "customer",
      ac_title_data: null,
      all_accounts: {},
      original_record: [],
      body: "",
      title: "",
      opening_balance: 0,
      opening_flag: "",
      add: false,
      date: new Date(),
      amount: 0,
      discount: 0,
      net_cost: 0,
      submit: false,
      editFlag: false,
      editData: {},
      createFlag: false,
      invoice: {},
      printFlag: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillMount() {}
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  onSubmit(e) {
    e.preventDefault();
    var from = new Date(this.state.date_from);
    from.setHours(1);
    var to = new Date(this.state.date_to);
    var data = {
      from: from,
      to: to,
      ac_id: this.state.ac_title_data,
    };

    fetch(url + "inventry/jv/report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((transactions) => {
        var amount = 0;
        const jv = transactions.map((jv) => {
          amount = amount + jv.credit;
          var acc = jv.transactions.map((i) => i.ac_title);
         

          return {
            accounts: acc.toLocaleString(),
        
            ...jv,
        };
    });
    this.setState({ rows: jv });
    this.setState({ amount: amount });
    console.log('ALl Information',jv)
});
}
  Clear_Text = () => {
    try {
      const instance = this._typeahead_1.getInstance();
      instance.clear();
    } catch (e) {
      console.log(e);
    }
  };
  Accounts = () => {
    return (
      <div>
        <Autocomplete
          caseSensitive={false}
          id="filtering-example"
          ignoreDiacritics={true}
          ref={(ref) => (this._typeahead_1 = ref)}
          onChange={(event, newValue) => {
            var id = this.state.all_accounts[newValue];
            console.log(id);
            this.setState({ ac_title_data: id });
          }}
          clearButton={true}
          options={this.state.options}
          placeholder="A/C Title..."
          renderInput={(params) => <TextField {...params} label="Search" />}
        />
      </div>
    );
  };
  getData() {
    var columns = this.state.columns;
    var rows = this.state.rows;
    var filter = this.original;
    return { columns, rows };
  }
  handleChangeFrom = (date) => {
    this.setState({
      date_from: date,
    });
  };
  handleChangeTo = (date) => {
    this.setState({
      date_to: date,
    });
  };
  editClose = () => {
    this.setState({ editFlag: false });
  };
  createClose = () => {
    this.setState({ createFlag: false });
  };
  render() {
    return (
      <Row>
        {this.state.load ? (
          <Grid item md={12}>
            {this.state.editFlag ? (
              <EditJV
                editClose={this.editClose}
                editData={this.state.editData}
              />
            ) : null}
            {this.state.createFlag ? (
              <CreateJV createClose={this.createClose} />
            ) : null}
            <Card>
              <CardHeader></CardHeader>
              <CardBody className="all-icons">
              <Modal
                  open={this.state.printFlag}
                  onClose={() => this.setState({ printFlag: false })}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Journal Voucher 
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <PDFViewer height="1000" width="100%" className="app">
                        <PDF
                          accounts={this.state.invoice.transactions}
                          accountTitle={this.state.invoice.accounts}
                          invoiceNo={this.state.invoice.id}
                          paymentMethod={this.state.invoice.payment_method}
                          paymentTitle={this.state.invoice.payment_title}
                          advanceTax={this.state.invoice.advance_tax}
                          chequeNo={this.state.invoice.cheque}
                          totalAmount={this.state.invoice.amount}
                          paymentId={this.state.invoice.payment_id}
                          date={this.state.invoice.date}
                          client={this.state.invoice.client}
                          remarks={this.state.invoice.remarks}
                        />
                      </PDFViewer>
                    </Typography>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  size="md"
                  block
                  onClick={() => {
                    this.setState({ createFlag: true });
                  }}
                >
                  Create JV
                </Button>
                <Form onSubmit={this.onSubmit} className="Notifications">
                  <Grid
                    container
                    controlId="exampleForm.ControlSelect2"
                    spacing={3}
                  >
                    <Grid item sm={2}>
                      <b>From :</b>
                      <br />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          value={this.state.date_from}
                          onChange={this.handleChangeFrom}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item sm={2}>
                      <b>To :</b>
                      <br />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          value={this.state.date_to}
                          onChange={this.handleChangeTo}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item sm={2}>
                      <br />
                      <Button variant="contained" size="md" block type="submit">
                        Search
                      </Button>
                    </Grid>
                    <Grid item sm={6}></Grid>
                  </Grid>
                </Form>
                <Grid container controlId="formHorizontalEmail">
                  <Grid item md={12}>
                    {this.state.submit ? (
                      <ReactLoading
                        type={"spokes"}
                        color="rgb(32,55,100)"
                        width={100}
                        height={100}
                      />
                    ) : (
                      <TableContainer component={Paper}>
                        <MaterialTable
                          title=""
                          icons={tableIcons}
                          columns={[
                            { title: "Date", field: "date", type: "date" },
                            { title: "JV No.", field: "id" },
                            { title: "Accounts", field: "accounts" },
                            { title: "Amount", field: "credit", render: rowData=> muiComma(rowData.credit) },
                            { title: "Remarks", field: "remarks" },
                          ]}
                          data={this.state.rows}
                          options={{
                            headerStyle: {
                              backgroundColor: "#01579b",
                              color: "#FFF",
                            },
                            search: true,
                            grouping: true,
                            exportButton: true,
                            showTitle: true,
                            paging: false,
                            maxBodyHeight:"500px"
                          }}
                          actions={[
                            {
                              icon: () => <Edit />,
                              tooltip: "Edit",
                              onClick: (event, rowData) => {
                                console.log("Edit");
                                this.setState({ editData: rowData });
                                this.setState({ editFlag: true });
                              },
                            },
                            {
                              icon: () => <Print />,
                              tooltip: "Print",
                              onClick: (event, rowData) => {
                                console.log(rowData);
                                console.log(
                                  "Clients are",
                                  this.state.invoice.client
                                );
                                this.setState({ invoice: rowData });
                                this.setState({ printFlag: true });
                              },
                            },
                            {
                              icon: () => <DeleteOutline />,
                              tooltip: "Delete",
                              onClick: (event, rowData) => {
                                if (window.confirm("Are you Sure ? Delete this Receipt!") == true) {
                                 
                                  var data = {
                                    id:rowData.id
                                  };
                              
                                  fetch(url + "inventry/jv", {
                                    method: "DELETE",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(data),
                                  })
                                    .then((res) => {
                                      if(res.status==200){
                                        alert("Deleted")
                                        this.Reload()
                                      }else{
                                        alert("Something went wrong!")
                                      }
                                    })
                              
                                    
                                }
                              },
                            },
                          ]}
                        />
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}></Grid>
                  <Grid
                    item
                    sm={2}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  ></Grid>
                  <Grid
                    item
                    sm={2}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  ></Grid>
                  <Grid
                    item
                    sm={2}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <b class="Total Profit">Total Amount</b>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      type="text"
                      value={this.numberWithCommas(
                        this.state.amount.toFixed(2)
                      )}
                      style={{
                        fontWeight: "bold",
                      }}
                      required
                      placeholder="Total Amount..."
                    />
                  </Grid>
                </Grid>

                <Modal
                  open={this.state.alert}
                  onClose={() => this.setState({ alert: false })}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {this.state.title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {this.state.body}
                    </Typography>
                  </Box>
                </Modal>
              </CardBody>
            </Card>
          </Grid>
        ) : (
          <div
            style={{
              margin: "auto",
              marginTop: "10em",
              height: "100em",
            }}
          >
            <ReactLoading
              type={"spokes"}
              color="rgb(32,55,100)"
              width={100}
              height={100}
            />
          </div>
        )}
      </Row>
    );
  }
}
export default JVManage;
